import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createProductFromResponse,
    IProduct,
    IProductResponse,
} from '../models/product.model';

export type ProductsState = EntitiesState<IProduct>;

@StoreConfig({
    name: 'products',
    resettable: true,
})
class ProductsStore extends EntitiesStore<
    IProduct,
    IProductResponse,
    IProductResponse[]
> {}

export const productsStore = new ProductsStore({
    responseMapper: createProductFromResponse,
    listResponseMapper: () => (results) => results,
});
