import { useObservable } from '@mindspace-io/react';
import {
    EntitiesContext,
    IEntitiesContext,
} from 'core/constants/entities.context';
import { useSession } from 'core/hooks/session.hook';
import { useErrorDialog } from 'core/hooks/shared/error.hook';
import { IEntity } from 'core/interfaces/entity.interface';
import { apiService } from 'core/services/apiService';
import { differenceInMinutes, isBefore } from 'date-fns';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { useContext, useEffect, useState } from 'react';
import { LivestreamStatusEnum } from '../enums';
import { ILivestreamSession } from '../interfaces';
import {
    ILivestream,
    ILivestreamDetails,
    ILivestreamResponse,
    LiveStreamLiveStatusEnum,
} from '../models/livestream.model';
import { LivestreamsQuery } from '../state/livestreams.query';
import { LivestreamsStore } from '../state/livestreams.store';

interface IUseLivestreamJwtOptions {
    forceFetch: boolean;
    autoJoin: boolean;
    autoFetchJwt: boolean;
}

interface IUseLivestreamJwt {
    jwt: string;
    canStartLivestream: () => boolean;
    canJoinLivestream: () => boolean;
    startLivestream: () => Promise<string | null>;
    joinLivestream: () => Promise<string | null>;
}

export const useLivestream = (
    livestream: IEntity & Partial<ILivestreamDetails>,
    {
        forceFetch = false,
        autoJoin = false,
        autoFetchJwt = false,
    }: Partial<IUseLivestreamJwtOptions> = {}
): IUseLivestreamJwt => {
    if (livestream.id === '0') {
        /**
         * This is a dirty HACK!
         * it solves an issue when creating a new Livestream via AddLivestreamPageContainer
         * In general this is a fallback when a livestream is created with no specific id
         */
        return {
            jwt: '',
            canStartLivestream: () => false,
            canJoinLivestream: () => false,
            startLivestream: async () => null,
            joinLivestream: async () => null,
        };
    }

    const { me } = useSession();
    const hasPermission = usePermissions();
    const { store, query } = useContext<
        IEntitiesContext<
            ILivestream,
            ILivestreamResponse,
            LivestreamsQuery,
            LivestreamsStore
        >
    >(EntitiesContext as any);

    const [jwt] = useObservable(query.ui.selectEntity(livestream.id, 'jwt'));
    const [isInitialized, setIsInitialized] = useState<boolean | null>(null);
    const { raiseErrorDialog } = useErrorDialog();
    /**
     * since jwt will always be undefined as initial value
     * we need to assume isInitialized is null
     * until we see if the jwt actually exists
     *
     * otherwise we would set the initial value of isInitialized to false
     * and remove the following useEffect
     */
    useEffect(() => {
        if (
            typeof jwt === 'undefined' ||
            isInitialized !== null ||
            !livestream.createdBy
        ) {
            return;
        }

        if (forceFetch) {
            setIsInitialized(true);
        } else if (jwt) {
            setIsInitialized(false);
        } else {
            setIsInitialized(autoFetchJwt || autoJoin);
        }
    }, [jwt, livestream]);

    const handleLivestreamSession = ({ jwt, stream }: ILivestreamSession) => {
        store.update(livestream.id, (state) => ({
            ...state,
            stream: { ...state.stream, ...stream },
        }));

        store.ui.update(livestream.id, {
            jwt,
        });

        return jwt;
    };

    const startLivestream = () => {
        return apiService
            .post<ILivestreamSession>(`streams/${livestream.id}`, {
                name: livestream.title,
            })
            .then(({ data }) => handleLivestreamSession(data))
            .catch((e: any) => {
                raiseErrorDialog(e);
                return null;
            });
    };

    const fetchJwt = () => {
        return apiService
            .get<ILivestreamSession[]>(`streams/${livestream.id}/jwts`)
            .then(({ data }) => handleLivestreamSession(data[0]))
            .catch((e: any) => {
                store.ui.update(livestream.id, {
                    jwt: '',
                });

                store.fetchById(livestream.id);

                return null;
            });
    };

    const joinLivestream = () => {
        return apiService
            .put<ILivestreamSession>(
                `streams/join/${livestream.id}`,
                livestream.id
            )
            .then(({ data }) => handleLivestreamSession(data))
            .catch((e: any) => {
                raiseErrorDialog(e);

                return null;
            });
    };

    const canStartLivestream = () => {
        const isStagingOrDevelopment = process.env.ENVIRONMENT === 'staging' || process.env.ENVIRONMENT === 'development';

        if (livestream.startsAt && livestream.endsAt) {
            return (
                livestream.createdBy === me?.id &&
                livestream.liveStatus === LiveStreamLiveStatusEnum.UPCOMING &&
                isBefore(new Date(), livestream.endsAt) &&
                (
                    !isStagingOrDevelopment || differenceInMinutes(livestream.startsAt, new Date()) <= 15
                )
            );
        } else {
            return false;
        }
    };

    const canJoinLivestream = () =>
        Boolean(
            hasPermission(PERMISSIONS.LIVESTREAM_SESSION_ADMIN_ACCESS) &&
                livestream.stream?.join_allowed &&
                livestream.stream.status === LivestreamStatusEnum.IN_PROGRESS &&
                !livestream.canceled
        );

    useEffect(() => {
        if (isInitialized) {
            fetchJwt().then((jwt) => {
                if (!jwt && autoJoin) {
                    if (canStartLivestream()) {
                        startLivestream();
                        return;
                    }

                    if (canJoinLivestream()) {
                        joinLivestream();
                        return;
                    }
                }
            });
        }
    }, [isInitialized]);

    return {
        jwt: jwt ?? '',
        canStartLivestream,
        canJoinLivestream,
        startLivestream,
        joinLivestream,
    };
};
