import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { ConfirmationDialogContext } from 'core/components/ConfirmationDialog/ConfirmationDialogProvider';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { TabProps, Tabs } from 'core/components/Tabs';
import { WithProvider } from 'core/hocs/WithProvider';
import { useActiveTab } from 'core/hooks/active-tab.hook';
import { useAirexCertificate } from 'core/hooks/airex-certificate.hook';
import { useAreaOfExpertiseData } from 'core/hooks/area-of-expertise.hook';
import { useEditMode } from 'core/hooks/edit-mode.hook';
import { useSession } from 'core/hooks/session.hook';
import { ILocationState } from 'modules/login/components/PrivateRoute';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import {
    AccountDetails,
    AccountDetailsData,
} from 'modules/trainers/components/AccountDetails';
import { AirexCertificate } from 'modules/trainers/components/AirexCertificate';
import { Education } from 'modules/trainers/components/Education';
import { useTrainer } from 'modules/trainers/hooks/useTrainer.hook';
import { createTrainerData } from 'modules/trainers/models/add-trainer.model';
import { trainersQuery } from 'modules/trainers/state/trainers.query';
import { UserRole } from 'modules/users/constants/role-permissions';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AreaOfExpertise } from '../../components/AreaOfExpertise';
import './style.scss';

export interface AddTrainerData {
    accountDetails: AccountDetailsData;
    areaOfExpertise: Array<string>;
    education: Array<string>;
    certificates: Array<string>;
}

const AddTrainerPage: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { cleanErrorState } = useSession();
    const { patchDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );
    const { push } = useHistory();
    const { state } = useLocation<ILocationState>();
    const { createTrainer } = useTrainer();
    const {
        activeTab,
        validatedTab,
        updateActiveTab,
        updateTabsUI,
    } = useActiveTab();
    const { cleanEditMode } = useEditMode();
    const { setConfirmationDialogProps } = useContext(
        ConfirmationDialogContext
    );
    const [
        accountDetailsData,
        setAccounDetailsData,
    ] = useState<AccountDetailsData>({
        first_name: '',
        last_name: '',
        email: '',
        contact_number1: '',
        languages: [],
        roles: [UserRole.ROLE_TRAINER],
        checkedAdditionalLanguages: [],
    });
    const expertises = useAreaOfExpertiseData();
    const [expertisesData, setExpertisesData] = useState<Array<string>>([]);
    const [educationData, setEducationData] = useState<Array<string>>([]);
    const certificates = useAirexCertificate();
    const [certificatesData, setCertificatesData] = useState<Array<string>>([]);
    const [base64UrlImage, setBase64UrlImage] = useState<
        string | ArrayBuffer | null
    >();

    const handleAccountDetailsSubmit = (
        accountDetailsData: AccountDetailsData
    ) => {
        if (
            accountDetailsData.avatar &&
            typeof accountDetailsData.avatar !== 'string'
        ) {
            encodeImageFileAsURL(accountDetailsData.avatar);
        }

        setAccounDetailsData(accountDetailsData);
    };

    const handleAreaOfExpertiseSubmit = (checkedExpertises: Array<string>) => {
        setExpertisesData(checkedExpertises);
    };

    const handleEducationSubmit = (educationData: Array<string>) => {
        setEducationData(educationData);
    };

    const handleCertificatesSubmit = async (
        checkedCertificates: Array<string>
    ) => {
        setCertificatesData(checkedCertificates);

        const trainer = createTrainerData({
            accountDetails: { ...accountDetailsData, avatar: base64UrlImage },
            areaOfExpertise: expertisesData,
            certificates: checkedCertificates,
            education: educationData,
        });

        if (await createTrainer(trainer)) {
            patchDialogProps({
                openDialog: true,
                contentText: t('sucessfully-added-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                label={t('close')}
                                color="primary"
                                style={{ width: '184px' }}
                                onClick={() => {
                                    push({
                                        pathname:
                                            state?.from?.pathname ??
                                            ROUTES.TRAINERS,
                                    });
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                openDialog: true,
                contentText: t('unsucessfully-added-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                label={t('close')}
                                color="primary"
                                style={{ width: '184px' }}
                                onClick={() => {
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        }
    };

    const encodeImageFileAsURL = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setBase64UrlImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const showConfirmationDialog = () => {
        setConfirmationDialogProps({
            openDialog: true,
            dialogText: t('edit-dialog-text'),
            rightButtonLabel: t('cancel'),
            leftButtonLabel: t('yes'),
            onRightClick: onDialogDiscardClick,
            onLeftClick: onDialogConfirmClick,
            onClose: onDialogClose,
        });
    };

    const tabsArray: Array<TabProps> = [
        {
            title: t('tab-account-details'),
            element: (
                <AccountDetails
                    data={accountDetailsData}
                    onNextClick={handleAccountDetailsSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
        {
            title: t('tab-area-of-expertise'),
            element: (
                <AreaOfExpertise
                    data={{
                        expertises: expertises,
                        checkedExpertises: [...expertisesData],
                    }}
                    onNextClick={handleAreaOfExpertiseSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
        {
            title: t('tab-education'),
            element: (
                <Education
                    data={educationData}
                    onNextClick={handleEducationSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
        {
            title: t('tab-certificate'),
            element: (
                <AirexCertificate
                    data={{
                        certificates: certificates,
                        checkedCertificates: [...certificatesData],
                    }}
                    onNextClick={handleCertificatesSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
    ];
    const renderTabsArray = useCallback(() => {
        return tabsArray.map((tab, index) => {
            // if index of current tab is equal to active tab then enable it
            if (index === activeTab) {
                return { ...tab, disabled: false };
                // if index of current tab is lesser then index of validated tab then enable it
            } else if (index <= validatedTab) {
                return { ...tab, disabled: false };
                // else disable tab for clicking
            } else {
                return { ...tab, disabled: true };
            }
        });
    }, [
        activeTab,
        validatedTab,
        accountDetailsData,
        expertises,
        expertisesData,
        educationData,
        certificatesData,
        certificates,
    ]);

    const onDialogConfirmClick = () => {
        setConfirmationDialogProps({
            openDialog: false,
        });
        push(ROUTES.DEFAULT);
    };

    const onDialogDiscardClick = () => {
        setConfirmationDialogProps({
            openDialog: false,
        });
    };

    const onDialogClose = () => {
        setConfirmationDialogProps({
            openDialog: false,
        });
    };

    useEffect(() => {
        // clean activeTabIndex, validatedTabIndex and set edit mode to false
        // on unmount
        return () => {
            updateTabsUI(0, 0);
            cleanEditMode();
            cleanErrorState();
        };
    }, []);

    return (
        <div className="addTrainerContainer">
            <h1> {t('add-trainer-title')}</h1>
            <Tabs
                tabs={renderTabsArray()}
                activeTab={activeTab}
                onTabClick={updateActiveTab}
            />
        </div>
    );
};

export const AddTrainerPageContainer = WithProvider(
    AddTrainerPage,
    trainersQuery
);
