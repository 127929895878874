import { Box } from '@material-ui/core';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { useErrorDialog } from 'core/hooks/shared/error.hook';
import { apiService } from 'core/services/apiService';
import { updateState } from 'core/services/updateState';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    ILivestream,
    ILivestreamResponse,
    LiveStreamState,
} from '../models/livestream.model';
import {
    createTakenTimeslotFromResponse,
    ITakenTimeslotResponse,
} from '../models/takenTimeslot.model';
import { livestreamsStore } from '../state/livestreams.store';

interface IUseRedirect {
    shouldRedirectAfterSuccess: boolean;
    path: string;
}

interface IUseLiveStreamState {
    requestApproval: (id: string, vaidate: boolean) => void;
    approve: (id: string, redirect?: IUseRedirect | undefined) => void;
    decline: (id: string) => void;
    draft: (id: string) => void;
}

export const useLiveStreamState = (
    id: string,
    shouldFetchAfterSuccess = false
): IUseLiveStreamState => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchDialogProps, setDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );
    const { push } = useHistory();
    const { raiseErrorDialog } = useErrorDialog();
    const [livestream] = useEntityById<ILivestream>({
        id,
    });

    const _requestApproval = updateState<ILivestream, ILivestreamResponse>({
        store: livestreamsStore,
        state: LiveStreamState.PENDING,
    });

    const _approve = updateState<ILivestream, ILivestreamResponse>({
        store: livestreamsStore,
        state: LiveStreamState.APPROVED,
    });

    const _move_to_draft = updateState<ILivestream, ILivestreamResponse>({
        store: livestreamsStore,
        state: LiveStreamState.DRAFT,
    });

    const _decline = updateState<ILivestream, ILivestreamResponse>({
        store: livestreamsStore,
        state: LiveStreamState.DECLINED,
    });

    const onSuccess = useCallback(
        (
            contentText: string,
            content: JSX.Element,
            redirect?: IUseRedirect
        ) => {
            setDialogProps({
                openDialog: true,
                contentText,
                content,
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={() => {
                                    if (redirect) {
                                        const {
                                            shouldRedirectAfterSuccess,
                                            path,
                                        } = redirect;

                                        shouldRedirectAfterSuccess &&
                                            push(path);
                                    }

                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t]
    );

    const requestApproval = useCallback(
        (id: string, validate?: boolean) => {
            setDialogProps({
                openDialog: true,
                contentText: t('request-approval-livestream-confirmation-text'),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('request-approval')}
                                onClick={async () => {
                                    // check livestream taken timeslots and 24h validation
                                    let block = false;
                                    if (validate) {
                                        try {
                                            // validate timeslots
                                            const takenTimeslots = await apiService
                                                .get<
                                                    Array<ITakenTimeslotResponse>
                                                >(
                                                    `/trainings/livestreams/taken-timeslots`
                                                )
                                                .then(({ data }) =>
                                                    data.map(
                                                        createTakenTimeslotFromResponse
                                                    )
                                                );

                                            if (takenTimeslots) {
                                                takenTimeslots.map((slot) => {
                                                    // check current time with taken slot
                                                    if (
                                                        livestream?.startsAt &&
                                                        slot.startsAt
                                                    ) {
                                                        const currentDate =
                                                            livestream.startsAt;
                                                        const slotDate =
                                                            slot.startsAt;
                                                        if (
                                                            currentDate.getDate() ===
                                                                slotDate.getDate() &&
                                                            currentDate.getMonth() ===
                                                                slotDate.getMonth() &&
                                                            currentDate.getFullYear() ===
                                                                slotDate.getFullYear() &&
                                                            currentDate.getTime() ===
                                                                slotDate.getTime()
                                                        ) {
                                                            // raise error dialog
                                                            block = true;
                                                            onSuccess(
                                                                t(
                                                                    'taken-timeslot'
                                                                ),
                                                                <></>
                                                            );
                                                        }
                                                    }
                                                });
                                            }
                                            // validate startsAt 24h from now
                                            const startTime =
                                                livestream?.startsAt &&
                                                livestream.startsAt.getTime();
                                            if (startTime) {
                                                const currentDate = new Date();
                                                const tommorowDate = new Date();
                                                tommorowDate.setDate(
                                                    currentDate.getDate() + 1
                                                );
                                                if (
                                                    startTime <
                                                    tommorowDate.getTime()
                                                ) {
                                                    block = true;
                                                    onSuccess(
                                                        t('24hours'),
                                                        <></>
                                                    );
                                                }
                                            }
                                        } catch (e: any) {
                                            raiseErrorDialog(e);
                                        }
                                    }

                                    if (!block) {
                                        try {
                                            await _requestApproval(id);
                                            onSuccess(
                                                '',
                                                <>
                                                    {t(
                                                        'request-approval-livestream-success-text'
                                                    )}
                                                </>
                                            );
                                        } catch (e) {
                                            raiseErrorDialog(e);
                                        }
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess, livestream]
    );

    const approve = useCallback(
        (id: string, redirect?: IUseRedirect | undefined) => {
            setDialogProps({
                openDialog: true,
                contentText: t('approve-livestream-confirmation-text'),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('approve-livestream')}
                                onClick={async () => {
                                    try {
                                        await _approve(id, {
                                            shouldFetchAfterSuccess,
                                        });
                                        onSuccess(
                                            t(
                                                'approve-livestream-success-title'
                                            ),
                                            <>
                                                {t(
                                                    'approve-livestream-success-text'
                                                )}
                                            </>,
                                            redirect
                                        );
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess]
    );

    const decline = useCallback(
        (id: string) => {
            setDialogProps({
                openDialog: true,
                contentText: t('decline-livestream-confirmation-text'),
                // eslint-disable-next-line react/display-name
                renderContent: (data) => (
                    <Box display="flex" flexDirection="column" flex="1 1 auto">
                        <p className="text">{t('decline-reason')}</p>
                        <Box pb="5px" />
                        <textarea
                            className="textareaInput"
                            maxLength={1000}
                            spellCheck={false}
                            name="reason"
                            value={data}
                            onChange={(e) =>
                                patchDialogProps({ data: e.target.value })
                            }
                        />
                    </Box>
                ),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        // eslint-disable-next-line react/display-name
                        renderContent: (data) => (
                            <Button
                                color="primary"
                                disabled={!data}
                                label={t('decline-livestream')}
                                onClick={async () => {
                                    try {
                                        await _decline(id, {
                                            shouldFetchAfterSuccess,
                                            data: {
                                                decline_reason: data,
                                            },
                                        });
                                        onSuccess(
                                            '',
                                            <>
                                                {t(
                                                    'decline-livestream-success-text'
                                                )}
                                            </>
                                        );
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess]
    );

    const draft = useCallback(
        (id: string) => {
            setDialogProps({
                openDialog: true,
                contentText: t('move-livestream-to-draft'),
                // eslint-disable-next-line react/display-name
                renderContent: (data) => (
                    <Box textAlign="center">
                        <p>{t('move-livestream-to-draft-subtitle')}</p>
                    </Box>
                ),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('move-training')}
                                onClick={async () => {
                                    try {
                                        await _move_to_draft(id, {
                                            shouldFetchAfterSuccess,
                                        });
                                        onSuccess(
                                            t('move-livestream-success-title'),
                                            <>
                                                {t(
                                                    'move-livestream-success-text'
                                                )}
                                            </>
                                        );
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess]
    );

    return {
        requestApproval,
        approve,
        decline,
        draft,
    };
};
