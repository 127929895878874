import { Box, FormHelperText } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { ActionList } from 'core/components/ActionList';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { IActionProps } from 'core/interfaces/action-props.interface';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { useTrainer } from 'modules/trainers/hooks/useTrainer.hook';
import { ITrainer } from 'modules/trainers/models/trainer.model';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import React, { FC, useContext, useMemo } from 'react';

interface TrainerListActionsProps {
    trainer: ITrainer;
}

export const TrainerListActions: FC<TrainerListActionsProps> = ({
    trainer,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me } = useSession();
    const { patchDialogProps, clearDialogProps, setDialogProps } = useContext(
        AirexDialogContext
    );
    const { activateTrainer } = useTrainer();

    const hasPermission = usePermissions();

    const onActivate = async () => {
        if (await activateTrainer(trainer.id, true)) {
            setDialogProps({
                openDialog: true,
                contentText: t('sucessfully-activated-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={clearDialogProps}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                content: (
                    <Box mb="20px">
                        <FormHelperText className="formHelperText error">
                            {t('error')}
                        </FormHelperText>
                    </Box>
                ),
                onClose: clearDialogProps,
            });
        }
    };

    const actions = useMemo<IActionProps[]>(
        () =>
            !trainer
                ? []
                : [
                      {
                          content: <Visibility />,
                          hide: !hasPermission(PERMISSIONS.VIEW_TRAINER),
                          path: pathBuilder(
                              ROUTES.TRAINER_VIEW,
                              ':id',
                              trainer.id
                          ),
                      },
                      {
                          content: <Edit />,
                          hide: !(
                              hasPermission(PERMISSIONS.EDIT_TRAINER) ||
                              (hasPermission(PERMISSIONS.EDIT_OWN_TRAINER) &&
                                  trainer.createdBy === me?.id)
                          ),
                          path: pathBuilder(
                              ROUTES.TRAINER_EDIT,
                              ':id',
                              trainer.id
                          ),
                      },
                      {
                          hide:
                              trainer.active ||
                              !hasPermission(PERMISSIONS.UPDATE_TRAINER_STATE),
                          content: (
                              <PowerSettingsNewIcon
                                  className="with-pointer"
                                  onClick={() => {
                                      patchDialogProps({
                                          openDialog: true,
                                          contentText: t(
                                              'activate-trainer-confirmation-text'
                                          ),
                                          actions: [
                                              {
                                                  content: (
                                                      <Button
                                                          label={t('cancel')}
                                                          color="default"
                                                          onClick={() =>
                                                              clearDialogProps()
                                                          }
                                                      />
                                                  ),
                                              },
                                              {
                                                  content: (
                                                      <Button
                                                          label={t(
                                                              'activate-trainer'
                                                          )}
                                                          color="primary"
                                                          onClick={onActivate}
                                                      />
                                                  ),
                                              },
                                          ],
                                          onClose: clearDialogProps,
                                      });
                                  }}
                              />
                          ),
                      },
                  ].map(({ content, ...rest }) => ({
                      ...rest,
                      content: (
                          <Box marginTop="14px" marginLeft="22px">
                              {content}
                          </Box>
                      ),
                  })),
        [t, trainer]
    );

    return (
        <Box display="flex" justifyContent="flex-end">
            <ActionList actions={actions} />
        </Box>
    );
};
