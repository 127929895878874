import React, { useEffect } from 'react';
import { useCrud } from '../hooks/crud.hook';

export const WithCurrentPageListener = (WrappedComponent: any) => {
    const HocComponent = () => {
        const { startCurrentPageListener, stopCurrentPageListener } = useCrud();

        useEffect(() => {
            startCurrentPageListener();

            return () => {
                stopCurrentPageListener();
            };
        }, [startCurrentPageListener, stopCurrentPageListener]);

        return <WrappedComponent />;
    };

    Object.defineProperty(HocComponent, 'name', {
        value: `WithListContainer(${WrappedComponent.name})`,
        configurable: true,
    });

    return HocComponent;
};
