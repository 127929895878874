import { ChatMessage } from '@zoom/videosdk';
import ZoomClientContext from 'core/components/ZoomClientContext';
import { useDebounce } from 'core/hooks/shared/debounce.hook';
import { useContext, useEffect, useState } from 'react';

interface IUseLivestreamHost {
    hostIsAlive: boolean;
    hostHasBeenDisconnectedFor3Minutes: boolean;
}

export const useLivestreamHostConnection = (): IUseLivestreamHost => {
    const zmClient = useContext(ZoomClientContext);

    const [{ counter, isDisconnected }, setHostIsAliveState] = useState({
        counter: 0,
        isDisconnected: false,
    });
    const hostHasDisconnected = Boolean(useDebounce(counter, 5000, !counter)); // 5 sec
    useEffect(() => {
        hostHasDisconnected &&
            setHostIsAliveState({ counter: 0, isDisconnected: true });
    }, [hostHasDisconnected]);
    const hostHasBeenDisconnectedFor3Minutes = useDebounce(
        isDisconnected,
        180000
    ); // 3 min

    const onChatMessage = async ({ message }: Pick<ChatMessage, 'message'>) => {
        console.log(message);
        switch (message) {
            case 'HOST_IS_BACK':
                setHostIsAliveState({ counter: 0, isDisconnected: false });
                break;
            case 'HOST_IS_ALIVE':
                setHostIsAliveState(({ counter }) => ({
                    counter: counter + 1,
                    isDisconnected: false,
                }));
                break;
        }
    };

    useEffect(() => {
        zmClient.on('chat-on-message', onChatMessage);

        /**
         * fakes host message to jump start debounce if host is disconnected when admin joins
         */
        onChatMessage({ message: 'HOST_IS_ALIVE' });

        return () => {
            zmClient.off('chat-on-message', onChatMessage);
        };
    }, []);

    return { hostIsAlive: !isDisconnected, hostHasBeenDisconnectedFor3Minutes };
};
