import { convertLocalTimetoUTCString } from './livestream.model';

export interface IRescheduleTimeslotCommand {
    starts_at: string;
    ends_at: string;
}

export interface IRescheduleTimeslot {
    startsAt: Date;
    endsAt: Date;
}

export const createRescheduleTimeslotCommand = ({
    startsAt,
    endsAt,
}: IRescheduleTimeslot): IRescheduleTimeslotCommand => {
    return {
        starts_at: convertLocalTimetoUTCString(startsAt),
        ends_at: convertLocalTimetoUTCString(endsAt),
    };
};
