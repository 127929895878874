import { useObservable } from '@mindspace-io/react';
import { AxiosResponse } from 'axios';
import { IEntitiesContext } from 'core/constants/entities.context';
import { IEntity } from 'core/interfaces/entity.interface';
import { useEffect } from 'react';

export const useEntitiesBuilder =
    <T extends IEntity, R>(
        { store, query }: IEntitiesContext<T, R>,
        useV2?: boolean
    ) =>
    (
        responseHandler?: (res: AxiosResponse) => R[]
    ): [T[], () => Promise<T[] | null>] => {
        const [trainers] = useObservable(query.selectAll(), query.getAll());

        useEffect(() => {
            const fetchEntities = useV2
                ? store.fetchEntitiesV2
                : store.fetchEntities;

            fetchEntities(
                {
                    searchTerm: '',
                    pageNumber: 1,
                    pageSize: 100000,
                },
                responseHandler,
                true
            );
        }, [store, useV2, responseHandler]);

        return [trainers, () => store.fetchEntities()];
    };
