import { ITrainer } from './trainer.model';

export interface ILanguage {
    name: string;
    nativeName: string;
}

export enum LANGUAGES_PRIMARY {
    English = 'English',
    Italian = 'Italian',
    German = 'German',
    French = 'French',
}

export const getPrimaryLanguagesOfTrainer = (
    trainer: ITrainer
): Array<string> => {
    return trainer.languages
        ? trainer.languages.filter((language) => {
              return (
                  language === LANGUAGES_PRIMARY.English ||
                  language === LANGUAGES_PRIMARY.French ||
                  language === LANGUAGES_PRIMARY.German ||
                  language === LANGUAGES_PRIMARY.Italian
              );
          })
        : [];
};

export const getAdditionalLanguagesOfTrainer = (
    trainer: ITrainer
): Array<string> => {
    return trainer.languages
        ? trainer.languages.filter((language) => {
              return (
                  language !== LANGUAGES_PRIMARY.English &&
                  language !== LANGUAGES_PRIMARY.French &&
                  language !== LANGUAGES_PRIMARY.German &&
                  language !== LANGUAGES_PRIMARY.Italian
              );
          })
        : [];
};
