import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { MultiLanguageSupportContext } from '../MultiLanguageSupportProvider';
import { Thumbnail } from '../shared/Thumbnail';
import './style.scss';

export interface ImageUploadProps {
    url: string | null;
    reset?: boolean;
    onChange?: (data: File | null) => void;
    renderSupportedFile: () => JSX.Element;
}

export const ThumbnailUpload: FC<ImageUploadProps & IReadonlyProps> = ({
    readonly,
    url,
    reset,
    onChange,
    renderSupportedFile,
}): JSX.Element => {
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string>('');
    const fileInput = useRef<HTMLInputElement>(null);
    const { t } = useContext(MultiLanguageSupportContext);
    const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
    const [hidePreview, setHidePreview] = useState(false);

    useEffect(() => {
        setPreview(url);
    }, [url]);

    useEffect(() => {
        if (reset) {
            onRemove();
        }
    }, [reset]);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        const file: File | null = files?.length ? files[0] : null;
        if (file) {
            setError('');
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                setError(t('not-supported-file-type'));
                return;
            }
            if (file.size > 2097152) {
                setError(t('image-size-error'));
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e: any) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    const ratio = 9 / 16;
                    const ratioMax = ratio + 0.02;
                    const ratioMin = ratio - 0.02;
                    const ratioImg = img.height / img.width;
                    if (ratioImg >= ratioMin && ratioImg <= ratioMax) {
                        if (reader.result) {
                            setPreview(reader.result);
                            setFile(file);

                            if (onChange) {
                                onChange(file);
                            }
                        }
                    } else {
                        setError(
                            t('not-supported-image-ratio', { format: '16:9' })
                        );
                        return false;
                    }
                };
            };
        }
    };

    const triggerInputFile = () => {
        if (!readonly) {
            fileInput.current?.click();
        }
    };

    const onRemove = () => {
        setFile(null);
        setError('');
        if (onChange) {
            onChange(null);
        }
    };

    return (
        <>
            <div className="thumbnailUploadContainer">
                <div
                    className="choose-file-container"
                    onMouseOver={() => !readonly && setHidePreview(true)}
                    onMouseLeave={() => !readonly && setHidePreview(false)}
                    onClick={triggerInputFile}
                >
                    {preview && <Thumbnail url={preview as string} />}
                    {(!preview || hidePreview) && (
                        <div className="choose-file-text">
                            <WallpaperIcon color="inherit" fontSize="large" />
                            <p>{t('browse-image')}</p>
                        </div>
                    )}
                </div>

                <input
                    type="file"
                    id="image-upload"
                    ref={fileInput}
                    accept=".png, .jpg, .jpeg"
                    value=""
                    onChange={onFileChange}
                    readOnly={readonly}
                    hidden
                />
            </div>
            <div className="imageUploadButtons">
                {file && !readonly && (
                    <button onClick={onRemove} className="enabled">
                        {t('remove-image')}
                    </button>
                )}
            </div>
            {error && <p className="thumbnailError">{error}</p>}
            <div className="supportedFile">{renderSupportedFile()}</div>
        </>
    );
};
