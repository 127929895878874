import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { ReactComponent as FacebookIcon } from 'assets/images/facebook_icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/instagram_icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/images/linkedin_icon.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube_icon.svg';
import { Button } from 'core/components/Button';
import { FormInput } from 'core/components/FormInput';
import { ImageUpload } from 'core/components/ImageUpload';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { RadioButtonsGroup } from 'core/components/RadioButtonsGroup';
import { useActiveTab } from 'core/hooks/active-tab.hook';
import { useAdditionalLanguages } from 'core/hooks/additional-languages.hook';
import { useEditMode } from 'core/hooks/edit-mode.hook';
import { validateEmailhelper } from 'core/utils/validateEmail';
import { validateInputText } from 'core/utils/validateInputText';
import { useFormik } from 'formik';
import {
    ILanguage,
    LANGUAGES_PRIMARY,
} from 'modules/trainers/models/language.model';
import { UserRole } from 'modules/users/constants/role-permissions';
import { UserRoles } from 'modules/users/models/user.model';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import './style.scss';

export interface AccountDetailsData {
    id?: string;
    email: string;
    first_name: string;
    last_name: string;
    contact_number1: string;
    contact_number2?: string;
    languages: Array<string>;
    roles: Array<UserRoles>;
    facebook?: string | null;
    instagram?: string | null;
    linkedin?: string | null;
    youtube?: string | null;
    avatar?: any;
    checkedAdditionalLanguages?: Array<string>;
}
export interface AccountDetailsProps {
    data: AccountDetailsData;
    onNextClick?: (data: AccountDetailsData) => void;
    onCancelClick?: (data?: any) => void;
}

export const AccountDetails: FC<AccountDetailsProps> = ({
    data,
    onNextClick,
    onCancelClick,
}): JSX.Element => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { activeTab, updateTabsUI, updateValidatedTabIndex } = useActiveTab();
    const { updateEditMode, edit } = useEditMode();
    const [activeTabEdit, setActiveTabEdit] = useState<boolean>(false);
    const additionalLanguages = useAdditionalLanguages();

    const languagesArray = [
        LANGUAGES_PRIMARY.English,
        LANGUAGES_PRIMARY.French,
        LANGUAGES_PRIMARY.Italian,
        LANGUAGES_PRIMARY.German,
    ];

    const formik = useFormik<AccountDetailsData>({
        initialValues: {
            ...data,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            if (onNextClick) {
                onNextClick(values);
            }
            updateTabsUI(activeTab + 1, activeTab + 1);
        },
        validate: (values) => {
            const errors: Partial<AccountDetailsData> = {};

            if (!values.first_name) {
                errors.first_name = t('required');
            } else if (!validateInputText(values.first_name)) {
                errors.first_name = t('invalid-input');
            }

            if (!values.last_name) {
                errors.last_name = t('required');
            } else if (!validateInputText(values.last_name)) {
                errors.last_name = t('invalid-input');
            }

            if (!values.email) {
                errors.email = t('required');
            } else if (!validateEmailhelper(values.email)) {
                errors.email = t('invalid-email-error');
            }

            if (!values.contact_number1) {
                errors.contact_number1 = t('required');
            } else if (!validateInputText(values.contact_number1)) {
                errors.contact_number1 = t('invalid-input');
            }

            if (values.languages.length === 0) {
                errors.languages = [t('required-language')];
            }

            return errors;
        },
    });

    useEffect(() => {
        if (activeTabEdit) {
            // if tab is edited, set validated tab index to current active tab
            // reset steps
            updateValidatedTabIndex(activeTab);
        }
    }, [activeTabEdit]);

    const handleChange = (e: any) => {
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }
        formik.handleChange(e);
    };

    const handleMultiSelect = (e: any) => {
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }
        formik.setValues({
            ...formik.values,
            checkedAdditionalLanguages: e.target.value,
        });
    };

    const handleRadioButtonChange = (e: any) => {
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }
        formik.setValues({
            ...formik.values,
            roles: [e.target.value],
        });
        // formik.handleChange(e);
    };

    const handleImageChange = (file: any) => {
        formik.setValues({
            ...formik.values,
            avatar: file,
        });
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }
    };

    const onTabCancel = () => {
        if (edit) {
            if (onCancelClick) {
                onCancelClick();
            }
        }
    };

    const radioButtons = useMemo(() => {
        return (
            <RadioButtonsGroup<AccountDetailsData, UserRoles>
                name="roles"
                groupLabel={t('granted-permission')}
                checkedValue={formik.values.roles[0]}
                items={[
                    {
                        label: t('basic-access'),
                        value: UserRole.ROLE_TRAINER,
                    },
                    {
                        label: t('master-access'),
                        value: UserRole.ROLE_MASTER_TRAINER,
                    },
                ]}
                onChange={(e) => handleRadioButtonChange(e)}
            />
        );
    }, [formik.values.roles, formik.values]);

    const languageCheckBoxes = useMemo(() => {
        return (
            <FormControl required>
                <FormLabel className="formCheckBoxLabel">
                    {t('languages')}
                </FormLabel>
                <div className="rowContainer">
                    <FormGroup>
                        {languagesArray.map((language) => (
                            <FormControlLabel
                                key={language}
                                className="formControlCheckBoxLabel"
                                label={language}
                                onChange={handleChange}
                                control={
                                    <Checkbox
                                        name="languages"
                                        checked={
                                            formik.values.languages[
                                                formik.values.languages.indexOf(
                                                    language
                                                )
                                            ]
                                                ? true
                                                : false
                                        }
                                        value={
                                            formik.values.languages[
                                                formik.values.languages.indexOf(
                                                    language
                                                )
                                            ] ?? language
                                        }
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </div>
                {formik.errors.languages &&
                    formik.errors.languages.length > 0 && (
                        <FormHelperText className="formHelperText error">
                            {formik.errors.languages?.[0]}
                        </FormHelperText>
                    )}
            </FormControl>
        );
    }, [formik.values.languages, formik.errors.languages]);

    const prepareAdditionalLanguages = () => {
        const languages: Array<string> = [];
        for (const languageKey in additionalLanguages) {
            if (
                Object.prototype.hasOwnProperty.call(
                    additionalLanguages,
                    languageKey
                )
            ) {
                const element: ILanguage = additionalLanguages[languageKey];
                languages.push(element.name);
            }
        }
        return languages;
    };

    const selectAdditionalLanguages = () => {
        return (
            <Box>
                <InputLabel className="formInputLabel" shrink={false}>
                    {t('select-additional-languages')}
                </InputLabel>
                <FormControl className="formControl">
                    <Select
                        className="formSelect"
                        autoWidth={true}
                        multiple
                        value={formik.values.checkedAdditionalLanguages}
                        onChange={handleMultiSelect}
                        input={<Input />}
                    >
                        {prepareAdditionalLanguages().map((language, index) => {
                            return (
                                <MenuItem key={index} value={language}>
                                    {language}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="accountDetailsContainer">
                <div className="rowContainer">
                    <div className="imageUploadContainer">
                        <ImageUpload
                            currentFile={formik.values.avatar}
                            onChange={handleImageChange}
                            renderSupportedFile={() => (
                                <>
                                    <p>{t('supported-image-types')}</p>
                                    <p> {t('maximum-image-size')}</p>
                                </>
                            )}
                        />
                    </div>
                    <div className="formAccountContainer">
                        <div className="rowContainer">
                            <FormInput<AccountDetailsData>
                                label={t('first-name')}
                                placeholder={t('first-name-placeholder')}
                                name="first_name"
                                type="text"
                                onChange={handleChange}
                                value={formik.values.first_name}
                                error={!!formik.errors.first_name}
                                textHelper={formik.errors.first_name}
                                required
                            />
                            <Box pr="30px" />
                            <FormInput<AccountDetailsData>
                                label={t('last-name')}
                                placeholder={t('last-name-placeholder')}
                                name="last_name"
                                type="text"
                                onChange={handleChange}
                                value={formik.values.last_name}
                                error={!!formik.errors.last_name}
                                textHelper={formik.errors.last_name}
                                required
                            />
                        </div>
                        <FormInput<AccountDetailsData>
                            label={t('email')}
                            placeholder={t('email-placeholder')}
                            name="email"
                            type="text"
                            onChange={handleChange}
                            value={formik.values.email}
                            error={!!formik.errors.email}
                            textHelper={formik.errors.email}
                            disabled={!!formik.values.id}
                            required
                        />

                        <Box
                            display="flex"
                            alignItems="flex-end"
                            flexWrap="wrap"
                        >
                            <FormInput<AccountDetailsData>
                                label={t('contact-number')}
                                placeholder={t(
                                    'contact-number-primary-placeholder'
                                )}
                                name="contact_number1"
                                onChange={handleChange}
                                value={formik.values.contact_number1}
                                error={!!formik.errors.contact_number1}
                                required
                            />
                            <Box pr="30px" />
                            <FormInput<AccountDetailsData>
                                label=""
                                placeholder={t(
                                    'contact-number-secondary-placeholder'
                                )}
                                name="contact_number2"
                                onChange={handleChange}
                                value={formik.values.contact_number2}
                            />
                        </Box>
                        {formik.errors.contact_number1 && (
                            <FormHelperText className="formHelperText error">
                                {formik.errors.contact_number1}
                            </FormHelperText>
                        )}

                        {/* Languages checkbox */}
                        {languageCheckBoxes}

                        {/* Select additional languages */}
                        {selectAdditionalLanguages()}

                        {/* Granted permissions radio buttons */}
                        {radioButtons}

                        {/* Social accounts */}
                        <FormInput<AccountDetailsData>
                            label={t('add-social-media-accounts')}
                            placeholder=""
                            name="facebook"
                            onChange={handleChange}
                            value={formik.values.facebook}
                            startAdorment={<FacebookIcon />}
                        />
                        <FormInput<AccountDetailsData>
                            placeholder=""
                            name="instagram"
                            onChange={handleChange}
                            value={formik.values.instagram}
                            startAdorment={<InstagramIcon />}
                        />
                        <FormInput<AccountDetailsData>
                            placeholder=""
                            name="linkedin"
                            onChange={handleChange}
                            value={formik.values.linkedin}
                            startAdorment={<LinkedinIcon />}
                        />
                        <FormInput<AccountDetailsData>
                            placeholder=""
                            name="youtube"
                            onChange={handleChange}
                            value={formik.values.youtube}
                            startAdorment={<YoutubeIcon />}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <Button
                        type="button"
                        onClick={onTabCancel}
                        label={t('cancel')}
                        color="default"
                        style={{ width: '184px', height: '40px' }}
                    />
                    <Button
                        type="submit"
                        label={t('next')}
                        color="primary"
                        style={{ width: '184px', height: '40px' }}
                    />
                </div>
            </div>
        </form>
    );
};
