import { Box } from '@material-ui/core';
import React, { FC } from 'react';

interface ITrainerListItemTemplate {
    createdByRole: JSX.Element | null;
    expertise: JSX.Element;
    fullName: JSX.Element;
}

export const TrainerListItemTemplate: FC<ITrainerListItemTemplate> = ({
    createdByRole,
    expertise,
    fullName,
}) => (
    <>
        <Box
            display="flex"
            flex={1}
            padding="4px 20px 4px"
            maxHeight="40px"
            overflow="hidden"
        >
            {fullName}
        </Box>
        <Box
            display="flex"
            flex={1.5}
            padding="4px 20px 4px"
            maxHeight="40px"
            overflow="hidden"
        >
            {expertise}
        </Box>
        {createdByRole && (
            <Box
                display="flex"
                flex={1.5}
                padding="4px 20px 4px"
                maxHeight="40px"
                overflow="hidden"
            >
                {createdByRole}
            </Box>
        )}
    </>
);
