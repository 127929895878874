import {
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

interface FormInput<T> {
    /**
     * Name attribute of the `input` element.
     */
    name: keyof T;

    /**
     * Label text of the `input` element.
     */
    label: string;

    /**
     * Placeholder attribute of the `input` element.
     */
    placeholder: string;

    /**
     * Type attribute of the `input` element. Should be valid HTML5 type atribute.
     */
    type?: string;

    /**
     * Error text attribute of the `input` element.
     */
    textHelper?: string;

    /**
     * Validate function callback of the `input` element.
     */
    validate?: (data: string) => boolean;
}

export interface FormConfig<T> {
    logo?: JSX.Element;
    title?: string;
    text?: string;
    inputs?: Array<FormInput<T>>;
    buttonText?: string;
    linkText?: string;
    linkRoute?: string;
    errorText?: string | null;
    successText?: string | null;
    onSubmitClick?: (data?: any) => void;
    onLinkClick?: (data?: any) => void;
}

interface Data {
    [key: string]: string;
}

interface ErrorsMap {
    [key: string]: boolean;
}

/**
 * Reusable Form component.
 *
 * @param {*} { onSubmitClick, config }
 * @return {*}  {JSX.Element}
 */
export const AuthForm = <T,>(config: FormConfig<T>): JSX.Element => {
    const [data, setData] = useState<Data>();
    const [showPassword, setShowPassword] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [succesInfo, setSuccesInfo] = useState<boolean>(
        config?.successText ? true : false
    );
    const [validateErrors, setValidateErrors] = useState<ErrorsMap>(() => {
        let map!: ErrorsMap;
        config?.inputs?.forEach((input, index) => {
            map = {
                ...map,
                [index]: false,
            };
        });

        return map;
    });

    useEffect(() => {
        if (data) {
            if (config?.inputs?.length === Object.keys(data).length) {
                let found = false;
                for (const item in data) {
                    if (data[item] === '') {
                        found = true;
                    }
                }
                setDisableSubmit(found);
            }
        }
    }, [data]);

    useEffect(() => {
        // check if there are errors
        let found = false;
        for (const key in validateErrors) {
            if (validateErrors[key]) {
                found = true;
            }
        }

        if (found) {
            setDisableSubmit(found);
        }
    }, [validateErrors]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (config?.onSubmitClick) {
            // map data to appropriate interface
            config?.onSubmitClick(data);
            if (succesInfo) {
                setSuccesInfo(false);
            }
        }
    };

    const onLinkClick = () => {
        if (config?.onLinkClick) {
            config?.onLinkClick(data);
        }
    };

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: number,
        validate?: (data: string) => boolean
    ) => {
        if (validate) {
            setValidateErrors({
                ...validateErrors,
                [key]: !validate(e.target.value),
            });
        }

        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e: any) => {
        e.preventDefault();
    };

    return (
        <>
            <div className="formContainer">
                <form className="formBody" onSubmit={onSubmit}>
                    {config?.logo}
                    <p className="formError">{config?.errorText}</p>
                    {succesInfo && (
                        <p className="formSuccess">{config?.successText}</p>
                    )}
                    <p className="formTitle">{config?.title}</p>
                    <p className="formText">{config?.text}</p>
                    {config?.inputs && config?.inputs.length > 0
                        ? config.inputs.map(
                              (
                                  {
                                      name,
                                      label,
                                      placeholder,
                                      type,
                                      textHelper,
                                      validate,
                                  },
                                  index
                              ) => (
                                  <FormControl
                                      key={index}
                                      className="formControl"
                                  >
                                      <InputLabel
                                          className="formLabel"
                                          shrink={true}
                                      >
                                          {label}
                                      </InputLabel>
                                      <Input
                                          key={index}
                                          className={`formInput ${
                                              validateErrors?.[index]
                                                  ? 'error'
                                                  : ''
                                          }`}
                                          name={(name as unknown) as string}
                                          placeholder={placeholder}
                                          onChange={(e) =>
                                              handleInputChange(
                                                  e,
                                                  index,
                                                  validate
                                              )
                                          }
                                          error={validateErrors?.[index]}
                                          type={
                                              type === 'password' &&
                                              showPassword
                                                  ? 'text'
                                                  : type !== 'password'
                                                  ? 'text'
                                                  : 'password'
                                          }
                                          endAdornment={
                                              type === 'password' && (
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          className="formPasswordIcon"
                                                          aria-label="toggle password visibility"
                                                          onClick={
                                                              handleClickShowPassword
                                                          }
                                                          onMouseDown={
                                                              handleMouseDownPassword
                                                          }
                                                      >
                                                          {showPassword ? (
                                                              <Visibility />
                                                          ) : (
                                                              <VisibilityOff />
                                                          )}
                                                      </IconButton>
                                                  </InputAdornment>
                                              )
                                          }
                                      />
                                      {validate && validateErrors?.[index] ? (
                                          <FormHelperText className="formHelperText error">
                                              {textHelper}
                                          </FormHelperText>
                                      ) : (
                                          <></>
                                      )}
                                  </FormControl>
                              )
                          )
                        : null}
                    <button
                        disabled={disableSubmit}
                        className={`formButton ${
                            disableSubmit ? `` : `active`
                        }`}
                    >
                        {config?.buttonText}
                    </button>
                </form>
                <Link
                    className="formLink"
                    to={(location) => {
                        if (!config?.onLinkClick) {
                            return {
                                ...location,
                                pathname: config?.linkRoute,
                                state: null,
                            };
                        } else {
                            return {
                                ...location,
                                state: null,
                            };
                        }
                    }}
                    onClick={onLinkClick}
                >
                    {config?.linkText}
                </Link>
            </div>
        </>
    );
};

export default AuthForm;
