import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import {
    supportedLanguages,
    defaultLanguage,
} from 'core/constants/supported-language';
import { LocalStorageKeys } from 'core/enums/local-storage-keys.enum';

i18n.use(initReactI18next)
    .use(Backend)
    .init({
        lng: localStorage.getItem(LocalStorageKeys.appLang) || defaultLanguage,
        fallbackLng: defaultLanguage,
        whitelist: supportedLanguages,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
