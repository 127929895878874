import {
    FormControl as FormControlMUI,
    FormHelperText,
    Input as InputMUI,
    InputAdornment,
    InputLabel as InputLabelMUI,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import './style.scss';

export interface FormInputProps<T> {
    /**
     * Name attribute of the `input` element.
     */
    name?: keyof T;

    /**
     * Value attribute of the `input` element.
     */
    value?: any;

    /**
     * Required attribut of the `input` element.
     */
    required?: boolean;

    /**
     * Label text of the `input` element.
     */
    label?: string;

    /**
     * Placeholder attribute of the `input` element.
     */
    placeholder?: string;

    /**
     * Type attribute of the `input` element. Should be valid HTML5 type atribute.
     */
    type?: string;

    /**
     * Error text attribute of the `input` element.
     */
    textHelper?: string;

    /**
     * Validate function callback of the `input` element.
     */
    validate?: (event: any) => void;

    /**
     * Onchange function callback of the `input` element.
     */
    onChange?: (event: any) => void;

    /**
     * Onblur function callback of the `input` element.
     */
    onBlur?: (event: any) => void;

    /**
     * Start adorment attribute of the `input` element.
     */
    startAdorment?: JSX.Element;

    /**
     * Error attribute of the `input` element.
     */
    error?: boolean;

    /**
     * ReadOnly attribute of the `input` element.
     */
    readOnly?: boolean;

    /**
     * Disabled attribute of the `input` element.
     */
    disabled?: boolean;
}

export const FormInput = <T,>(props: FormInputProps<T>): JSX.Element => {
    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (props.onChange) {
            props.onChange(e);
        }

        if (props.validate) {
            props.validate(e);
        }
    };

    return (
        <FormControlMUI required={props.required} className="formControl">
            {props.label && (
                <InputLabelMUI className="formLabel" shrink={true}>
                    {props.label}
                </InputLabelMUI>
            )}
            <InputMUI
                className="formInput"
                name={(props.name as unknown) as string}
                placeholder={props.placeholder}
                onChange={handleInputChange}
                onBlur={props.onBlur}
                type={props.type}
                error={props.error}
                value={props.value}
                disabled={props.disabled}
                readOnly={props.readOnly}
                startAdornment={
                    props.startAdorment && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <InputAdornment
                                style={{ width: '22px', marginRight: '0 px' }}
                                position="start"
                            >
                                {props.startAdorment}
                            </InputAdornment>
                        </div>
                    )
                }
            />

            {props.error && props.textHelper && (
                <FormHelperText className="formHelperText error">
                    {props.textHelper}
                </FormHelperText>
            )}
        </FormControlMUI>
    );
};
