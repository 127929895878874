export interface ICertificateResponse {
    id: number;
    name: string;
}

export interface ICertificate {
    id: string;
    name: string;
}

export const createCertificateFromResponse = ({
    id,
    ...props
}: ICertificateResponse): ICertificate => {
    return {
        id: id.toString(),
        ...props,
    };
};
