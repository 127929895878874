export enum ROUTES {
    NOT_FOUND = '*',
    DEFAULT = '/',
    LOGIN = '/login',
    RESET_PASSWORD = '/reset-password',
    RESET_PASSWORD_HASH = '/reset-password/:hash',
    GENERATE_PASSWORD_HASH = '/generate-password/:hash',
    MY_PROFILE = '/my-profile',
    MY_PROFILE_EDIT = '/my-profile/edit',
    TRAINERS = '/trainers',
    TRAINER_ADD = '/trainers/add',
    TRAINER_VIEW = '/trainers/:id',
    TRAINER_EDIT = '/trainers/:id/edit',

    MY_TRAININGS_ADD = '/add-training-video',
    MY_TRAININGS = '/my-training-videos',
    MY_TRAININGS_SINGLE = '/my-training-videos/:id',
    ALL_TRAININGS = '/all-training-videos',
    ALL_TRAININGS_SINGLE = '/all-training-videos/:id',
    PENDING_TRAININGS = '/pending-training-videos',
    PENDING_TRAININGS_SINGLE = '/pending-training-videos/:id',

    MY_LIVESTREAMS_ADD = '/add-livestream-video',
    MY_LIVESTREAMS = '/my-livestream-videos',
    MY_LIVESTREAMS_SINGLE = '/my-livestream-videos/:id',
    ALL_LIVESTREAMS = '/all-livestream-videos',
    ALL_LIVESTREAMS_SINGLE = '/all-livestream-videos/:id',
    PENDING_LIVESTREAMS = '/pending-livestream-videos',
    PENDING_LIVESTREAMS_SINGLE = '/pending-livestream-videos/:id',
    LIVESTREAMS_LIVE = '/livestream-live/:id',
    LIVESTREAM_CALENDAR = '/livestream-calendar/:id',
    LIVESTREAM_CALENDAR_MOBILE = 'airex://mobile.airex.app/livestream-calendar/:id',

    NOTIFICATIONS = '/notifications',
}
