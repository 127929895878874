import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { WithProvider } from 'core/hocs/WithProvider';
import { IBreadcrumPathProps } from 'core/interfaces/breadcrumb-path-props.interface';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { useContext } from 'react';
import { CreateTrainingDialog } from '../components/CreateTrainingDialog';
import { createTraining } from '../models/training.model';
import { trainingsQuery } from '../state/trainings.query';
import { ITrainingPageProps, TrainingPage } from './TrainingPage';

export const AddTrainingPageContainer = WithProvider(() => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { id, videoClip, video, ...initialValues } = createTraining({
        title: t('new-training'),
    });

    const props: ITrainingPageProps & IBreadcrumPathProps = {
        id,
        videoClip,
        video,
        initialValues,
        breadcrumbPath: ROUTES.MY_TRAININGS,
    };

    return (
        <>
            <TrainingPage {...props} />
            <CreateTrainingDialog />
        </>
    );
}, trainingsQuery);
