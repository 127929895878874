import React, { FC } from 'react';
import { EntityProvider } from '../components/EntityProvider';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesQuery } from '../state/entities.query';

export const WithProvider = <T extends IEntity, R>(
    WrappedComponent: any,
    query: EntitiesQuery<T>
): FC => {
    const HocComponent = ({ ...props }) => (
        <EntityProvider query={query as any}>
            <WrappedComponent {...props} />
        </EntityProvider>
    );

    Object.defineProperty(HocComponent, 'name', {
        value: `WithEntityProvider(${WrappedComponent.name})`,
        configurable: true,
    });

    return HocComponent;
};
