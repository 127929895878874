import { ErrorBoundary } from 'core/components/ErrorBoundary';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { PageContainer } from 'core/components/PageContainer';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { LivestreamList } from '../components/LivestreamList/LivestreamList';
import { LivestreamListActions } from '../components/LivestreamListActions';
import { LiveStreamState } from '../models/livestream.model';
import { livestreamsQuery } from '../state/livestreams.query';

interface MyLivestreamsPageTabs {
    title: string;
    state: LiveStreamState | null;
}

const MyLivestreamsPageWithoutProvider: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchFilters, getFilter } = useCrud();
    const { me } = useSession();
    const [initialIndex, setInitialIndex] = useState<number>();
    const hasPermission = usePermissions();

    // useEffect(() => {
    // return () => setFilter({ role: null });
    // }, []);

    useEffect(() => {
        if (me) {
            const state = null; // getFilter('state');

            patchFilters(
                {
                    state,
                    current_user: true,
                    order_by: 'startsAt',
                    order_direction: 'desc',
                },
                true
            );
        }
    }, [me, patchFilters]);

    const tabs: MyLivestreamsPageTabs[] = useMemo(() => {
        const tabsArray: MyLivestreamsPageTabs[] = [
            { title: t('all-my-livestreams'), state: null },
            { title: t('approved'), state: LiveStreamState.APPROVED },
            { title: t('draft'), state: LiveStreamState.DRAFT },
        ];
        if (hasPermission(PERMISSIONS.MY_VIDEOS_PENDING)) {
            tabsArray.push({
                title: t('pending_approval'),
                state: LiveStreamState.PENDING,
            });
        }

        if (hasPermission(PERMISSIONS.MY_VIDEOS_DECLINED)) {
            tabsArray.push({
                title: t('declined'),
                state: LiveStreamState.DECLINED,
            });
        }
        return tabsArray;
    }, [t]);

    useEffect(() => {
        setInitialIndex(
            tabs.map(({ state }) => state).indexOf(getFilter('state'))
        );
    }, [tabs, getFilter]);

    const renderContent = useCallback(
        (activeTabIndex?: number) => {
            if (typeof activeTabIndex !== 'undefined') {
                const { state } = tabs[activeTabIndex];

                patchFilters({ state });
            }

            return (
                <ErrorBoundary>
                    <LivestreamList
                        showState={activeTabIndex === 0}
                        renderActions={(props) => (
                            <LivestreamListActions {...props} />
                        )}
                    />
                </ErrorBoundary>
            );
        },
        [tabs, patchFilters]
    );

    return typeof initialIndex === 'undefined' ? (
        <></>
    ) : (
        <PageContainer
            header={{ title: t('my-livestreams') }}
            tabs={tabs.map(({ title }) => title)}
            initialIndex={initialIndex}
            renderContent={renderContent}
        />
    );
};

export const MyLivestreamsPage = WithProvider(
    WithCurrentPageListener(MyLivestreamsPageWithoutProvider),
    livestreamsQuery
);
