import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormInput } from 'core/components/FormInput';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { apiService } from 'core/services/apiService';
import { useFormik } from 'formik';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { useTrainers } from 'modules/trainers/hooks/trainers.hook';
import {
    ITraining,
    ITrainingDetails,
    ITrainingResponse,
} from 'modules/trainings/models/training.model';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { fullName } from 'modules/users/models/user.model';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import './style.scss';

type ICreateTrainingData = Pick<ITraining, 'title' | 'type' | 'trainerName'>;

export const CreateTrainingDialog = (): JSX.Element => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me } = useSession();
    const { goBack, push } = useHistory();
    const [openFormDialog, setOpenFormDialog] = useState(true);
    const hasPermission = usePermissions();
    const [trainers] = useTrainers();
    const [errorOnCreateTraining, setErrorOnCreateTraining] = useState('');

    const formik = useFormik<ICreateTrainingData>({
        initialValues: {
            title: '',
            type: 'training',
            trainerName:
                me && !hasPermission(PERMISSIONS.SELECT_TRAINER)
                    ? fullName(me)
                    : '',
        },
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: async ({ type, trainerName: trainer_name, ...data }) => {
            try {
                const { id } = apiService.responseHandler(
                    await apiService.post<ITrainingResponse>(`/trainings`, {
                        trainer_name,
                        type,
                        ...data,
                    })
                );

                push(
                    pathBuilder(
                        ROUTES.MY_TRAININGS_SINGLE,
                        ':id',
                        id.toString()
                    )
                );

                setOpenFormDialog(false);
            } catch (error: any) {
                let errorMessage = t('error');

                const exception = error?.debug.exception;

                if (exception?.length) {
                    errorMessage = exception[0]?.message ?? errorMessage;
                }

                setErrorOnCreateTraining(errorMessage);
            }
        },
        validate: (values) => {
            const errors: Partial<ITrainingDetails> = {};

            if (!values.title?.trim()) {
                errors.title = 'Required';
            }

            if (!values.trainerName?.trim()) {
                errors.trainerName = 'Required';
            }

            return errors;
        },
    });

    const onCreateClick = () => {
        setErrorOnCreateTraining('');
        formik.handleSubmit();
    };

    const onCancelClick = () => {
        setOpenFormDialog(false);
        goBack();
    };

    return (
        <Dialog
            open={openFormDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <div className="uploadTrainingFormDialogContainer">
                <DialogTitle>{t('add-training')}</DialogTitle>
                <DialogContent>
                    {errorOnCreateTraining && (
                        <Box mb="20px">
                            <FormHelperText className="formHelperText error">
                                {errorOnCreateTraining}
                            </FormHelperText>
                        </Box>
                    )}
                    <form className="dialogForm">
                        <FormInput<ITrainingDetails>
                            label={t('video-title')}
                            name="title"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            error={
                                !!(formik.errors.title && formik.touched.title)
                            }
                            textHelper={formik.errors.title}
                            required
                        />

                        {hasPermission(PERMISSIONS.SELECT_TRAINER) ? (
                            <div className="selectTrainerContainer">
                                <Autocomplete
                                    options={trainers
                                        .filter(({ active }) => active)
                                        .map((trainer) => fullName(trainer))}
                                    onChange={(_e, value) =>
                                        formik.setFieldValue(
                                            'trainerName',
                                            value
                                        )
                                    }
                                    onBlur={formik.handleBlur}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('trainer-name')}
                                        />
                                    )}
                                />
                                {formik.errors.trainerName &&
                                    formik.touched.trainerName && (
                                        <FormHelperText className="formHelperText error">
                                            {formik.errors.trainerName}
                                        </FormHelperText>
                                    )}
                            </div>
                        ) : (
                            <FormInput<ITrainingDetails>
                                label={t('trainer-name')}
                                name="trainerName"
                                type="text"
                                value={formik.values.trainerName}
                                readOnly={true}
                                required
                            />
                        )}

                        <div className="dialogButtons">
                            <button
                                type="button"
                                onClick={onCancelClick}
                                className="cancelButton"
                            >
                                {t('cancel')}
                            </button>
                            <button
                                type="button"
                                onClick={onCreateClick}
                                className="confirmButton"
                            >
                                {t('create')}
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </div>
        </Dialog>
    );
};
