import { useObservable } from '@mindspace-io/react';
import { EntitiesContext } from 'core/constants/entities.context';
import { useContext } from 'react';

interface IUseActiveTab {
    activeTab: number;
    validatedTab: number;
    updateTabsUI: (activeTabIndex: number, validatedTabIndex: number) => void;
    updateActiveTab: (activeTabIndex: number) => void;
    updateValidatedTabIndex: (validatedTabIndex: number) => void;
}

export const useActiveTab = (): IUseActiveTab => {
    const { query, store } = useContext(EntitiesContext);

    const { activeTabIndex, validatedTabIndex } = store.getValue();

    const [activeTab] = useObservable(query.activeTabIndex$, activeTabIndex);
    const [validatedTab] = useObservable(
        query.validatedTabIndex$,
        validatedTabIndex
    );

    const updateTabsUI = (
        activeTabIndex: number,
        validatedTabIndex: number
    ) => {
        store.update({
            activeTabIndex,
            validatedTabIndex,
        });
    };

    const updateActiveTab = (activeTabIndex: number) => {
        store.update({
            activeTabIndex,
        });
    };

    const updateValidatedTabIndex = (validatedTabIndex: number) => {
        store.update({
            validatedTabIndex,
        });
    };

    return {
        activeTab,
        validatedTab,
        updateTabsUI,
        updateActiveTab,
        updateValidatedTabIndex,
    };
};
