import { Box, FormHelperText } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { ConfirmationDialogContext } from 'core/components/ConfirmationDialog/ConfirmationDialogProvider';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { TabProps, Tabs } from 'core/components/Tabs';
import { useActiveTab } from 'core/hooks/active-tab.hook';
import { useAirexCertificate } from 'core/hooks/airex-certificate.hook';
import { useAreaOfExpertiseData } from 'core/hooks/area-of-expertise.hook';
import { useEditMode } from 'core/hooks/edit-mode.hook';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { useSession } from 'core/hooks/session.hook';
import { encodeImageFileAsURL } from 'core/utils/encodeImageFileAsURL';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import {
    AccountDetails,
    AccountDetailsData,
} from 'modules/trainers/components/AccountDetails';
import { AirexCertificate } from 'modules/trainers/components/AirexCertificate';
import { Education } from 'modules/trainers/components/Education';
import { CONTACT_NUMBER } from 'modules/trainers/enums/contactNumbers.enum';
import { SOCIAL } from 'modules/trainers/enums/socialLinks.enum';
import { useTrainer } from 'modules/trainers/hooks/useTrainer.hook';
import {
    AddTrainerCredentials,
    createTrainerData,
} from 'modules/trainers/models/add-trainer.model';
import {
    getAdditionalLanguagesOfTrainer,
    getPrimaryLanguagesOfTrainer,
} from 'modules/trainers/models/language.model';
import { ITrainer } from 'modules/trainers/models/trainer.model';
import { UserRole } from 'modules/users/constants/role-permissions';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { fullName } from 'modules/users/models/user.model';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { AreaOfExpertise } from '../../components/AreaOfExpertise';
import './style.scss';

export interface EditTrainerData {
    accountDetails: AccountDetailsData;
    areaOfExpertise: Array<string>;
    education: Array<string>;
    certificates: Array<string>;
}

export interface EditTrainerProps {
    id: string;
    onEdit?: (data: AddTrainerCredentials) => void;
}

export const EditTrainer: FC<EditTrainerProps> = ({ id, onEdit }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { cleanErrorState } = useSession();
    const [trainer] = useEntityById<ITrainer>({ id, forceFetch: true });
    const { patchDialogProps, clearDialogProps, setDialogProps } = useContext(
        AirexDialogContext
    );
    const { activateTrainer, deactivateTrainer } = useTrainer();
    const { push } = useHistory();
    const hasPermission = usePermissions();

    const {
        activeTab,
        validatedTab,
        updateActiveTab,
        updateTabsUI,
    } = useActiveTab();
    const { edit, cleanEditMode } = useEditMode();
    const { setConfirmationDialogProps } = useContext(
        ConfirmationDialogContext
    );

    const [
        accountDetailsData,
        setAccountDetailsData,
    ] = useState<AccountDetailsData>({
        id: trainer?.id,
        first_name: trainer?.firstName ?? '',
        last_name: trainer?.lastName ?? '',
        email: trainer?.email ?? '',
        contact_number1: trainer?.contactNumber?.[CONTACT_NUMBER.PRIMARY] ?? '',
        languages: trainer ? getPrimaryLanguagesOfTrainer(trainer) : [],
        roles: trainer?.roles ?? [UserRole.ROLE_TRAINER],
        checkedAdditionalLanguages: trainer
            ? getAdditionalLanguagesOfTrainer(trainer)
            : [],
        facebook: trainer?.social?.[SOCIAL.FACEBOOK] ?? '',
        instagram: trainer?.social?.[SOCIAL.INSTAGRAM] ?? '',
        linkedin: trainer?.social?.[SOCIAL.LINKEDIN] ?? '',
        youtube: trainer?.social?.[SOCIAL.YOUTUBE] ?? '',
        avatar: trainer?.avatar,
    });

    const expertises = useAreaOfExpertiseData();
    const [expertisesData, setExpertisesData] = useState<Array<string>>([]);

    const [educationData, setEducationData] = useState<Array<string>>(
        trainer?.education ?? []
    );

    const certificates = useAirexCertificate();
    const [certificatesData, setCertificatesData] = useState<Array<string>>([]);

    useEffect(() => {
        setAccountDetailsData({
            id: trainer?.id,
            first_name: trainer?.firstName ?? '',
            last_name: trainer?.lastName ?? '',
            email: trainer?.email ?? '',
            contact_number1:
                trainer?.contactNumber?.[CONTACT_NUMBER.PRIMARY] ?? '',
            languages: trainer ? getPrimaryLanguagesOfTrainer(trainer) : [],
            roles: trainer?.roles ?? [UserRole.ROLE_TRAINER],
            checkedAdditionalLanguages: trainer
                ? getAdditionalLanguagesOfTrainer(trainer)
                : [],
            facebook: trainer?.social?.[SOCIAL.FACEBOOK] ?? '',
            instagram: trainer?.social?.[SOCIAL.INSTAGRAM] ?? '',
            linkedin: trainer?.social?.[SOCIAL.LINKEDIN] ?? '',
            youtube: trainer?.social?.[SOCIAL.YOUTUBE] ?? '',
            avatar: trainer?.avatar,
        });

        setEducationData(trainer?.education ?? []);
    }, [trainer]);

    useEffect(() => {
        const preparedExpertisesData: Array<string> = [];
        expertises.forEach((expertise) => {
            if (
                trainer?.expertise?.findIndex(
                    (element) => element.name === expertise.name
                ) !== -1
            ) {
                preparedExpertisesData.push(expertise.id);
            }
        });
        setExpertisesData(preparedExpertisesData);
    }, [expertises, trainer]);

    useEffect(() => {
        const prepareCertificatesData: Array<string> = [];
        certificates.forEach((certificate) => {
            if (
                trainer?.certificates?.findIndex(
                    (element) => element.name === certificate.name
                ) !== -1
            ) {
                prepareCertificatesData.push(certificate.id);
            }
        });
        setCertificatesData(prepareCertificatesData);
    }, [certificates, trainer]);

    const [base64UrlImage, setBase64UrlImage] = useState<
        string | ArrayBuffer | null | File
    >();

    const handleAccountDetailsSubmit = (
        accountDetailsData: AccountDetailsData
    ) => {
        if (
            accountDetailsData.avatar &&
            typeof accountDetailsData.avatar !== 'string'
        ) {
            encodeImageFileAsURL(accountDetailsData.avatar, setBase64UrlImage);
        }

        setAccountDetailsData(accountDetailsData);
    };

    const handleAreaOfExpertiseSubmit = (checkedExpertises: Array<string>) => {
        setExpertisesData(checkedExpertises);
    };

    const handleEducationSubmit = (educationData: Array<string>) => {
        setEducationData(educationData);
    };

    const handleCertificatesSubmit = async (
        checkedCertificates: Array<string>
    ) => {
        setCertificatesData(checkedCertificates);

        const trainer = createTrainerData({
            accountDetails: {
                ...accountDetailsData,
                avatar: base64UrlImage ?? accountDetailsData.avatar,
            },
            areaOfExpertise: expertisesData,
            certificates: checkedCertificates,
            education: educationData,
        });

        if (onEdit) {
            onEdit(trainer);
        }
    };

    const showConfirmationDialog = () => {
        setConfirmationDialogProps({
            openDialog: true,
            dialogText: t('edit-dialog-text'),
            rightButtonLabel: t('cancel'),
            leftButtonLabel: t('yes'),
            onRightClick: onDialogDiscardClick,
            onLeftClick: () => onDialogConfirmClick(ROUTES.DEFAULT),
            onClose: onDialogClose,
        });
    };

    const tabsArray: Array<TabProps> = [
        {
            title: t('tab-account-details'),
            element: (
                <AccountDetails
                    data={accountDetailsData}
                    onNextClick={handleAccountDetailsSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
        {
            title: t('tab-area-of-expertise'),
            element: (
                <AreaOfExpertise
                    data={{
                        expertises: expertises,
                        checkedExpertises: [...expertisesData],
                    }}
                    onNextClick={handleAreaOfExpertiseSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
        {
            title: t('tab-education'),
            element: (
                <Education
                    data={educationData}
                    onNextClick={handleEducationSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
        {
            title: t('tab-certificate'),
            element: (
                <AirexCertificate
                    data={{
                        certificates: certificates,
                        checkedCertificates: [...certificatesData],
                    }}
                    onNextClick={handleCertificatesSubmit}
                    onCancelClick={showConfirmationDialog}
                />
            ),
            disabled: false,
        },
    ];
    const renderTabsArray = useCallback(() => {
        return tabsArray.map((tab, index) => {
            // if index of current tab is equal to active tab then enable it
            if (index === activeTab) {
                return { ...tab, disabled: false };
                // if index of current tab is lesser then index of validated tab then enable it
            } else if (index <= validatedTab) {
                return { ...tab, disabled: false };
                // else disable tab for clicking
            } else {
                return { ...tab, disabled: true };
            }
        });
    }, [
        activeTab,
        validatedTab,
        accountDetailsData,
        expertises,
        expertisesData,
        educationData,
        certificatesData,
        certificates,
    ]);

    const onDialogConfirmClick = (route?: ROUTES) => {
        setConfirmationDialogProps({
            openDialog: false,
        });
        route && push(route);
    };

    const onDialogDiscardClick = () => {
        setConfirmationDialogProps({
            openDialog: false,
        });
    };

    const onDialogClose = () => {
        setConfirmationDialogProps({
            openDialog: false,
        });
    };

    const onActivate = async () => {
        if (await activateTrainer(id)) {
            setDialogProps({
                openDialog: true,
                contentText: t('sucessfully-activated-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={() => {
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                content: (
                    <Box mb="20px">
                        <FormHelperText className="formHelperText error">
                            {t('error')}
                        </FormHelperText>
                    </Box>
                ),
                onClose: clearDialogProps,
            });
        }
    };

    const onDeactivate = async () => {
        if (await deactivateTrainer(id)) {
            setDialogProps({
                openDialog: true,
                contentText: t('sucessfully-deactivated-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={() => {
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                content: (
                    <Box mb="20px">
                        <FormHelperText className="formHelperText error">
                            {t('error')}
                        </FormHelperText>
                    </Box>
                ),
                onClose: clearDialogProps,
            });
        }
    };

    useEffect(() => {
        // clean activeTabIndex, validatedTabIndex and set edit mode to false
        // on unmount
        return () => {
            updateTabsUI(0, 0);
            cleanEditMode();
            cleanErrorState();
        };
    }, []);

    return (
        <div className="addTrainerContainer">
            <Box alignItems="center">
                <Link
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() =>
                        edit &&
                        setConfirmationDialogProps({
                            openDialog: true,
                            dialogText: t('edit-dialog-text'),
                            rightButtonLabel: t('cancel'),
                            leftButtonLabel: t('yes'),
                            onRightClick: onDialogDiscardClick,
                            onLeftClick: () =>
                                onDialogConfirmClick(ROUTES.TRAINERS),
                            onClose: onDialogClose,
                        })
                    }
                    to={(location) => {
                        if (edit) {
                            return {
                                ...location,
                                state: null,
                            };
                        }
                        return {
                            ...location,
                            pathname: ROUTES.TRAINERS,
                            state: null,
                        };
                    }}
                >
                    <KeyboardBackspaceIcon />
                    <span>{t('back-to-all-trainers')}</span>
                </Link>
                {trainer && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <h1>{trainer && fullName(trainer)}</h1>

                        <Box display="flex" justifyContent="flex-end">
                            {hasPermission(
                                PERMISSIONS.UPDATE_TRAINER_STATE
                            ) && (
                                <Button
                                    label={
                                        trainer.active
                                            ? t('deactivate')
                                            : t('activate')
                                    }
                                    color="primary"
                                    onClick={() => {
                                        patchDialogProps({
                                            openDialog: true,
                                            contentText: trainer.active
                                                ? t(
                                                      'deactivate-trainer-confirmation-text'
                                                  )
                                                : t(
                                                      'activate-trainer-confirmation-text'
                                                  ),
                                            actions: [
                                                {
                                                    content: (
                                                        <Button
                                                            label={t('cancel')}
                                                            color="default"
                                                            style={{
                                                                width: '184px',
                                                            }}
                                                            onClick={() =>
                                                                clearDialogProps()
                                                            }
                                                        />
                                                    ),
                                                },
                                                {
                                                    content: (
                                                        <Button
                                                            label={
                                                                trainer.active
                                                                    ? t(
                                                                          'deactivate-trainer'
                                                                      )
                                                                    : t(
                                                                          'activate-trainer'
                                                                      )
                                                            }
                                                            style={{
                                                                width: '184px',
                                                            }}
                                                            color="primary"
                                                            onClick={async () => {
                                                                trainer.active
                                                                    ? onDeactivate()
                                                                    : onActivate();
                                                            }}
                                                        />
                                                    ),
                                                },
                                            ],
                                            onClose: clearDialogProps,
                                        });
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
            <Tabs
                tabs={renderTabsArray()}
                activeTab={activeTab}
                onTabClick={updateActiveTab}
            />
        </div>
    );
};
