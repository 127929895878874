import { Box } from '@material-ui/core';
import { useObservable } from '@mindspace-io/react';
import { ReactComponent as ClockIcon } from 'assets/images/clock_icon.svg';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button as AirexButton } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { LinearProgressBar } from 'core/components/shared/LinearProgressBar/LinearProgressBar';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { IEntity } from 'core/interfaces/entity.interface';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import { ITraining } from 'modules/trainings/models/training.model';
import { trainingsQuery } from 'modules/trainings/state/trainings.query';
import {
    emptyTrainingVideoUploadData,
    ITrainingVideoUploadStateKey,
    trainingsStore,
} from 'modules/trainings/state/trainings.store';
import React, {
    FC,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import './style.scss';

export interface IUploadVideoProps extends IEntity {
    durationLabel?: string | null;
    setDurationLabel?: (duration: string | null) => void;
    onUploadCompleted?: (duration: string | null) => void;
    uploadEntityKey: ITrainingVideoUploadStateKey;
}

export const UploadVideo: FC<IUploadVideoProps & IReadonlyProps> = ({
    readonly,
    id: trainingId,
    durationLabel,
    uploadEntityKey,
    setDurationLabel,
    onUploadCompleted,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const videoInput = useRef<HTMLInputElement>(null);

    const [training] = useEntityById<ITraining>({ id: trainingId });

    const [uploadVideoState] = useObservable(
        trainingsQuery.ui.selectEntity(trainingId),
        trainingsQuery.ui.getEntity(trainingId)
    );
    const [duration, setDuration] = useState(
        durationLabel ? durationLabel : ''
    );
    const { patchDialogProps, clearDialogProps } =
        useContext(AirexDialogContext);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        const videoFile: File | null = files?.length ? files[0] : null;

        if (videoFile) {
            trainingsStore.patchKeyUplaodState(uploadEntityKey, trainingId, {
                videoFile,
            });
        }
    };

    const triggerInputFile = () => {
        videoInput.current?.click();
    };

    const handleDurationInfo = (e: any) => {
        const duration = e.target.duration;
        const ceilDurationSeconds = Math.ceil(duration);
        const floorDurationSeconds = Math.floor(duration);
        const floorDurationMin = Math.floor(duration / 60);

        const durationLabel =
            duration < 60
                ? `00:${ceilDurationSeconds.toString().padStart(2, '0')}`
                : `${floorDurationMin.toString().padStart(2, '0')}:${(
                      floorDurationSeconds -
                      floorDurationMin * 60
                  )
                      .toString()
                      .padStart(2, '0')}`;
        setDuration(durationLabel);
        setDurationLabel && setDurationLabel(durationLabel);
    };

    const videoSrc = useMemo(() => {
        const file = uploadVideoState?.[uploadEntityKey].videoFile;
        return file ? URL.createObjectURL(file) : training?.[uploadEntityKey];
    }, [
        uploadVideoState?.[uploadEntityKey].videoFile,
        training?.[uploadEntityKey],
    ]);

    useEffect(() => {
        if (uploadVideoState?.[uploadEntityKey].completed) {
            /**
             * reset training video upload data
             */
            trainingsStore.patchKeyUplaodState(uploadEntityKey, trainingId, {
                ...emptyTrainingVideoUploadData,
            });

            if (onUploadCompleted) {
                onUploadCompleted(duration);
            } else {
                trainingsStore.fetchById(trainingId);
            }
        }
    }, [uploadVideoState?.[uploadEntityKey].completed]);

    useEffect(() => {
        if (durationLabel && durationLabel !== duration) {
            setDuration(durationLabel);
        }
    }, [durationLabel]);

    return (
        <>
            {uploadVideoState?.[uploadEntityKey].progress && (
                <>
                    <LinearProgressBar
                        value={uploadVideoState[uploadEntityKey].progress ?? 0}
                    />
                    <Box pt="10px" />
                </>
            )}

            <input
                type="file"
                id="video-demo-example"
                ref={videoInput}
                accept=".mp4,.mov"
                value=""
                onChange={onFileChange}
                readOnly={readonly}
                hidden
            />

            {videoSrc ? (
                <video
                    key={videoSrc}
                    controls={true}
                    onLoadedMetadata={handleDurationInfo}
                >
                    <source src={videoSrc} />
                </video>
            ) : (
                <Box className="uploadContainer videoFile">
                    {!readonly && (
                        <p
                            className="chooseFileLink"
                            onClick={triggerInputFile}
                        >
                            {t('choose-video-file')}
                        </p>
                    )}

                    <Box pt="10px" />
                    <p>
                        {t('supported-file-formats', {
                            fileFormats: 'MP4',
                        })}
                    </p>
                    <p className="error">{t('required')}</p>
                </Box>
            )}

            <Box pt="10px" />

            {!readonly && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                >
                    {(!uploadVideoState?.[uploadEntityKey].videoFile &&
                        training?.[uploadEntityKey]) ||
                    uploadVideoState?.[uploadEntityKey].completed ? (
                        <button
                            className="actionButton"
                            onClick={triggerInputFile}
                        >
                            {t('choose-new-video')}
                        </button>
                    ) : (
                        !uploadVideoState?.[uploadEntityKey].progress && (
                            <>
                                <button
                                    disabled={
                                        !uploadVideoState?.[uploadEntityKey]
                                            .videoFile
                                    }
                                    className={
                                        uploadVideoState?.[uploadEntityKey]
                                            .videoFile
                                            ? 'actionButton'
                                            : 'actionButton disabled'
                                    }
                                    onClick={async () => {
                                        if (
                                            !uploadVideoState?.[uploadEntityKey]
                                                .videoFile
                                        ) {
                                            return;
                                        }

                                        try {
                                            const file =
                                                uploadVideoState[
                                                    uploadEntityKey
                                                ].videoFile;
                                            if (file) {
                                                await trainingsStore.uploadVideo(
                                                    trainingId,
                                                    file,
                                                    uploadEntityKey
                                                );
                                            }
                                        } catch (e: any) {
                                            patchDialogProps({
                                                openDialog: true,
                                                contentText: t(e.message),
                                                actions: [
                                                    {
                                                        content: (
                                                            <AirexButton
                                                                label={t(
                                                                    'close'
                                                                )}
                                                                color="primary"
                                                                onClick={() =>
                                                                    clearDialogProps()
                                                                }
                                                            />
                                                        ),
                                                    },
                                                ],
                                                onClose: clearDialogProps,
                                            });
                                        }
                                    }}
                                >
                                    {t('upload-video')}
                                </button>
                                <button
                                    disabled={
                                        !uploadVideoState?.[uploadEntityKey]
                                            .videoFile
                                    }
                                    className={
                                        uploadVideoState?.[uploadEntityKey]
                                            .videoFile
                                            ? 'actionButton'
                                            : 'actionButton disabled'
                                    }
                                    onClick={() =>
                                        trainingsStore.patchKeyUplaodState(
                                            uploadEntityKey,
                                            trainingId,
                                            { videoFile: null }
                                        )
                                    }
                                >
                                    {t('remove-video')}
                                </button>
                            </>
                        )
                    )}
                    {uploadVideoState?.[uploadEntityKey].progress &&
                        !uploadVideoState?.[uploadEntityKey].completed &&
                        !readonly && (
                            <button
                                disabled={
                                    !uploadVideoState?.[uploadEntityKey]
                                        .videoFile
                                }
                                className="actionButton"
                                onClick={() =>
                                    trainingsStore.cancelUpload(
                                        trainingId,
                                        uploadEntityKey
                                    )
                                }
                            >
                                {t('cancel-upload')}
                            </button>
                        )}
                </Box>
            )}
            {uploadVideoState?.[uploadEntityKey].videoFile && (
                <p className="error">{t('you-need-to-upload-video')}</p>
            )}
            {duration && (
                <Box display="flex" alignItems="center">
                    <ClockIcon />
                    <Box pr="10px" />
                    <p>
                        {`${t('total-duration')}: `}
                        {duration ? `${duration} min` : '-'}
                    </p>
                </Box>
            )}
        </>
    );
};
