import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createTrainerFromResponse,
    ITrainer,
    ITrainerResponse,
} from '../models/trainer.model';

export type TrainersState = EntitiesState<ITrainer>;

@StoreConfig({
    name: 'trainers',
    resettable: true,
})
class TrainersStore extends EntitiesStore<ITrainer, ITrainerResponse> {}

export const trainersStore = new TrainersStore({
    responseMapper: createTrainerFromResponse,
});
