import { ConfirmationDialogProps } from 'core/components/ConfirmationDialog';
import React, { createContext, FC, useReducer } from 'react';

interface IUseConfirmationDialog {
    setConfirmationDialogProps: (
        openDialog: Partial<ConfirmationDialogProps>
    ) => void;
    confirmationDialogProps: ConfirmationDialogProps;
    clearConfirmationDialogProps: () => void;
}

const initialConfirmationDialogProps: ConfirmationDialogProps = {
    openDialog: false,
    dialogContent: null,
    rightButtonLabel: '',
    leftButtonLabel: '',
    onLeftClick: () => null,
    onRightClick: () => null,
};

function reducer(
    state: ConfirmationDialogProps,
    action: {
        type: 'patch' | 'set' | 'clear';
        partialState?: Partial<ConfirmationDialogProps>;
    }
) {
    switch (action.type) {
        case 'patch':
            return { ...state, ...action.partialState };
        case 'set':
            return {
                ...initialConfirmationDialogProps,
                ...action.partialState,
            };
        case 'clear':
            return initialConfirmationDialogProps;
        default:
            throw new Error();
    }
}

export const ConfirmationDialogContext = createContext<IUseConfirmationDialog>(
    {} as IUseConfirmationDialog
);

export const ConfirmationDialogProvider: FC = ({ children }) => {
    const [props, dispatch] = useReducer(
        reducer,
        initialConfirmationDialogProps
    );

    const setConfirmationDialogProps = (
        partialState: Partial<ConfirmationDialogProps>
    ) => {
        dispatch({ type: 'set', partialState });
    };

    const patchConfirmationDialogProps = (
        partialState: Partial<ConfirmationDialogProps>
    ) => {
        dispatch({ type: 'patch', partialState });
    };

    const clearConfirmationDialogProps = () => {
        dispatch({ type: 'clear' });
    };

    const value = {
        confirmationDialogProps: props,
        setConfirmationDialogProps: patchConfirmationDialogProps,
        clearConfirmationDialogProps,
    };

    return (
        <ConfirmationDialogContext.Provider value={value}>
            {children}
        </ConfirmationDialogContext.Provider>
    );
};
