import { Box } from '@material-ui/core';
import { Paginator } from '@qagency/react-shared-library';
import { PaginatorButtonProps } from '@qagency/react-shared-library/build/components/Paginator/helpers';
import React from 'react';
import { pageSizeOptions } from '../constants/page-size-options';
import { useCrud } from '../hooks/crud.hook';
import { usePagination } from '../hooks/pagination.hook';
import { ErrorBoundary } from './ErrorBoundary';

const PaginatorButton = ({
    active,
    label,
    disabled,
    onClick,
}: PaginatorButtonProps) => {
    switch (label) {
        case 'first':
            label = '<<';
            break;
        case 'prev':
            label = '<';
            break;
        case 'next':
            label = '>';
            break;
        case 'last':
            label = '>>';
            break;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            boxSizing="border-box"
            height="33px"
            width="33px"
            border="1px solid #979797"
            borderRadius="50%"
            className={!disabled ? 'with-pointer' : 'no-pointer'}
            style={{
                backgroundColor: active ? '#000000' : 'transparent',
                color: active ? 'white' : 'black',
            }}
            onClick={() => {
                if (!disabled && onClick) {
                    onClick();
                }
            }}
        >
            <span>{label}</span>
        </Box>
    );
};

export const EntityListPagination = () => {
    const { setPageNumber, fetch, setPageSize } = useCrud();
    const {
        currentPageSize,
        currentPageNumber,
        totalNumberOfEntities,
    } = usePagination();

    return (
        <ErrorBoundary>
            {!totalNumberOfEntities ? null : (
                <Paginator
                    pageSize={currentPageSize}
                    pageNumber={currentPageNumber}
                    pageSizeOptions={pageSizeOptions}
                    totalNumberOfItems={totalNumberOfEntities}
                    numberOfAdjacentPageNumbers={2}
                    buttonIdentifiers={[
                        'first',
                        'prev',
                        'current',
                        'next',
                        'last',
                    ]}
                    selectPageNumber={(pageNumber) => {
                        switch (pageNumber) {
                            case currentPageNumber:
                                fetch();
                                break;
                            default: {
                                setPageNumber(pageNumber);
                            }
                        }
                    }}
                    selectPageSize={(pageSize) => setPageSize(pageSize)}
                    renderSelectPageNumberButton={(props) => (
                        <PaginatorButton {...props} />
                    )}
                    renderSelectPageSizeDropdown={({ ...props }) => (
                        // <Dropdown
                        //     availableOptions={pageSizeOptions.map((value) => ({
                        //         displayValue: value.toString(),
                        //         value,
                        //     }))}
                        //     {...props}
                        // />
                        <></>
                    )}
                />
            )}
        </ErrorBoundary>
    );
};
