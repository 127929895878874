import { ITokenResponse } from 'core/interfaces/login-response-model.interface';
import { IGeneratePasswordHashCredentials } from 'modules/login/models/generate-password-hash.model';
import { ILoginCredentials } from 'modules/login/models/login.model';
import { IResetHashCredentials } from 'modules/login/models/reset-password-hash.model';
import { IResetCredentials } from 'modules/login/models/reset-password.model';
import { ISession } from 'modules/login/models/session.model';
import {
    createUserFromResponse,
    IUser,
    IUserResponse,
} from 'modules/users/models/user.model';
import { apiService } from './apiService';

class SessionAPIService {
    async current(): Promise<IUser> {
        const data = apiService.responseHandler(
            await apiService.get<IUserResponse>('me')
        );
        return createUserFromResponse(data);
    }

    async login(
        credentials: ILoginCredentials
    ): Promise<Omit<ISession, 'me' | 'error'> & { user: IUser }> {
        const {
            user,
            token_key,
            refresh_token_key,
        } = apiService.responseHandler(
            await apiService.post<ITokenResponse, ILoginCredentials>('token', {
                ...credentials,
                user_type: 'trainer',
            })
        );

        return {
            token: token_key,
            refreshToken: refresh_token_key,
            user: createUserFromResponse(user),
        };
    }

    async refreshToken(
        refreshToken: string
    ): Promise<Omit<ISession, 'me' | 'error'> & { user: IUser }> {
        const {
            user,
            token_key,
            refresh_token_key,
        } = apiService.responseHandler(
            await apiService.post<ITokenResponse, any>(
                `/token/refresh/${refreshToken}`,
                {}
            )
        );

        return {
            token: token_key,
            refreshToken: refresh_token_key,
            user: createUserFromResponse(user),
        };
    }

    async requestPasswordReset(credentials: IResetCredentials) {
        const response = await apiService.post<any, IResetCredentials>(
            '/reset-password',
            { ...credentials, user_type: 'trainer' }
        );

        return response;
    }

    async resetPassword(hash: string, credentials: IResetHashCredentials) {
        const response = await apiService.put<any, IResetHashCredentials>(
            `/reset-password/${hash}`,
            credentials
        );

        return response;
    }

    async generatePassword(
        hash: string,
        credentials: IGeneratePasswordHashCredentials
    ) {
        const response = await apiService.put<
            any,
            IGeneratePasswordHashCredentials
        >(`generate-password/${hash}`, credentials);

        return response;
    }
}

export const sessionAPIService = new SessionAPIService();
