import { expertiseAPIService } from 'core/services/expertiseService';
import {
    createExpertiseFromResponse,
    IExpertise,
} from 'modules/trainers/models/expertise.model';
import { useEffect, useState } from 'react';

export const useAreaOfExpertiseData = (): Array<IExpertise> => {
    const [data, setData] = useState<Array<IExpertise>>([]);

    useEffect(() => {
        expertiseAPIService
            .getExpertise()
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setData(
                        response.data.map((expertise) =>
                            createExpertiseFromResponse(expertise)
                        )
                    );
                }
            })
            .catch((
                e //TODO:Error
            ) => console.log(e));
    }, []);

    return data;
};
