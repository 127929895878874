import { useSession } from 'core/hooks/session.hook';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { getUserPermissions } from 'modules/users/models/user.model';
import { useCallback } from 'react';

export const usePermissions = (): ((permission: PERMISSIONS) => boolean) => {
    const { me } = useSession();

    return useCallback(
        (permission: PERMISSIONS) => {
            if (!me) {
                return false;
            }

            const permissions = getUserPermissions(me);

            return permissions.has(permission);
        },
        [me]
    );
};
