import { resetStores } from '@datorama/akita';
import { useObservable } from '@mindspace-io/react';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { sessionAPIService } from 'core/services/sessionAPIService';
import { checkNetworkError } from 'core/utils/checkNetworkError';
import { SessionContext } from 'modules/login/context/session.context';
import { IGeneratePasswordHashCredentials } from 'modules/login/models/generate-password-hash.model';
import { ILoginCredentials } from 'modules/login/models/login.model';
import { IResetHashCredentials } from 'modules/login/models/reset-password-hash.model';
import { IResetCredentials } from 'modules/login/models/reset-password.model';
import { IUser } from 'modules/users/models/user.model';
import { useContext } from 'react';

interface IUseSession {
    me: IUser | null;
    error: string | null;
    isLoggedIn: boolean;
    login: (credentials: ILoginCredentials) => Promise<boolean>;
    resetRequestPassword: (credentials: IResetCredentials) => Promise<boolean>;
    resetPassword: (
        hash: string,
        credentials: IResetHashCredentials
    ) => Promise<boolean>;
    generatePassword: (
        hash: string,
        credentials: IGeneratePasswordHashCredentials
    ) => Promise<boolean>;
    logout: () => void;
    cleanErrorState: () => void;
}

export const useSession = (): IUseSession => {
    const { query, store } = useContext(SessionContext);
    const [isLoggedIn] = useObservable(query.isLoggedIn$, query.isLoggedIn);
    const [me] = useObservable(query.me$, query.me);
    const [error] = useObservable(query.selectError(), query.error);
    const { t } = useContext(MultiLanguageSupportContext);

    const login = async (credentials: ILoginCredentials) => {
        try {
            store.handleToken(await sessionAPIService.login(credentials));
        } catch (e: any) {
            checkNetworkError(e?.message)
                ? null
                : store.setError(t('login-error'));
            return false;
        }

        return true;
    };

    const resetRequestPassword = async (credentials: IResetCredentials) => {
        try {
            await sessionAPIService.requestPasswordReset(credentials);
        } catch (e: any) {
            // TODO: different type of error responses data
            checkNetworkError(e?.message) ? null : store.setError(t('error'));
            return false;
        }

        return true;
    };

    const resetPassword = async (
        hash: string,
        credentials: IResetHashCredentials
    ) => {
        try {
            await sessionAPIService.resetPassword(hash, credentials);
        } catch (e: any) {
            // TODO: different type of error responses data
            checkNetworkError(e?.message) ? null : store.setError(t('error'));
            return false;
        }

        return true;
    };

    const generatePassword = async (
        hash: string,
        credentials: IGeneratePasswordHashCredentials
    ) => {
        try {
            await sessionAPIService.generatePassword(hash, credentials);
        } catch (e: any) {
            // TODO: different type of error responses data
            checkNetworkError(e?.message) ? null : store.setError(t('error'));
            return false;
        }

        return true;
    };

    const logout = () => {
        resetStores();
    };

    const cleanErrorState = () => {
        store.setError(null);
    };

    return {
        me,
        error,
        isLoggedIn,
        login,
        logout,
        resetRequestPassword,
        resetPassword,
        generatePassword,
        cleanErrorState,
    };
};
