import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
} from '@material-ui/core';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useActiveTab } from 'core/hooks/active-tab.hook';
import { useEditMode } from 'core/hooks/edit-mode.hook';
import { useFormik } from 'formik';
import { IExpertise } from 'modules/trainers/models/expertise.model';
import React, { FC, useContext, useEffect, useState } from 'react';
import './style.scss';

export interface CheckedExpertise {
    checkedExpertises: Array<string>;
}
export interface AreaOfExpertiseData extends CheckedExpertise {
    expertises: Array<IExpertise>;
}
export interface AreaOfExpertiseProps {
    data: AreaOfExpertiseData;
    onNextClick?: (data: any) => void;
    onCancelClick?: (data?: any) => void;
}

export const AreaOfExpertise: FC<AreaOfExpertiseProps> = ({
    data,
    onCancelClick,
    onNextClick,
}): JSX.Element => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { activeTab, updateTabsUI, updateValidatedTabIndex } = useActiveTab();
    const { updateEditMode, edit } = useEditMode();
    const [activeTabEdit, setActiveTabEdit] = useState<boolean>(false);

    useEffect(() => {
        if (activeTabEdit) {
            // if tab is edited, set validated tab index to current active tab
            // reset steps
            updateValidatedTabIndex(activeTab);
        }
    }, [activeTabEdit]);

    const formik = useFormik<AreaOfExpertiseData>({
        initialValues: {
            ...data,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            if (onNextClick) {
                onNextClick(values.checkedExpertises);
            }
            updateTabsUI(activeTab + 1, activeTab + 1);
        },
        validate: (values) => {
            const errors: Partial<AreaOfExpertiseData> = {};

            if (values.checkedExpertises.length === 0) {
                errors.checkedExpertises = [t('required-expertise')];
            }
            return errors;
        },
    });

    const handleChange = (e: any) => {
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }

        formik.handleChange(e);
    };

    const onTabCancel = () => {
        if (edit) {
            if (onCancelClick) {
                onCancelClick();
            }
        }
    };

    const expertiseCheckboxes = () => {
        const filteredArray = data.expertises.filter(
            (expertise) => !!expertise.parent
        );
        return (
            <div>
                {data.expertises.map((expertise) => {
                    return (
                        !expertise.parent && (
                            <Box
                                key={expertise.id}
                                display="flex"
                                flexDirection="row"
                                marginBottom="20px"
                            >
                                <FormControl key={expertise.id} required>
                                    <FormLabel className="formCheckBoxLabel">
                                        {expertise.name}
                                    </FormLabel>
                                    <FormGroup>
                                        {filteredArray.map(
                                            (filteredExpertise) =>
                                                filteredExpertise.parent ===
                                                    expertise.id && (
                                                    <FormControlLabel
                                                        key={
                                                            filteredExpertise.id
                                                        }
                                                        className="formControlCheckBoxLabel"
                                                        label={
                                                            filteredExpertise.name
                                                        }
                                                        onChange={handleChange}
                                                        control={
                                                            <Checkbox
                                                                name="checkedExpertises"
                                                                checked={
                                                                    formik
                                                                        .values
                                                                        .checkedExpertises[
                                                                        formik.values.checkedExpertises.indexOf(
                                                                            filteredExpertise.id
                                                                        )
                                                                    ]
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .checkedExpertises[
                                                                        formik.values.checkedExpertises.indexOf(
                                                                            filteredExpertise.id
                                                                        )
                                                                    ] ??
                                                                    filteredExpertise.id
                                                                }
                                                            />
                                                        }
                                                    />
                                                )
                                        )}
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        )
                    );
                })}
                {formik.errors.checkedExpertises &&
                    formik.errors.checkedExpertises.length > 0 && (
                        <FormHelperText className="formHelperText error">
                            {formik.errors.checkedExpertises[0]}
                        </FormHelperText>
                    )}
            </div>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="areaOfExpertiseContainer">
                <div className="rowContainer">{expertiseCheckboxes()}</div>

                <div className="buttons">
                    <Button
                        type="button"
                        onClick={onTabCancel}
                        label={t('cancel')}
                        color="default"
                        style={{ width: '184px', height: '40px' }}
                    />
                    <Button
                        type="submit"
                        label={t('next')}
                        color="primary"
                        style={{ width: '184px', height: '40px' }}
                    />
                </div>
            </div>
        </form>
    );
};
