import { ICertificateResponse } from 'modules/trainers/models/certificate.model';
import { apiService } from './apiService';

class CertificateAPIService {
    async getCertificates() {
        const response = await apiService.get<Array<ICertificateResponse>>(
            `/certificates`
        );
        return response;
    }
}

export const certificateAPIService = new CertificateAPIService();
