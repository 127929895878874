import { Box } from '@material-ui/core';
import React, { FC } from 'react';

interface IListHeader {
    renderFirstColumn?: () => JSX.Element;
    renderLastColumn?: () => JSX.Element;
}

export const ListHeader: FC<IListHeader> = ({
    renderFirstColumn,
    renderLastColumn,
    children,
}) => (
    <Box display="flex" justifyContent="space-between">
        {renderFirstColumn && renderFirstColumn()}
        {children}
        {renderLastColumn && renderLastColumn()}
    </Box>
);
