import { Box, FormHelperText } from '@material-ui/core';
import { FormInput } from 'core/components/FormInput';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ThumbnailUpload } from 'core/components/ThumbnailUpload';
import { IEntity } from 'core/interfaces/entity.interface';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import { encodeImageFileAsURL } from 'core/utils/encodeImageFileAsURL';
import { useFormikContext } from 'formik';
import { ILivestreamDetails } from 'modules/livestream/models/livestream.model';
import React, { FC, useContext } from 'react';
import './style.scss';
import { UploadVideo } from './UploadLivestreamVideoInput';

export const UploadLivestreamVideoTab: FC<IReadonlyProps & IEntity> = ({
    readonly,
    id,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const formik = useFormikContext<ILivestreamDetails>();

    const handleImageChange = (file: File | null) => {
        !file
            ? formik.setFieldValue('screen', formik.initialValues.screen)
            : encodeImageFileAsURL(file, (result) =>
                  formik.setFieldValue('screen', result as string)
              );
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <FormInput<ILivestreamDetails>
                    label={t('video-title')}
                    name="title"
                    type="text"
                    value={formik.values.title}
                    readOnly={true}
                />

                <Box px="20px" />

                <FormInput<ILivestreamDetails>
                    label={t('trainer-name')}
                    name="trainerName"
                    type="text"
                    value={formik.values.trainerName}
                    readOnly={true}
                />
            </Box>

            <Box pt="20px" />

            <Box className="uploadVideoTabContainer">
                <Box className="column">
                    <Box display="flex" justifyContent="space-between">
                        <p className="bold">{t('upload-video-preview')}</p>
                        <p className="light">{t('short-teaser')}</p>
                    </Box>

                    <UploadVideo
                        id={id}
                        uploadEntityKey="videoClip"
                        readonly={readonly}
                    />
                </Box>

                <Box className="column">
                    <p className="bold">{t('upload-thumbnail')}</p>

                    <ThumbnailUpload
                        url={formik.values.screen}
                        reset={
                            formik.values.screen === formik.initialValues.screen
                        }
                        onChange={handleImageChange}
                        readonly={readonly}
                        renderSupportedFile={() => (
                            <>
                                <p>{t('supported-image-types')}</p>
                                <p> {t('maximum-image-size')}</p>
                                <p>
                                    {t('format', {
                                        format: `16:9`,
                                    })}
                                </p>
                            </>
                        )}
                    />

                    {formik.errors.screen !== '' && (
                        <FormHelperText className="formHelperText error">
                            {formik.errors.screen}
                        </FormHelperText>
                    )}
                </Box>
            </Box>
        </>
    );
};
