import { PERMISSIONS, RolePermissions } from '../models/permissions.model';

export enum UserRole {
    ROLE_AIREX_ADMIN = 'ROLE_AIREX_ADMIN',
    ROLE_MASTER_TRAINER = 'ROLE_MASTER_TRAINER',
    ROLE_TRAINER = 'ROLE_TRAINER',
}

export const ROLE_PERMISSIONS: RolePermissions = {
    [UserRole.ROLE_AIREX_ADMIN]: [
        PERMISSIONS.HOME,
        PERMISSIONS.ADD_TRAINER,
        PERMISSIONS.VIEW_TRAINER,
        PERMISSIONS.EDIT_TRAINER,
        PERMISSIONS.UPDATE_TRAINER_STATE,
        PERMISSIONS.ALL_TRAINERS_LIST,
        PERMISSIONS.VIEW_TRAINER_ADDED_BY,
        PERMISSIONS.MY_VIDEOS,
        PERMISSIONS.ALL_TRAINING_VIDEOS,
        PERMISSIONS.UPLOAD_NEW_TRAINING,
        PERMISSIONS.VIEW_TRAINING,
        PERMISSIONS.EDIT_OWN_TRAINING,
        PERMISSIONS.DELETE_TRAINING,
        PERMISSIONS.VIDEOS_PENING_APPROVAL,
        PERMISSIONS.SELECT_TRAINER,
        PERMISSIONS.ALL_LIVESTREAM_VIDEOS,
        PERMISSIONS.VIEW_LIVESTREAM,
        PERMISSIONS.DELETE_LIVESTREAM,
        PERMISSIONS.LIVESTREAM_PENDING_APPROVAL,
        PERMISSIONS.CANCEL_LIVESTREAM,
        PERMISSIONS.LIVESTREAM_SESSION_ADMIN_ACCESS,
        PERMISSIONS.EDIT_APPROVED_TRAINING,
    ],
    [UserRole.ROLE_MASTER_TRAINER]: [
        PERMISSIONS.HOME,
        PERMISSIONS.MY_PROFILE,
        PERMISSIONS.ADD_TRAINER,
        PERMISSIONS.VIEW_TRAINER,
        PERMISSIONS.EDIT_OWN_TRAINER,
        PERMISSIONS.ALL_TRAINERS_LIST,
        PERMISSIONS.MY_VIDEOS,
        PERMISSIONS.ALL_TRAINING_VIDEOS,
        PERMISSIONS.UPLOAD_NEW_TRAINING,
        PERMISSIONS.VIEW_TRAINING,
        PERMISSIONS.EDIT_OWN_TRAINING,
        PERMISSIONS.DELETE_OWN_TRAINING,
        PERMISSIONS.MY_VIDEOS_PENDING,
        PERMISSIONS.MY_VIDEOS_DECLINED,
        PERMISSIONS.MOVE_DECLINED_VIDEO_TO_DRAFT,
        PERMISSIONS.MY_LIVESTREAM,
        PERMISSIONS.ALL_LIVESTREAM_VIDEOS,
        PERMISSIONS.UPLOAD_NEW_LIVESTREAM,
        PERMISSIONS.VIEW_LIVESTREAM,
        PERMISSIONS.EDIT_OWN_LIVESTREAM,
        PERMISSIONS.DELETE_OWN_LIVESTREAM,
        PERMISSIONS.CANCEL_OWN_LIVESTREAM,
        PERMISSIONS.RESCHEDULE_OWN_LIVESTREAM,
        PERMISSIONS.MY_LIVESTREAM_DECLINED,
        PERMISSIONS.MY_LIVESTREAM_PENDING,
    ],
    [UserRole.ROLE_TRAINER]: [
        PERMISSIONS.HOME,
        PERMISSIONS.MY_PROFILE,
        PERMISSIONS.VIEW_TRAINER,
        PERMISSIONS.ALL_TRAINERS_LIST,
        PERMISSIONS.MY_VIDEOS,
        PERMISSIONS.ALL_TRAINING_VIDEOS,
        PERMISSIONS.UPLOAD_NEW_TRAINING,
        PERMISSIONS.VIEW_TRAINING,
        PERMISSIONS.EDIT_OWN_TRAINING,
        PERMISSIONS.DELETE_OWN_TRAINING,
        PERMISSIONS.MY_VIDEOS_PENDING,
        PERMISSIONS.MY_VIDEOS_DECLINED,
        PERMISSIONS.MOVE_DECLINED_VIDEO_TO_DRAFT,
    ],
};
