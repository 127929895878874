import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { EntitiesContext } from 'core/constants/entities.context';
import { useCrud } from 'core/hooks/crud.hook';
import { CrudApiService } from 'core/services/crudApiService';
import { checkNetworkError } from 'core/utils/checkNetworkError';
import { useContext } from 'react';
import { AddTrainerCredentials } from '../models/add-trainer.model';

interface IUseTrainer {
    createTrainer: (trainer: AddTrainerCredentials) => Promise<boolean>;
    updateTrainer: (
        id: string,
        trainerData: AddTrainerCredentials
    ) => Promise<boolean>;
    activateTrainer: (
        id: string,
        shouldFetchAfterSuccess?: boolean
    ) => Promise<boolean>;
    deactivateTrainer: (
        id: string,
        shouldFetchAfterSuccess?: boolean
    ) => Promise<boolean>;
}

export const useTrainer = (): IUseTrainer => {
    const { store } = useContext(EntitiesContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const crudApiService = new CrudApiService('/trainers', {
        responseMapper: (trainer: any) => trainer,
    });
    const { activate, deactivate } = useCrud();

    const createTrainer = async (trainer: AddTrainerCredentials) => {
        try {
            await crudApiService.create(trainer);
        } catch (e: any) {
            checkNetworkError(e?.message) ? null : store.setError(t('error'));
            return false;
        }

        return true;
    };

    const updateTrainer = async (id: string, data: AddTrainerCredentials) => {
        try {
            await crudApiService.update({ id, ...data });
        } catch (e: any) {
            checkNetworkError(e?.message) ? null : store.setError(t('error'));
            return false;
        }

        return true;
    };

    const activateTrainer = async (
        id: string,
        shouldFetchAfterSuccess?: boolean
    ) => {
        try {
            await activate(id, {
                shouldFetchAfterSuccess: shouldFetchAfterSuccess,
            });
        } catch (e: any) {
            checkNetworkError(e?.message) ? null : store.setError(t('error'));
            return false;
        }
        return true;
    };

    const deactivateTrainer = async (
        id: string,
        shouldFetchAfterSuccess?: boolean
    ) => {
        try {
            await deactivate(id, {
                shouldFetchAfterSuccess: shouldFetchAfterSuccess,
            });
        } catch (e: any) {
            checkNetworkError(e?.message) ? null : store.setError(t('error'));
            return false;
        }
        return true;
    };

    return {
        createTrainer,
        updateTrainer,
        activateTrainer,
        deactivateTrainer,
    };
};
