import { IEntity } from 'core/interfaces/entity.interface';
import { createTrainingCategoryFromResponse } from 'modules/training-categories/models/training-category.model';
import {
    emptyTraining,
    ITrainingCommand,
    ITrainingDetails,
    ITrainingResponse,
} from 'modules/trainings/models/training.model';
import { IStreamDetails } from '../interfaces';

export enum LiveStreamState {
    APPROVED = 'approved',
    PENDING = 'pending_approval',
    DRAFT = 'draft',
    DECLINED = 'declined',
}

export enum LiveStreamLiveStatusEnum {
    PASSED = 'passed',
    LIVE = 'live',
    UPCOMING = 'upcoming',
}

export interface ILivestreamDetails extends Omit<ITrainingDetails, 'state'> {
    startsAt: Date | null;
    endsAt: Date | null;
    maxNumberOfParticipants: number | null;
    numberOfParticipiants: number;
    agenda: string | null;
    minRequirements: string[];
    liveStatus: string;
    state: LiveStreamState;
    canceled: boolean;
    rescheduled: boolean;
    watchedTimes: number | null;
    createdFromLivestream: boolean;
    stream: IStreamDetails | null;
}

export interface ILivestream extends IEntity, ILivestreamDetails {}
export interface ILivestreamCommand extends ITrainingCommand {
    starts_at: string | null;
    ends_at: string | null;
    max_number_of_participants: number | null;
    agenda: string | null;
    min_requirements: string[] | null;
    created_from_livestream: boolean;
}

export interface ILivestreamResponse
    extends IEntity<number>,
        Omit<ILivestreamCommand, 'products' | 'categories'>,
        Omit<ITrainingResponse, 'state'> {
    rescheduled: boolean;
    number_of_participant: number;
    live_status: string;
    state: LiveStreamState;
    canceled: boolean;
    starts_at: string;
    ends_at: string;
    max_number_of_participants: number;
    agenda: string;
    min_requirements: string[];
}

export const emptyLivestream: ILivestreamDetails = {
    ...emptyTraining,
    startsAt: null,
    endsAt: null,
    maxNumberOfParticipants: 0,
    numberOfParticipiants: 0,
    agenda: '',
    minRequirements: [],
    state: LiveStreamState.DRAFT,
    stream: null,
    liveStatus: '',
    canceled: false,
    rescheduled: false,
};

export const createLivestream = ({
    id = '0',
    ...props
}: Partial<ILivestream>): ILivestream => {
    return {
        id,
        ...emptyLivestream,
        ...props,
    } as ILivestream;
};

export const convertUTCStringTimetoLocal = (date: string): Date =>
    new Date(date);

export const convertLocalTimetoUTCString = (date: Date): string =>
    date.toISOString();

export const createLivestreamFromResponse = ({
    id,
    categories,
    trainer_name,
    starts_at,
    ends_at,
    max_number_of_participants,
    number_of_participant,
    created_from_livestream,
    min_requirements,
    products,
    subscription,
    screen,
    trainer,
    video_clip,
    watched_times,
    live_status,
    ...props
}: ILivestreamResponse): ILivestream => {
    return createLivestream({
        id: id.toString(),
        screen: screen || null,
        categories: categories?.map(createTrainingCategoryFromResponse) ?? [],
        trainerName: trainer_name,
        startsAt: starts_at ? convertUTCStringTimetoLocal(starts_at) : null,
        liveStatus: live_status,
        endsAt: ends_at ? convertUTCStringTimetoLocal(ends_at) : null,
        maxNumberOfParticipants: max_number_of_participants ?? null,
        minRequirements: min_requirements ?? [],
        productIds: products?.map(({ id }) => id.toString()) ?? [],
        subscription: subscription,
        createdBy: trainer?.id.toString() ?? null,
        videoClip: video_clip ?? null,
        watchedTimes: watched_times ?? null,
        numberOfParticipiants: number_of_participant ?? null,
        createdFromLivestream: created_from_livestream ?? null,
        ...props,
    });
};

export const createLivestreamCommand = ({
    categories,
    trainerName,
    productIds,
    subscription,
    ...props
}: ILivestreamDetails): Partial<ILivestreamCommand> => {
    return {
        categories: categories?.map(({ id }) => ({ id: +id })) ?? [],
        trainer_name: trainerName,
        products:
            productIds?.map((id) => ({
                id: +id,
            })) ?? [],
        subscription: !!subscription,
        ...Object.keys(props).reduce((obj, key) => {
            let value = (props as any)[key];

            if (key === 'startsAt' && value) {
                value = convertLocalTimetoUTCString(value);
            }

            if (key === 'endsAt' && value) {
                value = convertLocalTimetoUTCString(value);
            }

            return value ? { ...obj, [key]: value } : obj;
        }, {}),
    };
};
