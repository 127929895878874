import { Box, BoxProps } from '@material-ui/core';
import { IEntity } from 'core/interfaces/entity.interface';
import React, { PropsWithChildren } from 'react';
import style from './index.module.scss';

export interface IListItemContainerProps<T> {
    entity: T;
    boxProps?: BoxProps;
    renderFirstColumn?: (entity: T) => JSX.Element;
    renderLastColumn?: (entity: T) => JSX.Element;
}

export const ListItemContainer = <T extends IEntity>({
    entity,
    boxProps,
    renderFirstColumn,
    renderLastColumn,
    children,
}: PropsWithChildren<IListItemContainerProps<T>>): JSX.Element => {
    const { className, ...props } = boxProps ?? {};

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            className={`${style.listItemContainer} ${className}`}
            {...props}
        >
            {renderFirstColumn && renderFirstColumn(entity)}
            {children}
            {renderLastColumn && renderLastColumn(entity)}
        </Box>
    );
};
