import { ReactComponent as AirexLogo } from 'assets/images/airex_logo.svg';
import { AuthForm, FormConfig } from 'core/components/AuthForm';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { validateEmailhelper } from 'core/utils/validateEmail';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IResetCredentials } from '../models/reset-password.model';

export const ResetPasswordPage: FC = () => {
    const {
        isLoggedIn,
        logout,
        resetRequestPassword,
        error,
        cleanErrorState,
    } = useSession();
    const { push } = useHistory();
    const { t } = useContext(MultiLanguageSupportContext);
    const [resetConfig, setResetConfig] = useState<
        FormConfig<IResetCredentials>
    >({
        logo: <AirexLogo />,
        title: t('forgot-password-title'),
        text: t('forgot-password-text'),
        buttonText: t('send-recovery'),
        linkText: t('return-to-login'),
        linkRoute: ROUTES.LOGIN,
        inputs: [
            {
                name: 'email',
                label: t('email'),
                placeholder: t('input-email-placeholder'),
                textHelper: t('invalid-email-error'),
                validate: (data: string) => validateEmail(data),
            },
        ],
        onSubmitClick: (credentials: IResetCredentials) =>
            onSubmitClick(credentials),
    });

    useEffect(() => {
        if (isLoggedIn) {
            logout();
        }

        return () => cleanErrorState();
    }, []);

    const onResendRecovery = async (credentials: IResetCredentials) => {
        if (await resetRequestPassword(credentials)) {
            cleanErrorState();
        }
    };

    const validateEmail = (data: string): boolean => {
        return validateEmailhelper(data);
    };

    const onSubmitClick = async (credentials: IResetCredentials) => {
        if (await resetRequestPassword(credentials)) {
            cleanErrorState();
            setResetConfig({
                logo: <AirexLogo />,
                title: t('forgot-password-title'),
                text: `${t('request-reset-password-text')} ${
                    credentials.email
                }`,
                buttonText: t('return-to-login'),
                linkText: t('resend-recovery'),
                onSubmitClick: () => push({ pathname: ROUTES.LOGIN }),
                onLinkClick: onResendRecovery,
            });
        }
    };

    return <AuthForm {...resetConfig} errorText={error} />;
};
