import { combineQueries, EntityUIQuery } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntitiesQuery } from '../../../core/state/entities.query';
import { ITraining } from '../models/training.model';
import {
    ITrainingVideoUploadState,
    TrainingsStore,
    trainingsStore,
} from './trainings.store';

export class TrainingsQuery extends EntitiesQuery<ITraining> {
    ui!: EntityUIQuery<ITrainingVideoUploadState>;

    readonly role$ = this.select(({ ui }) => (ui as any).role);
    readonly state$ = this.select(({ ui }) => (ui as any).state);
    readonly shuoldFetchEntities$ = combineQueries([
        this.searchTerm$,
        this.pageSize$,
        this.pageNumber$,
        this.role$,
        this.state$,
    ]);
    readonly areAnyVideoInProgress$: Observable<boolean>;

    constructor(store: TrainingsStore) {
        super(store);

        this.createUIQuery();

        this.areAnyVideoInProgress$ = this.ui.selectAll().pipe(
            // map((entities) => entities.some((entity) => Object.keys(entity).filter((key) => entity[key as ITrainingVideoUploadStateKey].progress !== null).length ))
            map((entities) =>
                entities.some(
                    (entity) =>
                        entity.video.progress !== null ||
                        entity.videoClip.progress !== null
                )
            )
        );
    }
}

export const trainingsQuery = new TrainingsQuery(trainingsStore);
