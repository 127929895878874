import { ReactComponent as AirexLogo } from 'assets/images/airex_logo.svg';
import { AuthForm, FormConfig } from 'core/components/AuthForm';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { validatePasswordHelper } from 'core/utils/validatePassword';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ILocationState } from '../components/PrivateRoute';
import { IGeneratePasswordHashCredentials } from '../models/generate-password-hash.model';

export const GeneratePasswordPage: FC = () => {
    const {
        isLoggedIn,
        generatePassword,
        logout,
        error,
        cleanErrorState,
    } = useSession();
    const { push } = useHistory();
    const { state } = useLocation<ILocationState>();
    const { hash } = useParams<{ hash: string }>();
    const { t } = useContext(MultiLanguageSupportContext);

    useEffect(() => {
        if (isLoggedIn) {
            logout();
        }

        return () => cleanErrorState();
    }, []);

    const onSubmitClick = async (
        credentials: IGeneratePasswordHashCredentials
    ) => {
        if (await generatePassword(hash, credentials)) {
            push({
                pathname: state?.from?.pathname ?? ROUTES.LOGIN,
                state: { info: t('sucessfully-updated-password') },
            });
        }
    };

    const validatePassword = (data: string): boolean => {
        return validatePasswordHelper(data);
    };

    const generatePasswordFormConfig: FormConfig<IGeneratePasswordHashCredentials> = {
        logo: <AirexLogo />,
        title: t('generate-password-title'),
        text: t('confirm-password-text'),
        buttonText: t('save-password'),
        linkText: t('login-instead'),
        linkRoute: ROUTES.LOGIN,
        inputs: [
            {
                name: 'password',
                label: t('new-password'),
                placeholder: t('input-password-placeholder'),
                type: 'password',
                textHelper: t('invalid-password-error'),
                validate: validatePassword,
            },
            {
                name: 'confirm_password',
                label: t('confirm-password'),
                placeholder: t('input-password-placeholder'),
                type: 'password',
                textHelper: t('invalid-password-error'),
                validate: validatePassword,
            },
        ],
        errorText: error,
        onSubmitClick: onSubmitClick,
    };

    return <AuthForm {...generatePasswordFormConfig} />;
};
