import { IEntity } from 'core/interfaces/entity.interface';
import { IUpdateEntityStateOptions } from 'core/interfaces/update-entity-state-options.interface copy';
import { EntitiesStore } from 'core/state/entities.store';
import { ICrudApiServiceMethodWithBodyOptions } from './crudApiService';

interface IUpdateStateProps<T extends IEntity, R> {
    store: EntitiesStore<T, R>;
    state: string;
}

type IUpdateStateOptions = IUpdateEntityStateOptions &
    ICrudApiServiceMethodWithBodyOptions;

export const updateState = <T extends IEntity, R>({
    store,
    state,
}: IUpdateStateProps<T, R>) => async (
    id: string,
    options?: Partial<IUpdateStateOptions>
): Promise<void> => {
    const {
        shouldFetchAfterSuccess,
        ...updateStateOptions
    }: IUpdateEntityStateOptions &
        Partial<ICrudApiServiceMethodWithBodyOptions> = {
        shouldFetchAfterSuccess: false,
        ...options,
    };

    await store.crudApiService.updateState(
        id,
        `state/${state}`,
        updateStateOptions
    );

    /**
     * id as any is used as typescript dosen't pick up the correct override to use
     * { active } as any is used because the generic store entity type doesn't know about the active property
     */
    store.update(id as any, { state } as any);

    if (shouldFetchAfterSuccess) {
        store.fetchEntities();
    }
};
