import { IEntity } from '../../../core/interfaces/entity.interface';
import { ROLE_PERMISSIONS } from '../constants/role-permissions';
import { PERMISSIONS } from './permissions.model';

export type UserRoles =
    | 'ROLE_AIREX_ADMIN'
    | 'ROLE_MASTER_TRAINER'
    | 'ROLE_TRAINER';

export interface IUser extends IEntity {
    email: string;
    firstName: string;
    lastName: string;
    avatar: string;
    roles: Array<UserRoles>;
    active: boolean;
}

export interface IUserResponse extends IEntity<number> {
    email: string;
    first_name: string;
    last_name: string;
    active: boolean;
    email_confirmed: boolean;
    avatar: string;
    roles: Array<UserRoles>;
}

export const getUserRole = (user: IUser): UserRoles => user.roles[0];

export const getUserPermissions = (user: IUser): Set<PERMISSIONS> => {
    let concatedPermissions: Array<PERMISSIONS> = [];
    user.roles.forEach((role) => {
        concatedPermissions = [
            ...concatedPermissions,
            ...ROLE_PERMISSIONS[role],
        ];
    });
    const permissions = new Set([...concatedPermissions]);
    return permissions;
};

export const fullName = (user: IUser) =>
    `${user.firstName} ${user.lastName}`.trim();

export const createUser = ({ id, ...props }: Partial<IUser>) => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        email: '',
        firstName: '',
        lastName: '',
        avatar: '',
        roles: [],
        ...props,
    } as IUser;
};

export const createUserFromResponse = ({
    id,
    first_name,
    last_name,
    ...props
}: IUserResponse): IUser => {
    return createUser({
        id: id.toString(),
        firstName: first_name ?? '',
        lastName: last_name ?? '',
        ...props,
    });
};
