import { useSession } from 'core/hooks/session.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface ILocationState {
    info: string | null;
    from: {
        pathname: string;
    };
}

interface PrivateRouteProps {
    path: RouteProps['path'];
    component: React.ElementType;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
    component: Component,
    children,
    ...rest
}) => {
    const { isLoggedIn } = useSession();

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                const locationState: ILocationState = {
                    from: {
                        pathname: routeProps.location.pathname,
                    },
                    info: null,
                };

                return isLoggedIn ? (
                    children ?? <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: ROUTES.LOGIN,
                            state: locationState,
                        }}
                    />
                );
            }}
        />
    );
};
