import { useEffect, useState, useContext, FC } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { MultiLanguageSupportContext } from "core/components/MultiLanguageSupportProvider";
import { ConfirmationDialogContext } from "../ConfirmationDialog/ConfirmationDialogProvider";

export interface IRoutePromptProps {
    blockWhen: boolean;
    dialogText: string;
}

export const RoutePrompt: FC<IRoutePromptProps> = ({
    blockWhen,
    dialogText,
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const { push } = useHistory();
    const { t } = useContext(MultiLanguageSupportContext);
    const { setConfirmationDialogProps, clearConfirmationDialogProps } = useContext(ConfirmationDialogContext);

    const closeModal = () => setModalVisible(false);

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && blockWhen) {
            setModalVisible(true);
            setLastLocation(nextLocation);

            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        setConfirmationDialogProps({
            openDialog: modalVisible,
            dialogText: dialogText,
            leftButtonLabel: t('yes'),
            rightButtonLabel: t('cancel'),
            onLeftClick: handleConfirmNavigationClick,
            onRightClick: closeModal,
            onClose: closeModal,
        });

        return () => clearConfirmationDialogProps();
    }, [modalVisible]);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            push(lastLocation.pathname);
        }

        return () => setConfirmedNavigation(false);
    }, [confirmedNavigation, lastLocation]);

    return (
        <Prompt when={blockWhen} message={handleBlockedNavigation} />
    );
};
