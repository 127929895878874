import { defaultLanguage } from 'core/constants/supported-language';
import { LocalStorageKeys } from 'core/enums/local-storage-keys.enum';
import i18next from 'i18next';
import React, { createContext, FC, useEffect, useState } from 'react';
import { useTranslation, ValidTranslationKeys } from 'react-i18next';

const storedLangValue = localStorage.getItem(LocalStorageKeys.appLang);

interface MultiLanguageSupportProps {
    language: string;
    setLanguage: (lang: string) => void;
    t: (key: keyof ValidTranslationKeys, ...args: any) => string;
}

const MultiLanguageSupportContext = createContext<MultiLanguageSupportProps>({
    language: storedLangValue || defaultLanguage,
    setLanguage: (lang: string) => console.log(lang),
    t: (key, ...args) => '',
});

const MultiLanguageSupportProvider: FC = ({ children }) => {
    const [language, setLanguage] = useState(
        storedLangValue || defaultLanguage
    );

    const { t } = useTranslation();

    useEffect(() => {
        setLanguage(language);
        i18next.changeLanguage(language);
        localStorage.setItem(LocalStorageKeys.appLang, language);
    }, [language]);

    return (
        <MultiLanguageSupportContext.Provider
            value={{
                language,
                setLanguage,
                t: (key: keyof ValidTranslationKeys, ...args: any) =>
                    t(key, ...args),
            }}
        >
            {children}
        </MultiLanguageSupportContext.Provider>
    );
};

export { MultiLanguageSupportProvider, MultiLanguageSupportContext };
