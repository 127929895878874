import { useEntitiesBuilder } from 'core/hooks/entities.hook-builder';
import {
    ITrainingCategory,
    ITrainingCategoryResponse,
} from '../models/training-category.model';
import { trainingCategoriesQuery } from '../state/training-categories.query';
import { trainingCategoriesStore } from '../state/training-categories.store';

export const useTrainingCategories = useEntitiesBuilder<
    ITrainingCategory,
    ITrainingCategoryResponse
>(
    {
        query: trainingCategoriesQuery,
        store: trainingCategoriesStore,
    },
    true
);
