import { IEntity } from 'core/interfaces/entity.interface';
import { IProductResponse } from 'modules/products/models/product.model';
import {
    createTrainingCategoryFromResponse,
    ITrainingCategory,
    ITrainingCategoryResponse,
} from 'modules/training-categories/models/training-category.model';

export enum TrainingState {
    APPROVED = 'approved',
    PENDING = 'pending_approval',
    DRAFT = 'draft',
    DECLINED = 'declined',
}

export interface ITrainingDetails {
    title: string;
    duration: string;
    health: number;
    challenge: number;
    description: string | null;
    type: string;
    subscription: boolean | null;
    active: boolean;
    video: string;
    videoClip: string | null;
    categories: ITrainingCategory[];
    trainerName: string;
    screen: string | null;
    productIds: string[];
    createdBy: string | null;
    state: TrainingState;
    watchedTimes: number | null;
    createdFromLivestream: boolean;
}

export interface ITraining extends IEntity, ITrainingDetails {}
export interface ITrainingCommand {
    title: string;
    duration: string;
    health: number;
    challenge: number;
    description: string | null;
    type: string;
    subscription: boolean | null;
    categories?: IEntity<number>[];
    trainer_name: string;
    screen: string;
    products?: IEntity<number>[];
}

export interface ITrainingResponse
    extends IEntity<number>,
        Omit<ITrainingCommand, 'categories'> {
    categories?: ITrainingCategoryResponse[];
    products?: IProductResponse[];
    state: TrainingState;
    video: string;
    video_clip: string;
    trainer: IEntity<number>;
    active: boolean;
    watched_times: number;
    created_from_livestream: boolean;
}

export const emptyTraining: ITrainingDetails = {
    title: '',
    video: '',
    videoClip: null,
    categories: [],
    trainerName: '',
    type: '',
    screen: null,
    state: TrainingState.DRAFT,
    description: '',
    duration: '',
    health: 0,
    challenge: 0,
    productIds: [],
    subscription: false,
    active: false,
    createdBy: null,
    watchedTimes: null,
    createdFromLivestream: false,
};

export const createTraining = ({
    id = '0',
    ...props
}: Partial<ITraining>): ITraining => {
    return {
        id,
        ...emptyTraining,
        ...props,
    } as ITraining;
};

export const createTrainingFromResponse = ({
    id,
    categories,
    trainer_name,
    type,
    products,
    subscription,
    screen,
    trainer,
    video_clip,
    watched_times,
    created_from_livestream,
    ...props
}: ITrainingResponse): ITraining => {
    return createTraining({
        id: id.toString(),
        screen: screen || null,
        categories: categories?.map(createTrainingCategoryFromResponse) ?? [],
        trainerName: trainer_name,
        type: type,
        productIds: products?.map(({ id }) => id.toString()) ?? [],
        subscription: subscription,
        createdBy: trainer?.id.toString() ?? null,
        videoClip: video_clip ?? null,
        watchedTimes: watched_times ?? null,
        createdFromLivestream: created_from_livestream ?? null,
        ...props,
    });
};

export enum TrainingCategory {
    FITNESS_AND_TRAINING = 'FITNESS_AND_TRAINING',
    YOGA_AND_PILATES = 'YOGA_AND_PILATES',
}

export enum TrainingType {
    STRENGTH = 'STRENGTH',
    CARDIO = 'CARDIO',
    YOGA = 'YOGA',
    PILATES = 'PILATES',
}

export const createTrainingCommand = ({
    categories,
    trainerName,
    type,
    productIds,
    subscription,
    ...props
}: ITrainingDetails): Partial<ITrainingCommand> => {
    return {
        categories: categories?.map(({ id }) => ({ id: +id })) ?? [],
        trainer_name: trainerName,
        type: type,
        products:
            productIds?.map((id) => ({
                id: +id,
            })) ?? [],
        subscription: !!subscription,
        ...Object.keys(props).reduce((obj, key) => {
            const value = (props as any)[key];
            return value ? { ...obj, [key]: value } : obj;
        }, {}),
    };
};
