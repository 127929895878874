import { Box } from '@material-ui/core';
import { EntityListPagination } from 'core/components/EntityListPagination';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListItemContainer } from 'core/components/shared/ListItemContainer';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import dateFormat from 'dateformat';
import {
    ILivestream,
    LiveStreamLiveStatusEnum,
    LiveStreamState,
} from 'modules/livestream/models/livestream.model';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import React, { FC, useContext } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import style from './index.module.scss';
import { LivestreamListItemThumbnail } from './LivestreamListItemThumbnail';

interface ILivestreamDetailsSegmentProps {
    title: string;
    body: string;
}

const LivestreamDetailsSegment = ({
    title,
    body,
}: ILivestreamDetailsSegmentProps) => {
    return (
        <Box display="inline-box" paddingRight="20px">
            <span>{title} </span>
            <span className="gray-text">{body} </span>
        </Box>
    );
};

interface ILivestreamListRenderActionsProps {
    livestream: ILivestream;
}

interface ILivestreamListProps {
    showState?: boolean;
    renderActions: (props: ILivestreamListRenderActionsProps) => JSX.Element;
}

export const LivestreamList: FC<ILivestreamListProps> = ({
    renderActions,
    showState,
}) => {
    const { currentPageEntities } = useCurrentPageEntities<ILivestream>();
    const { t } = useContext(MultiLanguageSupportContext);
    const hasPermission = usePermissions();

    return (
        <>
            <div>
                {currentPageEntities.map((livestream) => {
                    let formatDate = '';
                    let startTime = '';
                    let endTime = '';
                    if (livestream.startsAt) {
                        const localDate = new Date(livestream.startsAt);
                        formatDate = dateFormat(localDate, 'dd.mm.yyyy.');
                        startTime = dateFormat(localDate, 'HH:MM');
                    }
                    if (livestream.endsAt) {
                        const localDate = new Date(livestream.endsAt);
                        endTime = dateFormat(localDate, 'HH:MM');
                    }

                    return (
                        <ListItemContainer
                            key={livestream.id}
                            entity={livestream}
                            renderFirstColumn={(entity) => (
                                <LivestreamListItemThumbnail
                                    livestream={entity}
                                />
                            )}
                            renderLastColumn={(entity) =>
                                renderActions({ livestream: entity })
                            }
                        >
                            <Box
                                display="flex"
                                flex={2}
                                flexDirection="row"
                                padding="0 20px"
                                //maxHeight="69px"
                                overflow="hidden"
                            >
                                <div>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        flexWrap="wrap"
                                    >
                                        {!livestream.canceled &&
                                            livestream.liveStatus ===
                                                LiveStreamLiveStatusEnum.LIVE &&
                                            livestream.state ===
                                                LiveStreamState.APPROVED && (
                                                <Box className={style.live}>
                                                    {t('live').toUpperCase()}
                                                </Box>
                                            )}
                                        {!livestream.canceled &&
                                            livestream.liveStatus ===
                                                LiveStreamLiveStatusEnum.PASSED &&
                                            livestream.state ===
                                                LiveStreamState.APPROVED &&
                                            !livestream.canceled && (
                                                <Box className={style.finished}>
                                                    {t(
                                                        'finished'
                                                    ).toUpperCase()}
                                                </Box>
                                            )}
                                        {!livestream.canceled &&
                                            (formatDate ||
                                                startTime ||
                                                endTime) && (
                                                <Box className={style.date}>
                                                    {formatDate}, {startTime}
                                                    {' - '}
                                                    {endTime}
                                                </Box>
                                            )}
                                        {!livestream.canceled &&
                                            livestream.state ===
                                                LiveStreamState.APPROVED && (
                                                <Box
                                                    className={
                                                        style.numberOfParticipants
                                                    }
                                                >
                                                    {` (${livestream.numberOfParticipiants}/${livestream.maxNumberOfParticipants} attendees)`}
                                                </Box>
                                            )}
                                        {livestream.canceled && (
                                            <Box className={style.canceled}>
                                                {t('canceled').toUpperCase()}
                                            </Box>
                                        )}
                                    </Box>

                                    {livestream.canceled ? (
                                        <Box
                                            className={
                                                style.livestreamStrikeTitle
                                            }
                                            padding="10px 0"
                                        >
                                            {livestream.title}
                                        </Box>
                                    ) : (
                                        <Box
                                            className={style.livestreamTitle}
                                            padding="10px 0"
                                        >
                                            {livestream.title}
                                        </Box>
                                    )}
                                    <LivestreamDetailsSegment
                                        title={t('trainer')}
                                        body={livestream.trainerName}
                                    />
                                    {!livestream.categories.length ? (
                                        <></>
                                    ) : (
                                        <LivestreamDetailsSegment
                                            title={t('categories')}
                                            body={livestream.categories
                                                .map(({ name }) => name)
                                                .join(', ')}
                                        />
                                    )}
                                    {showState && (
                                        <LivestreamDetailsSegment
                                            title={t('state')}
                                            body={t(
                                                livestream.state as keyof ValidTranslationKeys
                                            )}
                                        />
                                    )}
                                </div>
                            </Box>
                        </ListItemContainer>
                    );
                })}
            </div>
            <br />
            <Box display="flex" justifyContent="center" style={{ gap: '11px' }}>
                <EntityListPagination />
            </Box>
        </>
    );
};
