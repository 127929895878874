import { Thumbnail } from 'core/components/shared/Thumbnail';
import { useSession } from 'core/hooks/session.hook';
import { fullName, getUserRole } from 'modules/users/models/user.model';
import React, { FC } from 'react';
import './style.scss';

export const UserInfo: FC = (): JSX.Element => {
    const { me } = useSession();

    let role!: string;
    if (me) {
        switch (getUserRole(me)) {
            case 'ROLE_AIREX_ADMIN': {
                role = 'Admin';
                break;
            }
            case 'ROLE_MASTER_TRAINER': {
                role = 'Master Trainer';
                break;
            }
            case 'ROLE_TRAINER': {
                role = 'Trainer';
                break;
            }
            default:
                break;
        }
    }

    return !me ? (
        <></>
    ) : (
        <div className="userSectionContainer">
            <div className={`imageContainer ${me.avatar ? `` : `white`}`}>
                <Thumbnail url={me.avatar} />
            </div>
            <div className="textContainer">
                <div className="userName">{fullName(me)}</div>
                <div className="userRole">{role}</div>
            </div>
        </div>
    );
};
