import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createUserFromResponse,
    IUser,
    IUserResponse,
} from '../models/user.model';

export type UsersState = EntitiesState<IUser>;

@StoreConfig({
    name: 'users',
    resettable: true,
})
class UsersStore extends EntitiesStore<IUser, IUserResponse> {}

export const usersStore = new UsersStore({
    responseMapper: createUserFromResponse,
});
