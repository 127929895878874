import { createStyles, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { ChatMessage } from '@zoom/videosdk';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import ZoomClientContext from 'core/components/ZoomClientContext';
import { IEntity } from 'core/interfaces/entity.interface';
import { apiService } from 'core/services/apiService';
import { IStreamDetails } from 'modules/livestream/interfaces';
import {
    isAndroidBrowser,
    isSupportOffscreenCanvas,
} from 'modules/livestream/utils/platform';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router';
import LivestreamVideoComp from './LivestreamVideoComp';
import LivestreamVideoDialog from './LivestreamVideoDialog';

const SELF_VIDEO_ID = 'ZOOM_WEB_SDK_SELF_VIDEO';

declare global {
    interface Window {
        crossOriginIsolated: boolean;
    }
}

const isUseVideoElementToDrawSelfVideo =
    !self.crossOriginIsolated &&
    (isAndroidBrowser() || isSupportOffscreenCanvas());

const useStyles = makeStyles(() =>
    createStyles({
        stop: {
            fontSize: 18,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        back: {
            marginRight: 10,
        },
        participints: {
            height: 40,
            width: 225,
            backgroundColor: '#CE2E26',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: 'bold',
            cursor: 'pointer',
        },
        participintsAlowed: {
            height: 40,
            width: 225,
            backgroundColor: '#706F6F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: 'bold',
        },
        video: {
            width: '100%',
            height: 'calc(100vh - 10px)',
            background: '#222',
        },
    })
);

interface ILivestreamVideoHostProps extends IEntity {
    allowJoin: (value: boolean) => void;
    stream: Pick<IStreamDetails, 'name' | 'join_allowed'>;
}

const LivestreamVideoHost: FC<ILivestreamVideoHostProps> = ({
    id: livestreamId,
    stream,
    allowJoin,
}) => {
    const zmClient = useContext(ZoomClientContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();

    const [openDialogFinish, setOpenDialogFinish] = useState(false);
    const [openDialogCanceled, setOpenDialogCanceled] = useState(false);
    const [canHandleMic, setCanHandleMic] = useState(false);
    const { setDialogProps, clearDialogProps } = useContext(AirexDialogContext);

    const zmIntervalRef = useRef<NodeJS.Timeout>();

    const { push } = useHistory();

    const startVideo = useCallback(async () => {
        try {
            const mediaStream = zmClient.getMediaStream();
            if (isUseVideoElementToDrawSelfVideo) {
                const videoElement = document.querySelector<HTMLVideoElement>(
                    `#${SELF_VIDEO_ID}`
                );

                if (!videoElement) {
                    throw new Error('videoElement was not found');
                }
                if (mediaStream.isSupportHDVideo()) {
                    await mediaStream
                        .startVideo({
                            videoElement,
                            captureHeight: 360,
                            captureWidth: 640,
                            hd: false,
                        })
                        .catch((e) => {
                            switch (e.type) {
                                case 'VIDEO_USER_FORBIDDEN_CAPTURE':
                                // TODO: implement popup to notifiy user to turn camera permissions on
                                // eslint-disable-next-line no-fallthrough
                                default:
                                    console.log('startVideo catch', e);
                                    break;
                            }
                        });
                } else {
                    await mediaStream
                        .startVideo({
                            videoElement,
                            captureHeight: 360,
                            captureWidth: 640,
                            hd: false,
                        })
                        .catch((e) => {
                            switch (e.type) {
                                case 'VIDEO_USER_FORBIDDEN_CAPTURE':
                                // TODO: implement popup to notifiy user to turn camera permissions on
                                // eslint-disable-next-line no-fallthrough
                                default:
                                    console.log('startVideo catch', e);
                                    break;
                            }
                        });
                }
            } else {
                if (mediaStream.isSupportHDVideo()) {
                    await mediaStream
                        .startVideo({
                            captureHeight: 360,
                            captureWidth: 640,
                            hd: false,
                        })
                        .catch((e) => {
                            switch (e.type) {
                                case 'VIDEO_USER_FORBIDDEN_CAPTURE':
                                // TODO: implement popup to notifiy user to turn camera permissions on
                                // eslint-disable-next-line no-fallthrough
                                default:
                                    console.log('startVideo catch', e);
                                    break;
                            }
                        });
                } else {
                    await mediaStream
                        .startVideo({
                            captureHeight: 360,
                            captureWidth: 640,
                            hd: false,
                        })
                        .catch((e) => {
                            switch (e.type) {
                                case 'VIDEO_USER_FORBIDDEN_CAPTURE':
                                // TODO: implement popup to notifiy user to turn camera permissions on
                                // eslint-disable-next-line no-fallthrough
                                default:
                                    console.log('startVideo catch', e);
                                    break;
                            }
                        });
                }
            }

            await mediaStream.startAudio().catch((e) => {
                console.log('Start audio', e);
            });
        } catch (e: any) {
            console.log('Media stream error', e);
        }
    }, []);

    const onChatMessage = async ({ message }: ChatMessage) => {
        switch (message) {
            case 'CANCEL':
                zmIntervalRef.current && clearInterval(zmIntervalRef.current);

                setTimeout(() => {
                    zmClient.leave(true);
                }, 2000);

                setOpenDialogCanceled(true);
                setTimeout(() => {
                    exitLivestream();
                }, 8000);
                break;
        }
    };

    useEffect(() => {
        zmClient.on('chat-on-message', onChatMessage);
        window.addEventListener('online', (event) => {
            console.log('You are now connected to the network.', event);
            window.location.reload();
        });

        zmClient.on('auto-play-audio-failed', () => {
            console.log('auto play failed, waiting user interaction');
            setDialogProps({
                openDialog: true,
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('ok')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                ],
                contentText: t('auto-play-failed'),
                onClose: clearDialogProps,
            });
        });

        zmClient.on('current-audio-change', (payload) => {
            if (payload.action === 'join') {
                console.log('Joined audio by ', payload.type);

                setCanHandleMic(true);
            }
        });

        zmClient.getChatClient().sendToAll('HOST_IS_BACK');

        const beIntervalRef = setInterval(() => {
            apiService.put(`streams/track-activity/${livestreamId}`, null);
        }, 30000);

        zmIntervalRef.current = setInterval(() => {
            zmClient.getChatClient().sendToAll('HOST_IS_ALIVE');
        }, 2000);

        return () => {
            zmClient.off('chat-on-message', onChatMessage);
            beIntervalRef && clearInterval(beIntervalRef);
            zmIntervalRef.current && clearInterval(zmIntervalRef.current);

            try {
                const mediaStream = zmClient.getMediaStream();
                mediaStream
                    .stopVideo()
                    .catch((e) => console.log('Stop video error', e));
                mediaStream
                    .stopAudio()
                    .catch((e) => console.log('Stop audio error', e));
            } catch (e) {
                console.log('Media stream error', e);
            }
        };
    }, []);

    const finishLivestream = async () => {
        zmIntervalRef.current && clearInterval(zmIntervalRef.current);

        try {
            await apiService.put<void>(
                `streams/finish/` + livestreamId,
                livestreamId
            );
        } catch (e) {
            console.log('Api service error', e);
        }

        try {
            await zmClient.getChatClient().sendToAll('FINISH');
        } catch (e) {
            console.log('Chat client error', e);
        }

        setTimeout(() => {
            zmClient.leave(true);
        }, 2000);

        setTimeout(() => {
            exitLivestream();
        }, 4000);
    };

    const exitLivestream = () => {
        push('/my-livestream-videos/' + livestreamId);
    };

    return (
        <>
            <LivestreamVideoComp
                livestreamName={stream.name}
                canHandleMic={canHandleMic}
                renderBackButton={() => (
                    <div
                        className={classes.stop}
                        onClick={() => setOpenDialogFinish(true)}
                    >
                        <ArrowBack className={classes.back} />
                        <span>{t('button-stop-livestream')}</span>
                    </div>
                )}
                renderActionButton={() =>
                    stream.join_allowed ? (
                        <div className={classes.participintsAlowed}>
                            <span>{t('participants-allowed')}</span>
                        </div>
                    ) : (
                        <div
                            className={classes.participints}
                            onClick={() => allowJoin(true)}
                        >
                            <span>{t('participants-allow')}</span>
                        </div>
                    )
                }
                onVideoDecodeReady={startVideo}
            >
                {isUseVideoElementToDrawSelfVideo && (
                    <video className={classes.video} id={SELF_VIDEO_ID} />
                )}
            </LivestreamVideoComp>
            <LivestreamVideoDialog
                open={openDialogFinish}
                title={t('stop-livestream-title')}
                body={t('stop-livestream-message')}
                renderButtons={() => (
                    <>
                        <button
                            type="button"
                            onClick={() => setOpenDialogFinish(false)}
                            className="cancelButton"
                        >
                            {t('stay-livestream')}
                        </button>
                        <button
                            type="button"
                            onClick={finishLivestream}
                            className="confirmButton"
                        >
                            {t('stop-livestream')}
                        </button>
                    </>
                )}
            />
            <LivestreamVideoDialog
                open={openDialogCanceled}
                title={t('livestream-canceled-dialog-title')}
                body={t('livestream-canceled-dialog-message')}
            />
        </>
    );
};

export default LivestreamVideoHost;
