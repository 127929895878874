import { ReactComponent as AirexAcademy } from 'assets/images/airex_academy.svg';
import { Copyright } from 'core/components/Copyright';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import React, { FC, useContext } from 'react';

export const CopyrightAirex: FC = (): JSX.Element => {
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <Copyright
            text={t('powered-by')}
            logo={<AirexAcademy />}
            companyName="Airex"
        />
    );
};
