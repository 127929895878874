import { IEntity } from 'core/interfaces/entity.interface';
import { IUpdateEntityStateOptions } from 'core/interfaces/update-entity-state-options.interface copy';
import { EntitiesStore } from 'core/state/entities.store';

interface IUpdateStateProps<T extends IEntity, R> {
    store: EntitiesStore<T, R>;
    active: boolean;
}

export const updateActive = <T extends IEntity, R>({
    store,
    active,
}: IUpdateStateProps<T, R>) => async (
    id: string,
    options?: Partial<IUpdateEntityStateOptions>
): Promise<void> => {
    const { shouldFetchAfterSuccess } = {
        shouldFetchAfterSuccess: false,
        ...options,
    };

    await store.crudApiService.updateState(
        id,
        active ? 'activate' : 'deactivate'
    );

    /**
     * id as any is used as typescript dosen't pick up the correct override to use
     * { active } as any is used because the generic store entity type doesn't know about the active property
     */
    store.update(id as any, { active } as any);

    if (shouldFetchAfterSuccess) {
        store.fetchEntities();
    }
};
