import { Box, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, RadioProps, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import Rating from '@material-ui/lab/Rating';
import { useFormikContext } from 'formik';
import React, { FC, useCallback, useContext, useMemo } from 'react';

import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import { maxNumberOfParticipants, minNumberofParticipants } from 'modules/livestream/constants/number-of-participants';
import { ILivestreamDetails } from 'modules/livestream/models/livestream.model';
import { useTrainingCategories } from 'modules/training-categories/hooks/trening-categories.hook';

import { DatePickersComponent } from './DatePickersComponent';

import './style.scss';
import 'react-datepicker/dist/react-datepicker.css';

export const LivestreamDetailsTab: FC<IReadonlyProps> = ({ readonly }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const formik = useFormikContext<ILivestreamDetails>();
    const [trainingCategories] = useTrainingCategories();

    // /**
    //  * This returns the root category
    //  *
    //  * The id value '0' is hardcoded.
    //  * This should be updated on the BE to return null since '0' is technically a value and could by all means be valid for all we know.
    //  */
    // const rootCategory = useMemo(
    //     () => trainingCategories.find(({ parentId }) => parentId === '0'),
    //     [trainingCategories]
    // );

    // /**
    //  * This returns the selected subcategory
    //  *
    //  * It is dependant on the layout which only allows the subcategory to be selected
    //  */
    // const category = useMemo(() => {
    //     return (
    //         formik.values.categories.find(
    //             ({ parentId }) => parentId !== rootCategory?.id
    //         ) ?? null
    //     );
    // }, [formik.values.categories, rootCategory]);

    const handleCategory = useCallback(
        (e) => {
            e.preventDefault();

            /**
             * Extract the subcategory that has been selected
             */
            const newCategory = trainingCategories.find(
                (trainingCategory) => trainingCategory.id === e.target.value
            );

            /**
             * Creates an array that contains the subcategory and it's parent category
             */
            const categories = trainingCategories.filter(
                (trainingCategory) =>
                    newCategory && trainingCategory.id === newCategory.id
            );

            formik.setFieldValue('categories', categories);
        },
        [trainingCategories]
    );

    const handleRatingChange =
        (name: string) =>
        (
            _event: React.ChangeEvent<{}>, // eslint-disable-line @typescript-eslint/ban-types
            value: number | null
        ) => {
            formik.setValues({
                ...formik.values,
                [name]: value,
            });
        };

    return (
        <Grid container spacing={6}>
            <Grid item md={12} lg={6}>
                <p className="text">{t('categories')}</p>
                <FormControl component="fieldset">
                    <RadioGroup
                        name="category"
                        value={formik.values.categories?.[0]?.id ?? ''}
                        onChange={handleCategory}
                    >
                        <Box pb="20px">
                            <Box display="flex" flexWrap="wrap">
                                {trainingCategories.map(({ id, name }) => (
                                    <Box key={id} width="150px" pl="30px">
                                        <StyledFormControlLabel
                                            label={name}
                                            control={<StyledRadio />}
                                            value={id}
                                            disabled={readonly}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </RadioGroup>
                    {formik.errors.categories !== '' && (
                        <FormHelperText className="formHelperText error">
                            {formik.errors.categories}
                        </FormHelperText>
                    )}
                </FormControl>
                <Box pt="20px">
                    <p className="text">{t('health-benefits')}</p>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                        <p>{t('health')}</p>
                        <Box pr="5px" />
                        <StyledRating
                            max={3}
                            icon={
                                <FiberManualRecordRoundedIcon fontSize="small" />
                            }
                            emptyIcon={
                                <FiberManualRecordOutlinedIcon fontSize="small" />
                            }
                            name="health"
                            value={formik.values.health}
                            onChange={handleRatingChange('health')}
                            disabled={readonly}
                        />
                        <Box pr="30px" />
                        <p>{t('challenge')}</p>
                        <Box pr="5px" />
                        <StyledRating
                            max={3}
                            icon={
                                <FiberManualRecordRoundedIcon fontSize="small" />
                            }
                            emptyIcon={
                                <FiberManualRecordOutlinedIcon fontSize="small" />
                            }
                            name="challenge"
                            value={formik.values.challenge}
                            onChange={handleRatingChange('challenge')}
                            disabled={readonly}
                        />
                    </Box>
                    {formik.errors.health !== '' &&
                        formik.errors.challenge !== '' && (
                            <FormHelperText className="formHelperText error">
                                {formik.errors.health ||
                                    formik.errors.challenge}
                            </FormHelperText>
                        )}
                </Box>
                <Box pt="20px">
                    <p className="text">{t('livestream-subscripton-text')}</p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            name="subscription"
                            value={formik.values.subscription?.toString()}
                            onChange={(e) =>
                                formik.setFieldValue(
                                    'subscription',
                                    e.target.value === 'true'
                                )
                            }
                        >
                            {[
                                { value: 'true', label: t('paid') },
                                { value: 'false', label: t('free') },
                            ].map((props, index) => (
                                <Box key={index} width="150px" pl="30px">
                                    <StyledFormControlLabel
                                        control={<StyledRadio />}
                                        disabled={readonly}
                                        {...props}
                                    />
                                </Box>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box pt="20px">
                    <p className="text">{t('maximum-number')}</p>
                    <Box mt="10px" className="text-field">
                        <TextField
                            variant="outlined"
                            placeholder={t('enter-max-no')}
                            name="maxNumberOfParticipants"
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.maxNumberOfParticipants ?? ''}
                            size="small"
                            fullWidth
                            inputProps={{
                                min: minNumberofParticipants,
                                max: maxNumberOfParticipants,
                            }}
                            disabled={readonly}
                        />
                    </Box>
                    {formik.errors.maxNumberOfParticipants !== '' && (
                        <FormHelperText className="formHelperText error">
                            {formik.errors.maxNumberOfParticipants}
                        </FormHelperText>
                    )}
                </Box>
            </Grid>
            <Grid item md={12} lg={6}>
                <p className="text">{t('livestream-description')}</p>
                <textarea
                    className="descriptionInput"
                    maxLength={1000}
                    spellCheck={false}
                    name="description"
                    value={formik.values.description ?? ''}
                    onChange={formik.handleChange}
                    required
                    disabled={readonly}
                />
                {formik.errors.description !== '' && (
                    <FormHelperText className="formHelperText error">
                        {formik.errors.description}
                    </FormHelperText>
                )}
            </Grid>
            <Grid item lg={12}>
                <DatePickersComponent
                    readonly={readonly}
                    validateTakenTimeslot={true}
                />
            </Grid>
        </Grid>
    );
};

const StyledRating = withStyles({
    iconFilled: {
        color: '#000',
    },
    iconHover: {
        color: '#000',
    },
    iconEmpty: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
})(Rating);

const StyledRadio = withStyles({
    root: {
        color: '#000',
        '&$checked': {
            color: '#000',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio required color="default" {...props} />);

const StyledFormControlLabel = withStyles({
    label: {
        fontSize: '14px',
    },
})(FormControlLabel);
