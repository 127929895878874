import { Box } from '@material-ui/core';
import { EntityListPagination } from 'core/components/EntityListPagination';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListItemContainer } from 'core/components/shared/ListItemContainer';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { ITraining } from 'modules/trainings/models/training.model';
import React, { FC, useContext } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import style from './index.module.scss';
import { TrainingListItemThumbnail } from './TrainingListItemThumbnail';

interface ITrainingDetailsSegmentProps {
    title: string;
    body: string;
}

const TrainingDetailsSegment = ({
    title,
    body,
}: ITrainingDetailsSegmentProps) => {
    return (
        <Box display="inline-box" paddingRight="20px">
            <span>{title} </span>
            <span className="gray-text">{body} </span>
        </Box>
    );
};

interface ITrainingListRenderActionsProps {
    training: ITraining;
}

interface ITrainingListProps {
    showState?: boolean;
    renderActions: (props: ITrainingListRenderActionsProps) => JSX.Element;
}

export const TrainingList: FC<ITrainingListProps> = ({
    renderActions,
    showState,
}) => {
    const { currentPageEntities } = useCurrentPageEntities<ITraining>();
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <>
            <div>
                {currentPageEntities.map((training) => (
                    <ListItemContainer
                        key={training.id}
                        entity={training}
                        renderFirstColumn={(entity) => (
                            <TrainingListItemThumbnail training={entity} />
                        )}
                        renderLastColumn={(entity) =>
                            renderActions({ training: entity })
                        }
                    >
                        <Box
                            display="flex"
                            flex={2}
                            flexDirection="row"
                            padding="0 20px"
                        //maxHeight="69px"
                        // overflow="hidden"
                        >
                            <div>
                                {training.createdFromLivestream && (
                                    <Box
                                        className={style.trainingType}
                                    >
                                        <span>{t('from-livestream')}</span>
                                    </Box>
                                )}
                                <Box
                                    className={style.trainingTitle}
                                    padding="10px 0"
                                >
                                    {training.title}
                                </Box>
                                <div className={style.trainingDetails}>

                                    <TrainingDetailsSegment
                                        title={t('trainer')}
                                        body={training.trainerName}
                                    />
                                    {!training.categories.length ? (
                                        <></>
                                    ) : (
                                        <TrainingDetailsSegment
                                            title={t('categories')}
                                            body={training.categories
                                                .map(({ name }) => name)
                                                .join(', ')}
                                        />
                                    )}
                                    {showState && (
                                        <TrainingDetailsSegment
                                            title={t('state')}
                                            body={t(
                                                training.state as keyof ValidTranslationKeys
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                        </Box>
                    </ListItemContainer>
                ))}
            </div>
            <br />
            <Box display="flex" justifyContent="center" style={{ gap: '11px' }}>
                <EntityListPagination />
            </Box>
        </>
    );
};
