import {
    createUser,
    IUser,
    IUserResponse,
    UserRoles,
} from 'modules/users/models/user.model';

export interface ITrainerDetails {
    contactNumber: Array<string> | null;
    languages: Array<string> | null;
    social: Array<string | null> | null;
    education: Array<string> | null;
    certificates: Array<{ name: string }> | null;
    expertise: Array<{ name: string; parent: number }> | null;
}

const defaultTrainerValues: ITrainerDetails = {
    contactNumber: [],
    languages: [],
    social: [],
    education: [],
    certificates: [],
    expertise: [],
};

export interface ITrainer extends IUser, ITrainerDetails {
    createdBy: string;
    createdByRole: UserRoles;
}

export interface ITrainerResponse extends IUserResponse {
    contact_number?: Array<string> | null;
    languages?: Array<string> | null;
    social?: Array<string | null> | null;
    education?: Array<string> | null;
    certificates?: Array<{ name: string }> | null;
    expertise?: Array<{ name: string; parent: number }> | null;
    created_by?: Pick<IUserResponse, 'id' | 'roles'>;
}

export const createTrainer = ({
    id,
    ...props
}: Partial<ITrainer>): ITrainer => {
    if (!id) {
        throw new Error('Unable to create a trainer without an ID!');
    }

    return {
        ...createUser({ id, ...props }),
        ...defaultTrainerValues,
        ...props,
    } as ITrainer;
};

export const createTrainerFromResponse = ({
    id,
    first_name,
    last_name,
    contact_number,
    created_by,
    ...props
}: ITrainerResponse): ITrainer => {
    return createTrainer({
        id: id.toString(),
        firstName: first_name ?? '',
        lastName: last_name ?? '',
        contactNumber: contact_number ?? defaultTrainerValues.contactNumber,
        createdBy: created_by?.id.toString(),
        createdByRole: created_by?.roles[0],
        ...props,
    });
};
