import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { PageContainer } from 'core/components/PageContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { useSession } from 'core/hooks/session.hook';
import { IEntity } from 'core/interfaces/entity.interface';
import { TrainerDetails } from 'modules/trainers/components/TrainerDetails';
import { ITrainer } from 'modules/trainers/models/trainer.model';
import { trainersQuery } from 'modules/trainers/state/trainers.query';
import { FC, useContext } from 'react';

export const MyProfilePageContainer: FC = WithProvider(() => {
    const { me } = useSession();

    return !me ? <></> : <MyProfilePage id={me.id} />;
}, trainersQuery);

const MyProfilePage: FC<IEntity> = ({ id }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [trainer] = useEntityById<ITrainer>({ id, forceFetch: true });

    return (
        <PageContainer
            header={{
                title: t('my-profile'),
                actions: !trainer
                    ? []
                    : [
                          {
                              content: (
                                  <Button label={t('edit')} color="primary" />
                              ),
                              path: `/my-profile/edit`,
                          },
                      ],
            }}
            renderContent={() =>
                !trainer ? <></> : <TrainerDetails trainer={trainer} />
            }
        />
    );
};
