import {
    applyTransaction,
    arrayRemove,
    MultiActiveState,
} from '@datorama/akita';
import { AxiosResponse } from 'axios';
import { ICreateEntityOptions } from 'core/interfaces/create-entity-options.interface';
import { IDeleteEntitiesOptions } from 'core/interfaces/delete-entities-options.interface';
import { IReadAllEntitiesResponse } from 'core/interfaces/read-all-entities-response.interface';
import { CrudApiService } from 'core/services/crudApiService';
import { initialEntitiesState } from '../constants/initial-entities-state';
import { IEntity } from '../interfaces/entity.interface';
import {
    EntityStateWithHighlight,
    EntityStoreWithHighlight,
} from './highlight.store';

export interface IEntityUIState {
    searchTerm: string;
    pageNumber: number;
    pageSize: number;
}

export interface EntitiesState<T, UI = IEntityUIState>
    extends EntityStateWithHighlight<T, string>,
        MultiActiveState<string> {
    ui: UI;
    total: number | null;
    highlightNext: 'first' | 'last' | null;
    currentPageIds: string[];
    activeTabIndex: number;
    validatedTabIndex: number;
    editMode: boolean;
}

export interface IEntitiesStoreConfig<T, R> {
    responseMapper: (x: R) => T;
    commandMapper?: (x: Omit<T, 'id'>) => any;
    listResponseMapper?: (
        callback: (partialState: Partial<EntitiesState<T>>) => void
    ) => (x: any) => R[];
}

export abstract class EntitiesStore<
    T extends IEntity,
    R,
    RA = IReadAllEntitiesResponse<R>
> extends EntityStoreWithHighlight<EntitiesState<T>> {
    private _listResponseMapper: (
        callback: (partialState: Partial<EntitiesState<T>>) => void
    ) => (x: any) => R[] =
        (callback) =>
        ({ results, meta }) => {
            const { total } = meta;
            callback({ total });
            return results;
        };

    private _crudApiService: CrudApiService<T, R>;

    get crudApiService(): CrudApiService<T, R> {
        return this._crudApiService;
    }
    get entitiesSlug(): string {
        return this._entitiesSlug ?? this.storeName;
    }

    constructor(
        {
            responseMapper,
            commandMapper,
            listResponseMapper,
        }: IEntitiesStoreConfig<T, R>,
        private _entitiesSlug?: string
    ) {
        super({ ...initialEntitiesState });

        this._crudApiService = new CrudApiService(this.entitiesSlug, {
            responseMapper,
            commandMapper,
        });

        if (listResponseMapper) {
            this._listResponseMapper = listResponseMapper;
        }
    }

    patchUIState(partial: Partial<IEntityUIState>): void {
        const { ui } = this.getValue();

        this.update({
            ui: {
                ...ui,
                ...partial,
            },
        });
    }

    setCurrentPageEntities(entities: T[]): void {
        if (!entities.length) {
            this.update({
                highlightNext: null,
                currentPageIds: [],
                highlightedId: null,
            });
            return;
        }

        const { highlightNext, highlightedId } = this.getValue();
        applyTransaction(() => {
            this.upsertMany(entities);

            const currentPageIds = entities.map(({ id }) => id);

            this.update({
                highlightNext: null,
                currentPageIds,
            });

            switch (highlightNext) {
                case 'first':
                    this.setHighlight(currentPageIds[0]);
                    break;
                case 'last':
                    this.setHighlight(
                        currentPageIds[currentPageIds.length - 1]
                    );
                    break;
                default:
                    if (
                        highlightedId &&
                        currentPageIds.indexOf(highlightedId) === -1
                    ) {
                        this.setHighlight();
                    }

                    break;
            }
        });
    }

    clearCurrentPageState = (ui?: IEntityUIState): void => {
        applyTransaction(() => {
            if (ui) {
                this.update({
                    ui,
                });
            }
            this.setCurrentPageEntities([]);
        });
    };

    fetchEntities = async (
        params: IEntityUIState = this.getValue().ui,
        responseHandler?: (res: AxiosResponse) => R[],
        skipSetCurrentPageEntities?: boolean
    ): Promise<T[]> => {
        if (!responseHandler) {
            responseHandler = ({ data }: AxiosResponse) => {
                return this._listResponseMapper((partialState) => {
                    this.update(partialState);
                })(data);
            };
        }

        const entities = await this._crudApiService.readAll<RA>(params, {
            responseHandler,
        });

        if (!skipSetCurrentPageEntities) {
            this.setCurrentPageEntities(entities);
        } else {
            this.add(entities);
        }

        return entities;
    };

    fetchEntitiesV2 = async (
        params: IEntityUIState = this.getValue().ui,
        responseHandler?: (res: AxiosResponse) => R[],
        skipSetCurrentPageEntities?: boolean
    ): Promise<T[]> => {
        if (!responseHandler) {
            responseHandler = ({ data }: AxiosResponse) => {
                return this._listResponseMapper((partialState) => {
                    this.update(partialState);
                })(data);
            };
        }

        const entities = await this._crudApiService.readAllV2<RA>(params, {
            responseHandler,
        });

        if (!skipSetCurrentPageEntities) {
            this.setCurrentPageEntities(entities);
        } else {
            this.add(entities);
        }

        return entities;
    };

    fetchById = async (id: string): Promise<T> => {
        const entity = await this._crudApiService.read(id);

        this.upsert(id, entity);

        return entity;
    };

    updateEntity = async (
        entity: T,
        { localOnly }: { localOnly: boolean } = { localOnly: false }
    ): Promise<T> => {
        const updatedEntity = localOnly
            ? entity
            : await this._crudApiService.update(entity);

        this.upsert(updatedEntity.id, updatedEntity);

        return updatedEntity;
    };

    createEntity = async (
        entity: Omit<T, 'id'>,
        options?: Partial<ICreateEntityOptions>
    ): Promise<T> => {
        const { shouldFetchAfterSuccess } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };

        const createdEntity = await this._crudApiService.create(entity);

        this.upsert(createdEntity.id, createdEntity);

        if (shouldFetchAfterSuccess) {
            this.fetchEntities();
        }

        return createdEntity;
    };

    deleteById = async (id: string): Promise<void> => {
        await this._crudApiService.deleteById(id);

        this.remove(id);
    };

    deleteMultiple = async (
        ids: string[],
        options?: Partial<IDeleteEntitiesOptions>
    ): Promise<void> => {
        const { shouldFetchAfterSuccess } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };

        const responses = await Promise.all(
            ids?.map(async (id) => ({
                id,
                res: await this._crudApiService.deleteById(id),
            }))
        );

        const deletedIds = responses
            .filter(({ res }) => res.status === 204)
            .map(({ id }) => id);

        applyTransaction(async () => {
            const { currentPageIds } = this.getValue();

            this.remove(deletedIds);
            this.update({
                currentPageIds: arrayRemove(currentPageIds, deletedIds),
            });
        });

        if (shouldFetchAfterSuccess) {
            const { ui } = this.getValue();

            if (ui.pageNumber === 1) {
                this.fetchEntities(ui);
            } else {
                this.patchUIState({ pageNumber: 1 });
            }
        }
    };
}
