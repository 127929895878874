import { Box } from '@material-ui/core';
import { EntityListPagination } from 'core/components/EntityListPagination';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListHeader } from 'core/components/shared/ListHeader';
import { ListItemContainer } from 'core/components/shared/ListItemContainer';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { ITrainer } from 'modules/trainers/models/trainer.model';
import { UserRole } from 'modules/users/constants/role-permissions';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { fullName } from 'modules/users/models/user.model';
import React, { FC, useContext } from 'react';
import style from './index.module.scss';
import { TrainerListActions } from './TrainerListActions';
import { TrainerListItemAvatar } from './TrainerListItemAvatar';
import { TrainerListItemTemplate } from './TrainerListItemTemplate';

export const TrainerList: FC = () => {
    const { currentPageEntities } = useCurrentPageEntities<ITrainer>();
    const { t } = useContext(MultiLanguageSupportContext);
    const hasPermission = usePermissions();
    return (
        <>
            <ListHeader
                renderFirstColumn={() => <Box width="48px" />}
                renderLastColumn={() => (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                         width="135px"
                    />
                )}
            >
                <TrainerListItemTemplate
                    expertise={
                        <span className="gray-text">{t('expertise')}</span>
                    }
                    fullName={<span className="gray-text">{t('trainer')}</span>}
                    createdByRole={
                        hasPermission(PERMISSIONS.VIEW_TRAINER_ADDED_BY) ? (
                            <span className="gray-text">{t('added-by')}</span>
                        ) : null
                    }
                />
            </ListHeader>
            <br />
            <div>
                {currentPageEntities.map((trainer) => (
                    <ListItemContainer
                        key={trainer.id}
                        entity={trainer}
                        boxProps={{
                            className: trainer.active ? '' : style.inactive,
                        }}
                        renderFirstColumn={(entity) => (
                            <TrainerListItemAvatar trainer={entity} />
                        )}
                        renderLastColumn={(entity) => (
                            <Box minWidth="135px"><TrainerListActions trainer={entity} /></Box>
                        )}
                    >
                        <TrainerListItemTemplate
                            expertise={
                                <span className={style.expertise}>
                                    {trainer.expertise
                                        ?.map((x) => x.name)
                                        .join(', ')}
                                </span>
                            }
                            fullName={
                                <span className={style.fullName}>
                                    {fullName(trainer)}
                                </span>
                            }
                            createdByRole={
                                hasPermission(
                                    PERMISSIONS.VIEW_TRAINER_ADDED_BY
                                ) ? (
                                    <span className={style.expertise}>
                                        {t(
                                            trainer.createdByRole ===
                                                UserRole.ROLE_AIREX_ADMIN
                                                ? 'airex-admin'
                                                : 'master-trainers'
                                        )}
                                    </span>
                                ) : null
                            }
                        />
                    </ListItemContainer>
                ))}
            </div>
            <br />
            <Box display="flex" justifyContent="center" style={{ gap: '11px' }}>
                <EntityListPagination />
            </Box>
        </>
    );
};
