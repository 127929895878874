import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const LinearProgressBar = withStyles(() =>
    createStyles({
        root: {
            height: 15,
            borderRadius: 3,
        },
        colorPrimary: {
            backgroundColor: "#f1c3c1",
        },
        bar: {
            backgroundColor: "#ce2e26"
        }
    }),
)((props: LinearProgressProps & { value: number }) => {
    return (
        <Box width="100%">
            <LinearProgress
                variant="determinate"
                {...props}
            />
        </Box>
    );
});
