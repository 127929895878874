import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import {
    IPageContainerHeaderProps,
    PageContainer,
} from 'core/components/PageContainer';
import { RoutePrompt } from 'core/components/RoutePrompt';
import { WithProvider } from 'core/hocs/WithProvider';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { useSession } from 'core/hooks/session.hook';
import { IActionProps } from 'core/interfaces/action-props.interface';
import { IBreadcrumPathProps } from 'core/interfaces/breadcrumb-path-props.interface';
import { IEntity } from 'core/interfaces/entity.interface';
import { FormikErrors, FormikProvider, useFormik } from 'formik';
import { AgendaTab } from 'modules/livestream/components/AgendaTab';
import { LivestreamDetailsTab } from 'modules/livestream/components/LivestreamDetailsTab';
import { ProductsUsedTab } from 'modules/livestream/components/ProductsUsedTab';
import {
    ILivestream,
    ILivestreamDetails,
    LiveStreamState,
} from 'modules/livestream/models/livestream.model';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { UserRole } from 'modules/users/constants/role-permissions';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import LivestreamVideoDialog from '../components/LivestreamVideoComp/LivestreamVideoDialog';
import { UploadLivestreamVideoTab } from '../components/UploadLivestreamVideoTab';
import {
    maxNumberOfParticipants,
    minNumberofParticipants,
} from '../constants/number-of-participants';
import { useLiveStreamState } from '../hooks/livestream-state.hook';
import { useLivestream } from '../hooks/livestream.hook';
import { livestreamsQuery } from '../state/livestreams.query';
import { livestreamsStore } from '../state/livestreams.store';

enum LivestreamPageTabs {
    UPLOAD_VIDEO,
    LIVESTREAM_DETAILS,
    PRODUCTS_USED,
    AGENDA,
}

export interface ILivestreamPageProps {
    id: string;
    videoClip: string | null;
    initialValues: Omit<ILivestreamDetails, 'videoClip'>;
    onSubmit?: (
        entity: ILivestreamDetails
    ) => Promise<ILivestreamDetails | null>;
}

type LivestreamPageProps = ILivestreamPageProps & IBreadcrumPathProps;

interface IUseRedirect {
    shouldRedirectAfterSuccess: boolean;
    path: string;
}

export const LivestreamPage: FC<LivestreamPageProps> = ({
    id,
    videoClip,
    breadcrumbPath,
    initialValues,
    onSubmit = async () => null,
}: LivestreamPageProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchDialogProps, setDialogProps, clearDialogProps } =
        useContext(AirexDialogContext);
    const { push } = useHistory();
    const { me } = useSession();
    const hasPermission = usePermissions();
    const [tempVideoClip, setTempVideoClip] = useState(videoClip);
    const { approve, decline, requestApproval } = useLiveStreamState(id, false);
    const [disabled, setDisabled] = useState(true);
    const [openDialogStart, setOpenDialogStart] = useState(false);

    const tabs = useMemo(
        () => [
            t('upload-video-preview'),
            t('details'),
            t('products-used'),
            t('agenda'),
        ],
        [t]
    );

    const canEdit = useMemo(
        () =>
            initialValues.state === LiveStreamState.DRAFT &&
            (hasPermission(PERMISSIONS.EDIT_LIVESTREAM) ||
                (hasPermission(PERMISSIONS.EDIT_OWN_LIVESTREAM) &&
                    me?.id === initialValues.createdBy)),
        [me, initialValues]
    );

    const validate = (values: Omit<ILivestreamDetails, 'videoClip'>) => {
        const errors: FormikErrors<ILivestreamDetails> = {};

        !values.screen && (errors.screen = t('required'));
        !values.categories?.length && (errors.categories = t('required'));
        !values.health && (errors.health = t('required'));
        !values.challenge && (errors.challenge = t('required'));
        !values.description && (errors.description = t('required'));
        !values.productIds?.length && (errors.productIds = t('required'));
        !values.type && (errors.type = t('required'));
        !values.agenda && (errors.agenda = t('required'));
        !values.minRequirements.length &&
            (errors.minRequirements = t('add-one-requirement'));
        (!values.maxNumberOfParticipants ||
            values.maxNumberOfParticipants < minNumberofParticipants ||
            values.maxNumberOfParticipants > maxNumberOfParticipants) &&
            (errors.maxNumberOfParticipants = t('number-of-participants'));
        !values.startsAt && (errors.startsAt = t('livestream-24-hours'));

        // validate livestream duration
        const endTime = values.endsAt && values.endsAt.getTime();
        const startTime = values.startsAt && values.startsAt.getTime();
        const readonly = !canEdit;
        if (!readonly) {
            if (endTime && startTime) {
                if (endTime < startTime) {
                    errors.endsAt = t('livestream-start-time');
                } else {
                    // calculate diff, duration must be between 30 and 60 min
                    const diff = Math.abs(endTime - startTime) / 60000; // min
                    if (diff < 30) {
                        errors.startsAt = t('livestream-30-min');
                    }
                    if (diff > 60) {
                        errors.startsAt = t('livestream-60-min');
                    }
                }
            }

            // validate startsAt 24h from now
            if (startTime) {
                const currentDate = new Date();
                const tommorowDate = new Date();
                tommorowDate.setDate(currentDate.getDate() + 1);
                if (startTime < tommorowDate.getTime()) {
                    errors.startsAt = t('livestream-24-hours');
                }
            }
        }

        return errors;
    };

    useEffect(() => {
        setTempVideoClip(videoClip);
    }, [videoClip]);

    const formik = useFormik<Omit<ILivestreamDetails, 'videoClip'>>({
        initialValues,
        // Warning: isInitialValid has been deprecated and will be removed in future versions of Formik. Please use initialErrors or validateOnMount instead.
        initialErrors: validate(initialValues),
        // isInitialValid: !(Object.keys(validate(initialValues)).length > 0),
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: () => {
            requestApproval(id, true);
        },
        validate,
    });

    const renderContent = useCallback(
        (activeIndex) => {
            const readonly = !canEdit;

            switch (activeIndex) {
                case LivestreamPageTabs.UPLOAD_VIDEO:
                    return (
                        <UploadLivestreamVideoTab readonly={readonly} id={id} />
                    );
                case LivestreamPageTabs.LIVESTREAM_DETAILS:
                    return <LivestreamDetailsTab readonly={readonly} />;
                case LivestreamPageTabs.PRODUCTS_USED:
                    return <ProductsUsedTab readonly={readonly} />;
                case LivestreamPageTabs.AGENDA:
                    return <AgendaTab readonly={readonly} />;
                default:
                    // TODO: add spinner
                    return <>Loading...</>;
            }
        },
        [canEdit]
    );

    useEffect(() => {
        // cleanup video file from store for specific Livestream when
        // video isn't in process of uploading
        return () => {
            livestreamsStore.checkIfVideoFileExistAndClear('videoClip', id);
        };
    }, []);

    const {
        jwt,
        canStartLivestream,
        canJoinLivestream,
        startLivestream,
        joinLivestream,
    } = useLivestream({ id, ...initialValues }, { forceFetch: true });

    useEffect(() => {
        if (formik.values.stream) {
            const date1: any = formik.values.stream?.started_at;
            const start = new Date(date1);
            const newDate = new Date();
            const now = new Date(newDate.getTime() - 15 * 60000);
            if (now < start) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }
    }, []);

    const livestreamErrorDialog = useCallback(
        (contentText: string, redirect?: IUseRedirect) => {
            setDialogProps({
                openDialog: true,
                contentText,
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={() => {
                                    if (redirect) {
                                        const {
                                            shouldRedirectAfterSuccess,
                                            path,
                                        } = redirect;

                                        shouldRedirectAfterSuccess &&
                                            push(path);
                                    }

                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        []
    );

    const header = useMemo<IPageContainerHeaderProps>(() => {
        let label;

        switch (breadcrumbPath) {
            case ROUTES.MY_LIVESTREAMS:
                label = t('back-to-my-livestreams');
                break;
            case ROUTES.ALL_LIVESTREAMS:
                label = t('back-to-all-livestreams');
                break;
            case ROUTES.PENDING_LIVESTREAMS:
                label = t('back-to-pending-livestreams');
                break;
            default:
                label = t('back-to-livestreams');
                break;
        }

        let actions: IActionProps[] = [];

        switch (initialValues.state) {
            case LiveStreamState.DRAFT:
                actions = !canEdit
                    ? []
                    : !formik.dirty
                    ? [
                          {
                              content:
                                  me?.roles.indexOf(
                                      UserRole.ROLE_AIREX_ADMIN
                                  ) === -1 ? (
                                      <Button
                                          color="primary"
                                          disabled={
                                              !formik.isValid || !tempVideoClip
                                          }
                                          label={t('request-approval')}
                                          onClick={() => formik.submitForm()}
                                      />
                                  ) : (
                                      <Button
                                          color="primary"
                                          disabled={!formik.isValid}
                                          label={t('publish')}
                                          onClick={() =>
                                              approve(id, {
                                                  shouldRedirectAfterSuccess:
                                                      true,
                                                  path: ROUTES.ALL_LIVESTREAMS,
                                              })
                                          }
                                      />
                                  ),
                          },
                      ]
                    : [
                          {
                              content: (
                                  <Button
                                      color="default"
                                      label={t('discard')}
                                      onClick={() =>
                                          formik.setValues(initialValues)
                                      }
                                  />
                              ),
                          },
                          {
                              content: (
                                  <Button
                                      color="primary"
                                      label={t('save')}
                                      onClick={() =>
                                          onSubmit({
                                              ...formik.values,
                                              videoClip: tempVideoClip,
                                          })
                                      }
                                  />
                              ),
                          },
                      ];
                break;
            case LiveStreamState.PENDING:
                actions = !hasPermission(PERMISSIONS.VIDEOS_PENING_APPROVAL)
                    ? []
                    : [
                          {
                              content: (
                                  <Button
                                      color="default"
                                      label={t('decline')}
                                      onClick={() => decline(id)}
                                  />
                              ),
                          },
                          {
                              content: (
                                  <Button
                                      color="primary"
                                      label={t('approve')}
                                      onClick={() => approve(id)}
                                  />
                              ),
                          },
                      ];
                break;
            case LiveStreamState.APPROVED:
                actions = canStartLivestream()
                    ? [
                          {
                              content: (
                                  <Button
                                      color="primary"
                                      label={t('start-livestream')}
                                      onClick={() => {
                                          setOpenDialogStart(true);
                                          canStartLivestream()
                                              ? setOpenDialogStart(true)
                                              : livestreamErrorDialog(
                                                    t('livestream-finished')
                                                );
                                      }}
                                  />
                              ),
                          },
                      ]
                    : jwt
                    ? [
                          {
                              content: (
                                  <Button
                                      color="default"
                                      label={t('join-livestream')}
                                      onClick={() => {
                                          push(
                                              pathBuilder(
                                                  ROUTES.LIVESTREAMS_LIVE,
                                                  ':id',
                                                  id
                                              )
                                          );
                                      }}
                                  />
                              ),
                          },
                      ]
                    : canJoinLivestream()
                    ? [
                          {
                              content: (
                                  <Button
                                      color="default"
                                      label={t('join-livestream')}
                                      onClick={async () => {
                                          const jwt = await joinLivestream();

                                          if (jwt) {
                                              push(
                                                  pathBuilder(
                                                      ROUTES.LIVESTREAMS_LIVE,
                                                      ':id',
                                                      id
                                                  )
                                              );
                                          }
                                      }}
                                  />
                              ),
                          },
                      ]
                    : [];
                break;
        }

        return {
            breadcrumb: {
                path: breadcrumbPath,
                label,
            },
            title: initialValues.title,
            actions,
        };
    }, [
        t,
        me,
        initialValues,
        formik.dirty,
        formik.values,
        formik.isValid,
        tempVideoClip,
        jwt,
    ]);

    return (
        <>
            <PageContainer
                header={header}
                tabs={tabs}
                initialIndex={0}
                renderContent={(activeIndex) => (
                    <FormikProvider value={formik}>
                        {renderContent(activeIndex)}
                    </FormikProvider>
                )}
            />
            <LivestreamVideoDialog
                open={openDialogStart}
                title={t('start-livestream-title')}
                body={t('start-livestream-message')}
                renderButtons={() => (
                    <>
                        <button
                            type="button"
                            onClick={() => setOpenDialogStart(false)}
                            className="cancelButton"
                        >
                            {t('cancel')}
                        </button>
                        <button
                            type="button"
                            onClick={async () => {
                                !formik.values.stream &&
                                    (await startLivestream());
                                push(
                                    pathBuilder(
                                        ROUTES.LIVESTREAMS_LIVE,
                                        ':id',
                                        id
                                    )
                                );
                            }}
                            className="confirmButton"
                        >
                            {t('start-livestream')}
                        </button>
                    </>
                )}
            />
            <RoutePrompt
                blockWhen={formik.dirty}
                dialogText={t('edit-dialog-text')}
            />
        </>
    );
};

export const getLivestreamPage = (
    breadcrumbPath:
        | ROUTES.MY_LIVESTREAMS
        | ROUTES.ALL_LIVESTREAMS
        | ROUTES.PENDING_LIVESTREAMS
): FC =>
    WithProvider(() => {
        const params = useParams<IEntity>();
        const [livestream, saveChanges] = useEntityById<ILivestream>({
            ...params,
            forceFetch: true,
        });

        if (!livestream) {
            return null;
        }

        const { id, videoClip, ...initialValues } = livestream;

        const props: ILivestreamPageProps = {
            id,
            videoClip,
            initialValues,
            onSubmit: (details) => saveChanges({ id, ...details }),
        };

        return <LivestreamPage {...props} breadcrumbPath={breadcrumbPath} />;
    }, livestreamsQuery);
