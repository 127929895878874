import { Box } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { apiService } from 'core/services/apiService';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import {
    createTrainerFromResponse,
    ITrainer,
    ITrainerResponse,
} from 'modules/trainers/models/trainer.model';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CardPlaceholder } from '../CardPlaceholder';
import { TrainerCard } from './TrainerCard';

const numberOfItems = 3;

export const NewestTrainer: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [newestTrainers, setNewestTrainers] = useState<ITrainer[]>([]);

    const renderNewestTrainers = () => {
        return new Array(numberOfItems)
            .fill(null)
            .map((_item, index) => newestTrainers[index] ?? null);
    };

    useEffect(() => {
        apiService
            .get<ITrainerResponse[]>(`trainers/newest/${numberOfItems}`)
            .then(({ data }) =>
                setNewestTrainers(data.map(createTrainerFromResponse))
            );
    }, []);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <h2>{t('newest-trainer')}</h2>
                <Link className="unlinkify" to={ROUTES.TRAINERS}>
                    <Box display="flex" alignItems="center">
                        <Box mr="5px">
                            <b>
                                <p>{t('view-all-trainers-list')}</p>
                            </b>
                        </Box>
                        <ArrowRightAltIcon />
                    </Box>
                </Link>
            </Box>
            <Box display="flex" flexWrap="wrap" style={{ gap: '40px' }}>
                {renderNewestTrainers().map((trainer, index) => {
                    return (
                        <Box key={trainer?.id ?? index}>
                            {!trainer ? (
                                <CardPlaceholder
                                    style={{
                                        height: '250px',
                                        width: '335px',
                                    }}
                                />
                            ) : (
                                <TrainerCard trainer={trainer} />
                            )}
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};
