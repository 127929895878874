import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { Thumbnail } from 'core/components/shared/Thumbnail';
import { ILivestream } from 'modules/livestream/models/livestream.model';
import { livestreamsStore } from 'modules/livestream/state/livestreams.store';
import React, { FC, useContext } from 'react';
import style from './index.module.scss';

export const LivestreamListItemThumbnail: FC<{ livestream: ILivestream }> = ({
    livestream,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );

    return (
        <div
            className={`${style.imageContainer} with-pointer`}
            onClick={async () => {
                const tempLivestream = await livestreamsStore.fetchById(
                    livestream.id
                );

                if (!tempLivestream) {
                    return;
                }

                const { title, videoClip } = tempLivestream;

                patchDialogProps({
                    openDialog: true,
                    title,
                    content: (
                        <video width="100%" controls>
                            {videoClip && (
                                <source src={videoClip} type="video/mp4" />
                            )}
                            Your browser does not support HTML video.
                        </video>
                    ),
                    actions: [
                        {
                            content: (
                                <Button
                                    label={t('close')}
                                    color="primary"
                                    onClick={() => clearDialogProps()}
                                />
                            ),
                        },
                    ],
                    onClose: clearDialogProps,
                });
            }}
        >
            <Thumbnail url={livestream.screen} />
        </div>
    );
};
