import { Box, FormHelperText } from '@material-ui/core';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { PageContainer } from 'core/components/PageContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { useSession } from 'core/hooks/session.hook';
import { IEntity } from 'core/interfaces/entity.interface';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { useTrainer } from 'modules/trainers/hooks/useTrainer.hook';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { fullName } from 'modules/users/models/user.model';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { TrainerDetails } from '../../components/TrainerDetails';
import { ITrainer } from '../../models/trainer.model';
import { trainersQuery } from '../../state/trainers.query';
import './style.scss';

const ViewTrainerPageWithoutProvider: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me } = useSession();
    const { id } = useParams<IEntity>();
    const [trainer] = useEntityById<ITrainer>({ id, forceFetch: true });
    const { patchDialogProps, clearDialogProps, setDialogProps } = useContext(
        AirexDialogContext
    );

    const { activateTrainer, deactivateTrainer } = useTrainer();
    const hasPermission = usePermissions();

    const onActivate = async () => {
        if (await activateTrainer(id)) {
            setDialogProps({
                openDialog: true,
                contentText: t('sucessfully-activated-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={() => {
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                content: (
                    <Box mb="20px">
                        <FormHelperText className="formHelperText error">
                            {t('error')}
                        </FormHelperText>
                    </Box>
                ),
                onClose: clearDialogProps,
            });
        }
    };

    const onDeactivate = async () => {
        if (await deactivateTrainer(id)) {
            setDialogProps({
                openDialog: true,
                contentText: t('sucessfully-deactivated-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={() => {
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                content: (
                    <Box mb="20px">
                        <FormHelperText className="formHelperText error">
                            {t('error')}
                        </FormHelperText>
                    </Box>
                ),
                onClose: clearDialogProps,
            });
        }
    };

    const renderContent = useCallback(
        () =>
            !trainer ? (
                <></>
            ) : (
                <Box paddingTop="32px">
                    <TrainerDetails trainer={trainer} />
                </Box>
            ),
        [trainer]
    );

    const header = useMemo(
        () => ({
            breadcrumb: { path: '/trainers', label: t('back-to-all-trainers') },
            title: !trainer ? '' : fullName(trainer),
            actions: !trainer
                ? []
                : [
                      {
                          hide: !hasPermission(
                              PERMISSIONS.UPDATE_TRAINER_STATE
                          ),
                          content: (
                              <Button
                                  label={
                                      trainer.active
                                          ? t('deactivate')
                                          : t('activate')
                                  }
                                  color="default"
                                  onClick={() => {
                                      patchDialogProps({
                                          openDialog: true,
                                          contentText: trainer.active
                                              ? t(
                                                    'deactivate-trainer-confirmation-text'
                                                )
                                              : t(
                                                    'activate-trainer-confirmation-text'
                                                ),
                                          actions: [
                                              {
                                                  content: (
                                                      <Button
                                                          color="default"
                                                          label={t('cancel')}
                                                          style={{
                                                              width: '184px',
                                                          }}
                                                          onClick={() =>
                                                              clearDialogProps()
                                                          }
                                                      />
                                                  ),
                                              },
                                              {
                                                  content: (
                                                      <Button
                                                          label={
                                                              trainer.active
                                                                  ? t(
                                                                        'deactivate-trainer'
                                                                    )
                                                                  : t(
                                                                        'activate-trainer'
                                                                    )
                                                          }
                                                          color="primary"
                                                          style={{
                                                              width: '184px',
                                                          }}
                                                          onClick={async () => {
                                                              trainer.active
                                                                  ? await onDeactivate()
                                                                  : await onActivate();
                                                          }}
                                                      />
                                                  ),
                                              },
                                          ],
                                          onClose: clearDialogProps,
                                      });
                                  }}
                              />
                          ),
                      },
                      {
                          content: <Button label={t('edit')} color="primary" />,
                          hide: !(
                              hasPermission(PERMISSIONS.EDIT_TRAINER) ||
                              (hasPermission(PERMISSIONS.EDIT_OWN_TRAINER) &&
                                  trainer.createdBy === me?.id)
                          ),
                          path: `/trainers/${trainer.id}/edit`,
                      },
                  ],
        }),
        [t, trainer]
    );

    return <PageContainer header={header} renderContent={renderContent} />;
};

export const ViewTrainerPage = WithProvider(
    ViewTrainerPageWithoutProvider,
    trainersQuery
);
