import { ErrorBoundary } from 'core/components/ErrorBoundary';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { PageContainer } from 'core/components/PageContainer';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { UserRole } from 'modules/users/constants/role-permissions';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { LivestreamList } from '../components/LivestreamList/LivestreamList';
import { PendingLivestreamListActions } from '../components/PendingLivestreamListActions';
import { LiveStreamState } from '../models/livestream.model';
import { livestreamsQuery } from '../state/livestreams.query';

interface PendingLivestreamsTabs {
    title: string;
    role: UserRole | null;
}

export const PendingLivestreamsPageWithoutProvider: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchFilters, getFilter } = useCrud();
    const [initialIndex, setInitialIndex] = useState<number>();

    useEffect(() => {
        patchFilters(
            {
                state: LiveStreamState.PENDING,
                current_user: false,
                order_by: 'createdAt',
                order_direction: 'desc',
            },
            true
        );

        // return () => {
        //     setFilter({ state: null });
        // };
    }, []);

    const tabs: PendingLivestreamsTabs[] = useMemo(
        () => [{ title: t('all-livestreams'), role: null }],
        [t]
    );

    useEffect(() => {
        setInitialIndex(
            tabs.map(({ role }) => role).indexOf(getFilter('role'))
        );
    }, [tabs]);

    const renderContent = useCallback(
        (activeTabIndex?: number) => {
            if (typeof activeTabIndex !== 'undefined') {
                const { role } = tabs[activeTabIndex];

                patchFilters({ role });
            }

            return (
                <ErrorBoundary>
                    <LivestreamList
                        renderActions={(props) => (
                            <PendingLivestreamListActions {...props} />
                        )}
                    />
                </ErrorBoundary>
            );
        },
        [tabs, patchFilters]
    );

    return typeof initialIndex === 'undefined' ? (
        <></>
    ) : (
        <PageContainer
            header={{ title: t('livestream-pending-approval') }}
            tabs={tabs.map(({ title }) => title)}
            initialIndex={initialIndex}
            renderContent={renderContent}
        />
    );
};

export const PendingLivestreamsPage = WithProvider(
    WithCurrentPageListener(PendingLivestreamsPageWithoutProvider),
    livestreamsQuery
);
