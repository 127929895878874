import { Box } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { ActionList } from 'core/components/ActionList';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { IActionProps } from 'core/interfaces/action-props.interface';
import { useLiveStreamState } from 'modules/livestream/hooks/livestream-state.hook';
import { ILivestream } from 'modules/livestream/models/livestream.model';
import React, { FC, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import style from './index.module.scss';

interface PendingLivestreamListActionsProps {
    livestream: ILivestream;
}

export const PendingLivestreamListActions: FC<PendingLivestreamListActionsProps> = ({
    livestream,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { pathname } = useLocation();
    const { approve, decline } = useLiveStreamState(livestream.id, true);

    const actions = useMemo<IActionProps[]>(
        () =>
            [
                {
                    content: <Visibility />,
                    path: `${pathname}/${livestream.id}`,
                },
                {
                    content: (
                        <button
                            className={style.actionButton}
                            onClick={() => decline(livestream.id)}
                        >
                            {t('decline')} <CancelRoundedIcon />
                        </button>
                    ),
                },
                {
                    content: (
                        <button
                            className={style.actionButton}
                            onClick={() => approve(livestream.id)}
                        >
                            {t('approve')} <CheckCircleRoundedIcon />
                        </button>
                    ),
                },
            ].map(({ content, ...rest }) => ({
                ...rest,
                content: (
                    <Box marginTop="10px" marginLeft="22px">
                        {content}
                    </Box>
                ),
            })),
        [t]
    );

    return (
        <Box
            display="flex"
            flex="1 1 auto"
            justifyContent="flex-end"
            flexWrap="wrap"
            maxWidth="300px"
        >
            <ActionList actions={actions} />
        </Box>
    );
};
