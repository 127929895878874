import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useActiveTab } from 'core/hooks/active-tab.hook';
import { useEditMode } from 'core/hooks/edit-mode.hook';
import { useFormik } from 'formik';
import { ICertificate } from 'modules/trainers/models/certificate.model';
import React, { FC, useContext, useEffect, useState } from 'react';
import './style.scss';

export interface CheckedCertificates {
    checkedCertificates: Array<string>;
}
export interface AirexCertificatesData extends CheckedCertificates {
    certificates: Array<ICertificate>;
}
export interface AirexCertificateProps {
    data: AirexCertificatesData;
    onNextClick?: (data: any) => void;
    onCancelClick?: (data?: any) => void;
}

export const AirexCertificate: FC<AirexCertificateProps> = ({
    data,
    onNextClick,
    onCancelClick,
}): JSX.Element => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { activeTab, updateTabsUI, updateValidatedTabIndex } = useActiveTab();
    const { updateEditMode, edit } = useEditMode();
    const [activeTabEdit, setActiveTabEdit] = useState<boolean>(false);

    useEffect(() => {
        if (activeTabEdit) {
            // if tab is edited, set validated tab index to current active tab
            // reset steps
            updateValidatedTabIndex(activeTab);
        }
    }, [activeTabEdit]);

    const formik = useFormik<AirexCertificatesData>({
        initialValues: {
            ...data,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            if (onNextClick) {
                onNextClick(values.checkedCertificates);
            }
            updateTabsUI(activeTab, activeTab + 1);
        },
    });

    const handleChange = (e: any) => {
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }

        formik.handleChange(e);
    };

    const onTabCancel = () => {
        if (edit) {
            if (onCancelClick) {
                onCancelClick();
            }
        }
    };

    const certificatesCheckboxes = () => {
        return (
            <div>
                {data.certificates.map((certificate) => {
                    return (
                        <Box
                            key={certificate.id}
                            display="flex"
                            flexDirection="row"
                            marginBottom="20px"
                        >
                            <FormControl key={certificate.id}>
                                <FormGroup>
                                    <FormControlLabel
                                        key={certificate.id}
                                        className="formControlCheckBoxLabel"
                                        label={certificate.name}
                                        onChange={handleChange}
                                        control={
                                            <Checkbox
                                                name="checkedCertificates"
                                                checked={
                                                    formik.values
                                                        .checkedCertificates[
                                                        formik.values.checkedCertificates.indexOf(
                                                            certificate.id
                                                        )
                                                    ]
                                                        ? true
                                                        : false
                                                }
                                                value={
                                                    formik.values
                                                        .checkedCertificates[
                                                        formik.values.checkedCertificates.indexOf(
                                                            certificate.id
                                                        )
                                                    ] ?? certificate.id
                                                }
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                        </Box>
                    );
                })}
            </div>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="certificateContainer">
                <div className="rowContainer">{certificatesCheckboxes()}</div>

                <div className="buttons">
                    <Button
                        type="button"
                        label={t('cancel')}
                        onClick={onTabCancel}
                        color="default"
                        style={{ width: '184px', height: '40px' }}
                    />
                    <Button
                        type="submit"
                        label={t('complete')}
                        color="primary"
                        style={{ width: '184px', height: '40px' }}
                    />
                </div>
            </div>
        </form>
    );
};
