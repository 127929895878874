import { Box, Tab, Tabs } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { IActionProps } from 'core/interfaces/action-props.interface';
import React, { FC, useState } from 'react';
import { ActionList, ActionListItem } from '../ActionList';
import style from './index.module.scss';

interface IBreadcrumbProps {
    label: string;
}

const Breadcrumb: FC<IBreadcrumbProps> = ({ label }) => {
    return (
        <Box className={style.breadcrumb}>
            <KeyboardBackspaceIcon className={style.breadcrumbIcon} />
            <span className={style.breadcrumbLabel}>{label}</span>
        </Box>
    );
};

const ContentContainer: FC = ({ children }) => {
    return (
        <Box padding="64px" boxShadow="0 2px 37px 0 rgba(178, 178, 178, 0.41)">
            {children}
        </Box>
    );
};

interface IPageBreadcrumb {
    label: string;
    path: string;
}

export interface IPageContainerHeaderProps {
    breadcrumb?: IPageBreadcrumb;
    title: string;
    actions?: IActionProps[];
}

export interface IPageContainerProps {
    header: IPageContainerHeaderProps;
    tabs?: string[];
    initialIndex?: number;
    renderContent: (activeIndex?: number) => JSX.Element;
}

export const PageContainer: FC<IPageContainerProps> = ({
    header: { breadcrumb, title, actions },
    tabs,
    initialIndex,
    renderContent,
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(initialIndex);

    return (
        <Box width={1}>
            <Box padding="10px 78px 100px" height="100vh" overflow="auto">
                <Box paddingLeft="50px" paddingTop="40px">
                    {breadcrumb && (
                        <ActionListItem
                            path={breadcrumb.path}
                            content={<Breadcrumb label={breadcrumb.label} />}
                        />
                    )}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {!breadcrumb ? (
                            <h1>{title}</h1>
                        ) : (
                            <h2 className={style.breadcrumbTitle}>{title}</h2>
                        )}
                        <Box display="flex" justifyContent="flex-end">
                            {actions && <ActionList actions={actions} />}
                        </Box>
                    </Box>
                </Box>
                {!tabs ? (
                    <>
                        <Box height="48px"></Box>
                        <ContentContainer>{renderContent()}</ContentContainer>
                    </>
                ) : (
                    <div className={style.tabsContainer}>
                        <Tabs
                            value={activeTabIndex}
                            onChange={(_e, tabIndex: number) =>
                                setActiveTabIndex(tabIndex)
                            }
                            variant="scrollable"
                        >
                            {tabs.map((tabTitle, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        label={tabTitle}
                                        className={style.tab}
                                    />
                                );
                            })}
                        </Tabs>
                        <ContentContainer>
                            {renderContent(activeTabIndex)}
                        </ContentContainer>
                    </div>
                )}
            </Box>
        </Box>
    );
};
