import { languagesAPIService } from 'core/services/languagesService';
import { useLayoutEffect, useState } from 'react';

export const useAdditionalLanguages = (): any => {
    const [data, setData] = useState();

    useLayoutEffect(() => {
        languagesAPIService
            .getLanguages()
            .then((response) => {
                if (response.data) {
                    setData(response.data);
                }
            })
            .catch((
                e //TODO:Error
            ) => console.log(e));
    }, []);

    return data;
};
