import { IEntity } from '../../../core/interfaces/entity.interface';

export interface IProductDetails {
    category: string;
    name: string;
}

export interface IProduct extends IEntity, IProductDetails {}

export interface IProductResponse extends IEntity<number>, IProductDetails {}

export const createProduct = ({ id, ...props }: Partial<IProduct>) => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        category: '',
        name: '',
        ...props,
    } as IProduct;
};

export const createProductFromResponse = ({
    id,
    ...props
}: IProductResponse): IProduct => {
    return createProduct({
        id: id.toString(),
        ...props,
    });
};
