import React from 'react';
import { sessionQuery, SessionQuery } from '../state/session.query';
import { sessionStore, SessionStore } from '../state/session.store';

interface ISessionContext {
    query: SessionQuery;
    store: SessionStore;
}

export const SessionContext = React.createContext<ISessionContext>({
    query: sessionQuery,
    store: sessionStore,
});
