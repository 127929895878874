import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useErrorDialog } from 'core/hooks/shared/error.hook';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import { apiService } from 'core/services/apiService';
import { isAfter, isBefore } from 'date-fns';
import { useFormikContext } from 'formik';
import { ILivestreamDetails } from 'modules/livestream/models/livestream.model';
import {
    createTakenTimeslotFromResponse,
    ITakenTimeslot,
    ITakenTimeslotResponse,
} from 'modules/livestream/models/takenTimeslot.model';
import React, { FC, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarInput } from './CalendarInput';
import './style.scss';
import { TimeEndInput } from './TimeEndInput';
import { TimeStartInput } from './TimeStartInput';

export const DatePickersComponent: FC<
    IReadonlyProps & { validateTakenTimeslot: boolean }
> = ({ readonly, validateTakenTimeslot }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const formik = useFormikContext<ILivestreamDetails>();
    const [takenTimes, setTakenTimes] = useState<Array<ITakenTimeslot>>();
    const [excludedStartTimes, setExcludedStartTimes] = useState<Date[]>([]);
    const [excludedEndTimes, setExcludedEndTimes] = useState<Date[]>([]);
    const { raiseErrorDialog } = useErrorDialog();

    useEffect(() => {
        if (!readonly) {
            try {
                apiService
                    .get<Array<ITakenTimeslotResponse>>(
                        `/trainings/livestreams/taken-timeslots`
                    )
                    .then(({ data }) => {
                        setTakenTimes(
                            data.map(createTakenTimeslotFromResponse)
                        );
                    });
            } catch (e: any) {
                raiseErrorDialog(e);
            }
        }
    }, [formik.values.startsAt]);

    useEffect(() => {
        getExcludedTimes();
        if (!readonly && validateTakenTimeslot) {
            validateTakenTimeslots();
        }
    }, [takenTimes, validateTakenTimeslot]);

    /**
     * Function for setting taken timeslots
     */
    const getExcludedTimes = () => {
        const takenStartTimesArray: Array<Date> = [];
        const takenEndTimesArray: Array<Date> = [];
        if (takenTimes) {
            takenTimes.map((slot) => {
                // check current time with taken slot
                if (formik.values.startsAt) {
                    const selectedDate = formik.values.startsAt;
                    const slotDateStartsAt = slot.startsAt;
                    const slotDateEndsAt = slot.endsAt;

                    if (
                        selectedDate.getDate() === slotDateStartsAt.getDate() &&
                        selectedDate.getMonth() ===
                            slotDateStartsAt.getMonth() &&
                        selectedDate.getFullYear() ===
                            slotDateStartsAt.getFullYear()
                    ) {
                        takenStartTimesArray.push(slotDateStartsAt);
                        // taken times for start of new livestream are [startsAt, endsAt - 30 min]
                        let takenStartTime =
                            slotDateStartsAt.getTime() + 30 * 60 * 1000;
                        while (
                            isBefore(takenStartTime, slotDateEndsAt.getTime())
                        ) {
                            takenStartTimesArray.push(new Date(takenStartTime));
                            takenStartTime += 30 * 60 * 1000;
                        }

                        // taken times for end of new livestream are [startsAt + 30 min, endsAt]
                        takenEndTimesArray.push(slotDateEndsAt);
                        let takenEndTime =
                            slotDateEndsAt.getTime() - 30 * 60 * 1000;
                        while (
                            isAfter(takenEndTime, slotDateStartsAt.getTime())
                        ) {
                            takenEndTimesArray.push(new Date(takenEndTime));
                            takenEndTime -= 30 * 60 * 1000;
                        }
                    }
                }
            });
        }
        if (takenStartTimesArray.length > 0) {
            setExcludedStartTimes(takenStartTimesArray);
        } else {
            setExcludedStartTimes([]);
        }

        if (takenEndTimesArray.length > 0) {
            setExcludedEndTimes(takenEndTimesArray);
        } else {
            setExcludedEndTimes([]);
        }
    };

    /**
     * Function for validating taken timeslots
     */
    const validateTakenTimeslots = () => {
        if (takenTimes) {
            takenTimes.map((slot) => {
                if (formik.values.startsAt && slot.startsAt) {
                    const currentDate = formik.values.startsAt;
                    const slotDate = slot.startsAt;
                    if (
                        currentDate.getDate() === slotDate.getDate() &&
                        currentDate.getMonth() === slotDate.getMonth() &&
                        currentDate.getFullYear() === slotDate.getFullYear() &&
                        currentDate.getTime() === slotDate.getTime()
                    ) {
                        !formik.errors.startsAt &&
                            formik.setErrors({
                                ...formik.errors,
                                startsAt: t('taken-timeslot-2'),
                            });
                    }
                }
            });
        }
    };

    const calculateTomorrow = () => {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        return tomorrow;
    };

    const handleDateChange = (date: Date | null) => {
        formik.setValues({
            ...formik.values,
            startsAt: date,
            endsAt: date,
        });
    };

    const handleStartChange = (date: Date | null) => {
        formik.setFieldValue('startsAt', date);
    };

    const handleEndChange = (date: Date | null) => {
        formik.setFieldValue('endsAt', date);
    };

    const filterStartPassedTime = (date: Date) => {
        const midnightSelectedDate = new Date(date);

        return midnightSelectedDate.getTime() > calculateTomorrow().getTime();
    };

    const filterEndPassedTime = (date: Date) => {
        const selectedDate = formik.values.startsAt;
        return selectedDate
            ? new Date(date).getTime() > selectedDate.getTime() + 30 * 60 * 60
            : false;
    };

    return (
        <Box display="flex" flexWrap="wrap">
            <Box maxWidth="180px" pt="20px" mr="20px">
                <p className="text">{t('select-livestream-date')}</p>
                <Box mt="10px">
                    <DatePicker
                        calendarClassName="datePicker"
                        selected={formik.values.startsAt}
                        minDate={calculateTomorrow()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date: Date | null) => handleDateChange(date)}
                        customInput={React.createElement(CalendarInput)}
                        disabled={readonly}
                    />
                </Box>
                {formik.errors.startsAt && formik.errors.startsAt !== '' ? (
                    <FormHelperText className="formHelperText error">
                        {formik.errors.startsAt}
                    </FormHelperText>
                ) : (
                    <FormHelperText className="formHelperText">
                        {t('livestream-24-hours')}
                    </FormHelperText>
                )}
            </Box>
            <Box pt="20px" mr="20px">
                <p className="text">{t('select-livestream-beginning')}</p>
                <Box mt="10px">
                    <DatePicker
                        calendarClassName="datePicker"
                        selected={formik.values.startsAt}
                        onChange={(date: Date | null) =>
                            handleStartChange(date)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        customInput={React.createElement(TimeStartInput)}
                        filterTime={filterStartPassedTime}
                        excludeTimes={excludedStartTimes}
                        disabled={readonly}
                        placeholderText="Select"
                    />
                </Box>
            </Box>
            <Box pt="20px">
                <p className="text">{t('select-livestream-ending')}</p>
                <Box mt="10px">
                    <DatePicker
                        calendarClassName="datePicker"
                        selected={formik.values.endsAt}
                        onChange={(date: Date | null) => handleEndChange(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        filterTime={filterEndPassedTime}
                        excludeTimes={excludedEndTimes}
                        customInput={React.createElement(TimeEndInput)}
                        disabled={readonly}
                        placeholderText="Select"
                    />
                </Box>
                {formik.errors.endsAt !== '' && (
                    <FormHelperText className="formHelperText error">
                        {formik.errors.endsAt}
                    </FormHelperText>
                )}
            </Box>
        </Box>
    );
};
