import { IEntity } from '../../../core/interfaces/entity.interface';

export interface ITrainingCategoryDetails {
    name: string;
    // slug: string;
    parentId: string;
    // active: boolean;
}

export interface ITrainingCategory extends IEntity, ITrainingCategoryDetails {}

/**
 * id and parent_id are optinal because BE does not return them when fetching multiple parent items like trainings or livestreams
 */
export interface ITrainingCategoryResponse
    extends Partial<IEntity<number>>,
        Omit<ITrainingCategoryDetails, 'parentId' | 'active'> {
    parent_id?: number;
    // active: number;
}

export const createTrainingCategory = ({
    id,
    ...props
}: Partial<ITrainingCategory>): ITrainingCategory => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        category: '',
        name: '',
        ...props,
    } as ITrainingCategory;
};

export const createTrainingCategoryFromResponse = ({
    id = 0,
    parent_id = 0,
    // active,
    ...props
}: ITrainingCategoryResponse): ITrainingCategory => {
    return createTrainingCategory({
        id: id.toString(),
        parentId: parent_id.toString(),
        // active: !!active,
        ...props,
    });
};
