import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button } from '../Button';
import './style.scss';

export interface ConfirmationDialogProps {
    openDialog: boolean;
    dialogTitle?: string;
    dialogText?: string;
    dialogContent?: ReactNode;
    rightButtonLabel: string;
    leftButtonLabel: string;
    onRightClick?: (data?: any) => void;
    onClose?: (data?: any) => void;
    onLeftClick?: (data?: any) => void;
    closeByDefault?: boolean;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    openDialog,
    dialogTitle,
    dialogText,
    dialogContent,
    rightButtonLabel,
    leftButtonLabel,
    onClose,
    onRightClick,
    onLeftClick,
    closeByDefault = true,
}) => {
    const [open, setOpen] = useState<boolean>(openDialog ? openDialog : false);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const onRightButtonClick = () => {
        if (onRightClick) {
            onRightClick();
        }
        if (closeByDefault) {
            setOpen(false);
        }
    };

    const onLeftButtonClick = () => {
        if (onLeftClick) {
            onLeftClick();
        }
        if (closeByDefault) {
            setOpen(false);
        }
    };

    const onDialogClose = () => {
        if (onClose) {
            onClose();
        }
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={onDialogClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogText && (
                    <DialogContentText>{dialogText}</DialogContentText>
                )}
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <div className="dialogButtons">
                    <Button
                        type="button"
                        onClick={onLeftButtonClick}
                        label={leftButtonLabel}
                        color="default"
                        style={{ width: '184px', height: '40px' }}
                    />
                    <Button
                        type="button"
                        label={rightButtonLabel}
                        onClick={onRightButtonClick}
                        color="primary"
                        style={{ width: '184px', height: '40px' }}
                    />
                </div>
            </DialogActions>
        </Dialog>
    );
};
