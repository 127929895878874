export function shallowEqual(object1: any, object2: any): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (key === 'confirmationDialog') {
            //
        } else if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}
