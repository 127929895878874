import { FC } from 'react';
import { Box } from "@material-ui/core";
import { MostWatchedVideos } from "../components/MostWatchedVideos";
import { NewestTrainer } from "../components/NewestTrainer";
import './style.scss';

export const HomePage: FC = () => {
    return (
        <div className="homePageContainer">
            <NewestTrainer />
            <Box mb="30px" />
            <MostWatchedVideos />
        </div>
    );
};
