import { certificateAPIService } from 'core/services/certificateService';
import {
    createCertificateFromResponse,
    ICertificate,
} from 'modules/trainers/models/certificate.model';
import { useEffect, useState } from 'react';

export const useAirexCertificate = (): Array<ICertificate> => {
    const [data, setData] = useState<Array<ICertificate>>([]);

    useEffect(() => {
        certificateAPIService
            .getCertificates()
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setData(
                        response.data.map((certificate) =>
                            createCertificateFromResponse(certificate)
                        )
                    );
                }
            })
            .catch((
                e //TODO:Error
            ) => console.log(e));
    }, []);

    return data;
};
