import { AxiosResponse } from 'axios';
import { IEntityUIState } from 'core/state/entities.store';
import { IEntity } from '../interfaces/entity.interface';
import { apiService, ResponseHandler } from './apiService';

interface ICrudApiServiceMethodOptions {
    slug?: string;
}

export interface ICrudApiServiceMethodWithBodyOptions
    extends ICrudApiServiceMethodOptions {
    data?: any;
}

interface ICrudApiServiceOptions<T, R> {
    responseMapper: (x: R) => T;
    commandMapper?: (x: Omit<T, 'id'>) => any;
}

interface ICrudApiServiceSingleMethodOptions<T>
    extends ICrudApiServiceMethodOptions {
    responseHandler?: ResponseHandler<T>;
}

interface ICrudApiServiceListMethodOptions<I, O>
    extends ICrudApiServiceMethodOptions {
    responseHandler: ResponseHandler<I, O>;
}

export class CrudApiService<T extends IEntity, R> {
    protected _responseMapper: (x: R) => T;
    protected _commandMapper: (x: Omit<T, 'id'>) => any = (x) => x;

    constructor(
        protected readonly _slug: string,
        { responseMapper, commandMapper }: ICrudApiServiceOptions<T, R>
    ) {
        this._responseMapper = responseMapper;

        if (commandMapper) {
            this._commandMapper = commandMapper;
        }
    }

    async create(
        entity: Omit<T, 'id'>,
        {
            responseHandler = apiService.responseHandler,
            slug = this._slug,
        }: ICrudApiServiceSingleMethodOptions<R> = {
            responseHandler: apiService.responseHandler,
            slug: this._slug,
        }
    ): Promise<T> {
        const response = await apiService.post<R>(
            slug,
            this._commandMapper(entity)
        );
        return this._responseMapper(responseHandler(response));
    }

    async update(
        { id, ...entity }: T,
        {
            responseHandler = apiService.responseHandler,
            slug = this._slug,
        }: ICrudApiServiceSingleMethodOptions<R> = {
            responseHandler: apiService.responseHandler,
            slug: this._slug,
        }
    ): Promise<T> {
        const response = await apiService.put<R, Omit<T, 'id'>>(
            `${slug}/${id}`,
            this._commandMapper(entity)
        );
        return this._responseMapper(responseHandler(response));
    }

    async readAll<RA = R[]>(
        { searchTerm, pageNumber, pageSize, ...rest }: IEntityUIState,
        {
            responseHandler,
            slug = this._slug,
        }: ICrudApiServiceListMethodOptions<RA, R[]>
    ): Promise<T[]> {
        let params: any = { ...rest };

        // if (searchTerm) {
        //     params = {
        //         ...params,
        //         firstName: searchTerm,
        //         lastName: searchTerm,
        //         email: searchTerm,
        //     };
        // }

        if (pageNumber) {
            params = { ...params, page: pageNumber };
        }

        if (pageSize) {
            params = { ...params, limit: pageSize };
        }

        const response = await apiService.get<RA>(`${slug}`, {
            params,
        });

        return responseHandler(response).map(this._responseMapper);
    }

    async readAllV2<RA = R[]>(
        { searchTerm, pageNumber, pageSize, ...rest }: IEntityUIState,
        {
            responseHandler,
            slug = this._slug,
        }: ICrudApiServiceListMethodOptions<RA, R[]>
    ): Promise<T[]> {
        let params: any = { ...rest };

        if (pageNumber) {
            params = { ...params, page: pageNumber };
        }

        if (pageSize) {
            params = { ...params, limit: pageSize };
        }

        const response = await apiService.getV2<RA>(`${slug}`, {
            params,
        });

        return responseHandler(response).map(this._responseMapper);
    }

    async read(
        id: string,
        {
            responseHandler = apiService.responseHandler,
            slug = this._slug,
        }: ICrudApiServiceSingleMethodOptions<R> = {
            responseHandler: apiService.responseHandler,
            slug: this._slug,
        }
    ): Promise<T> {
        const response = await apiService.get<R>(`${slug}/${id}`);
        return this._responseMapper(responseHandler(response));
    }

    deleteById(
        id: string,
        { slug = this._slug }: ICrudApiServiceMethodOptions = {
            slug: this._slug,
        }
    ): Promise<AxiosResponse> {
        return apiService.delete(`${slug}/${id}`);
    }

    updateState(
        id: string,
        state: string,
        {
            slug = this._slug,
            data = null,
        }: ICrudApiServiceMethodWithBodyOptions = {
            slug: this._slug,
            data: null,
        }
    ): Promise<AxiosResponse> {
        return apiService.put(`${slug}/${id}/${state}`, data);
    }
}
