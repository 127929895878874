import { useObservable } from '@mindspace-io/react';
import { useContext } from 'react';
import { Observable } from 'rxjs';
import { EntitiesContext } from '../constants/entities.context';
import { IEntity } from '../interfaces/entity.interface';

export interface IUseCurrentPageEntities<T> {
    currentPageEntities: T[];
}

export const useCurrentPageEntities = <
    T extends IEntity
>(): IUseCurrentPageEntities<T> => {
    const { query } = useContext(EntitiesContext);

    const { entities, currentPageIds } = query.getValue();

    const [currentPageEntities] = useObservable(
        query.currentPageEntities$ as Observable<T[]>,
        currentPageIds.map((id) => entities?.[id] ?? null) as T[]
    );

    return {
        currentPageEntities,
    };
};
