import { Box } from '@material-ui/core';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useErrorDialog } from 'core/hooks/shared/error.hook';
import { updateState } from 'core/services/updateState';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    ITraining,
    ITrainingResponse,
    TrainingState,
} from '../models/training.model';
import { trainingsStore } from '../state/trainings.store';

interface IUseRedirect {
    shouldRedirectAfterSuccess: boolean;
    path: string;
}

interface IUseTrainingState {
    requestApproval: (id: string) => void;
    approve: (
        id: string,
        redirect?: IUseRedirect | undefined,
        fromLivestream?: boolean | undefined
    ) => void;
    decline: (id: string) => void;
    draft: (id: string) => void;
}

export const useTrainingState = (
    shouldFetchAfterSuccess = false
): IUseTrainingState => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchDialogProps, setDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );
    const { push } = useHistory();

    const { raiseErrorDialog } = useErrorDialog();

    const _requestApproval = updateState<ITraining, ITrainingResponse>({
        store: trainingsStore,
        state: TrainingState.PENDING,
    });

    const _approve = updateState<ITraining, ITrainingResponse>({
        store: trainingsStore,
        state: TrainingState.APPROVED,
    });

    const _move_to_draft = updateState<ITraining, ITrainingResponse>({
        store: trainingsStore,
        state: TrainingState.DRAFT,
    });

    const _decline = updateState<ITraining, ITrainingResponse>({
        store: trainingsStore,
        state: TrainingState.DECLINED,
    });

    const onSuccess = useCallback(
        (
            contentText: string,
            content: JSX.Element,
            redirect?: IUseRedirect
        ) => {
            setDialogProps({
                openDialog: true,
                contentText,
                content,
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('close')}
                                onClick={() => {
                                    if (redirect) {
                                        const {
                                            shouldRedirectAfterSuccess,
                                            path,
                                        } = redirect;

                                        shouldRedirectAfterSuccess &&
                                            push(path);
                                    }

                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t]
    );

    const requestApproval = useCallback(
        (id: string) => {
            setDialogProps({
                openDialog: true,
                contentText: t('request-approval-training-confirmation-text'),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('request-approval')}
                                onClick={async () => {
                                    try {
                                        await _requestApproval(id);
                                        onSuccess(
                                            '',
                                            <>
                                                {t(
                                                    'request-approval-training-success-text'
                                                )}
                                            </>
                                        );
                                    } catch (e) {
                                        raiseErrorDialog(
                                            t(
                                                'request-approval-training-failure-text'
                                            )
                                        );
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess]
    );

    const approve = useCallback(
        (
            id: string,
            redirect?: IUseRedirect | undefined,
            fromLivestream?: boolean | undefined
        ) => {
            setDialogProps({
                openDialog: true,
                contentText: fromLivestream
                    ? t('approve-training-from-livestream-confirmation-text')
                    : t('approve-training-confirmation-text'),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                label={
                                    fromLivestream
                                        ? t('publish')
                                        : t('approve-training')
                                }
                                onClick={async () => {
                                    try {
                                        await _approve(id, {
                                            shouldFetchAfterSuccess,
                                        });
                                        onSuccess(
                                            !fromLivestream
                                                ? t(
                                                      'approve-training-success-title'
                                                  )
                                                : '',
                                            <>
                                                {fromLivestream
                                                    ? t(
                                                          'successfully-published-video'
                                                      )
                                                    : t(
                                                          'approve-training-success-text'
                                                      )}
                                            </>,
                                            redirect
                                        );
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess]
    );

    const decline = useCallback(
        (id: string) => {
            setDialogProps({
                openDialog: true,
                contentText: t('decline-training-confirmation-text'),
                // eslint-disable-next-line react/display-name
                renderContent: (data) => (
                    <Box display="flex" flexDirection="column" flex="1 1 auto">
                        <p className="text">{t('decline-reason')}</p>
                        <Box pb="5px" />
                        <textarea
                            className="textareaInput"
                            maxLength={1000}
                            spellCheck={false}
                            name="reason"
                            value={data}
                            onChange={(e) =>
                                patchDialogProps({ data: e.target.value })
                            }
                        />
                    </Box>
                ),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        // eslint-disable-next-line react/display-name
                        renderContent: (data) => (
                            <Button
                                color="primary"
                                disabled={!data}
                                label={t('decline-training')}
                                onClick={async () => {
                                    try {
                                        await _decline(id, {
                                            shouldFetchAfterSuccess,
                                            data: {
                                                decline_reason: data,
                                            },
                                        });
                                        onSuccess(
                                            '',
                                            <>
                                                {t(
                                                    'decline-training-success-text'
                                                )}
                                            </>
                                        );
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess]
    );

    const draft = useCallback(
        (id: string) => {
            setDialogProps({
                openDialog: true,
                contentText: t('move-to-draft'),
                // eslint-disable-next-line react/display-name
                renderContent: (data) => (
                    <Box textAlign="center">
                        <p>{t('move-to-draft-subtitle')}</p>
                    </Box>
                ),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                label={t('move-training')}
                                onClick={async () => {
                                    try {
                                        await _move_to_draft(id, {
                                            shouldFetchAfterSuccess,
                                        });
                                        onSuccess(
                                            t('move-training-success-title'),
                                            <>
                                                {t(
                                                    'move-training-success-text'
                                                )}
                                            </>
                                        );
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        },
        [t, onSuccess]
    );

    return {
        requestApproval,
        approve,
        decline,
        draft,
    };
};
