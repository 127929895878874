import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createTrainingCategoryFromResponse,
    ITrainingCategory,
    ITrainingCategoryResponse,
} from '../models/training-category.model';

export type TrainingCategoriesState = EntitiesState<ITrainingCategory>;

@StoreConfig({
    name: 'categories',
    resettable: true,
})
class TrainingCategoriesStore extends EntitiesStore<
    ITrainingCategory,
    ITrainingCategoryResponse,
    ITrainingCategoryResponse[]
> {}

export const trainingCategoriesStore = new TrainingCategoriesStore({
    responseMapper: createTrainingCategoryFromResponse,
    listResponseMapper: () => (results) => results,
});
