import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { Thumbnail } from 'core/components/shared/Thumbnail';
import { ITraining } from 'modules/trainings/models/training.model';
import { trainingsStore } from 'modules/trainings/state/trainings.store';
import React, { FC, useContext } from 'react';
import style from './index.module.scss';

export const TrainingListItemThumbnail: FC<{ training: ITraining }> = ({
    training,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );

    return (
        <div
            className={`${style.imageContainer} with-pointer`}
            onClick={async () => {
                const tempTraining = await trainingsStore.fetchById(
                    training.id
                );

                if (!tempTraining) {
                    return;
                }

                const { title, video } = tempTraining;

                patchDialogProps({
                    openDialog: true,
                    title,
                    content: (
                        <video width="100%" controls>
                            <source src={video} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    ),
                    actions: [
                        {
                            content: (
                                <Button
                                    label={t('close')}
                                    color="primary"
                                    onClick={() => clearDialogProps()}
                                />
                            ),
                        },
                    ],
                });
            }}
        >
            <Thumbnail url={training.screen} />
        </div>
    );
};
