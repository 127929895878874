import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect } from 'react';
import './style.scss';

export interface RadioButtonProps<R> {
    value: R;
    label: string;
}

export interface RadioButtonsGroupProps<T, R> {
    name: keyof T;
    checkedValue?: string;
    groupLabel: string;
    items: Array<RadioButtonProps<R>>;
    onChange?: (event: any) => void;
}

export const RadioButtonsGroup = <T, R>({
    name,
    checkedValue,
    groupLabel,
    onChange,
    items,
}: RadioButtonsGroupProps<T, R>): JSX.Element => {
    const [value, setValue] = React.useState(checkedValue ?? items[0].value);

    useEffect(() => {
        if (checkedValue && checkedValue !== value) {
            setValue(checkedValue);
        }
    }, [checkedValue]);

    const handleChange = (event: any) => {
        if (onChange) {
            onChange(event);
        }
        setValue(event.target.value);
    };

    return (
        <FormControl
            required
            style={{ display: 'flex' }}
            className="radioButtonsControl"
        >
            <FormLabel className="radioGroupLabel">{groupLabel}</FormLabel>
            <RadioGroup
                name={(name as unknown) as string}
                value={value}
                onChange={handleChange}
            >
                {items.map(({ label, value }) => {
                    return (
                        <FormControlLabel
                            key={label}
                            control={<Radio onChange={handleChange} />}
                            label={label}
                            value={value}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};
