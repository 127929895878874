import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { IActionProps } from 'core/interfaces/action-props.interface';
import { FC, useEffect, useMemo, useState } from 'react';
import { ActionList } from '../ActionList';
import defaultStyle from './index.module.scss';

export interface AirexDialogProps {
    style: {
        paper?: string;
        title?: string;
        contentText?: string;
        content?: string;
        actions?: string;
    };
    title?: string;
    contentText?: string;
    content?: JSX.Element;
    renderContent?: (data: any) => JSX.Element;
    actions: IActionProps[];
    openDialog: boolean;
    onClose?: (data?: any) => Promise<void> | void;
    data?: any;
}

export const AirexDialog: FC<AirexDialogProps> = ({
    openDialog,
    style,
    title,
    contentText,
    content,
    renderContent,
    actions,
    onClose,
    data,
}) => {
    const [open, setOpen] = useState<boolean>(openDialog ?? false);

    const calculatedStyle = useMemo(() => ({ ...defaultStyle, ...style }), [
        style,
    ]);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const onDialogClose = () => {
        if (onClose) {
            onClose();
        }
        setOpen(false);
    };

    return (
        <Dialog
            classes={{ paper: calculatedStyle.paper }}
            open={open}
            onClose={onDialogClose}
        >
            {title && (
                <DialogTitle
                    disableTypography={true}
                    classes={{ root: calculatedStyle.title }}
                >
                    {title}
                </DialogTitle>
            )}
            <DialogContent classes={{ root: calculatedStyle.content }}>
                {contentText && (
                    <DialogContentText
                        classes={{ root: calculatedStyle.contentText }}
                    >
                        {contentText}
                    </DialogContentText>
                )}
                {renderContent ? renderContent(data) : content}
            </DialogContent>
            {actions && (
                <DialogActions classes={{ root: calculatedStyle.actions }}>
                    <ActionList actions={actions} data={data} />
                </DialogActions>
            )}
        </Dialog>
    );
};
