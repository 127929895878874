import React, { FC, useContext, CSSProperties } from 'react';
import { Box } from "@material-ui/core";
import { MultiLanguageSupportContext } from "core/components/MultiLanguageSupportProvider";
import './style.scss';

export interface CardPlaceholderProps {
    style?: CSSProperties;
}

export const CardPlaceholder: FC<CardPlaceholderProps> = ({ style }) => {
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <Box
            className="cardPlaceholderContainer"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={style}
        >
            <p className="text emptySpace">
                {t('card-placeholder-empty-space-text')}
            </p>
            <p className="text content">
                {t('card-placeholder-content-text')}
            </p>
        </Box>
    );
}
