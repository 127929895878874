import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { PageContainer } from 'core/components/PageContainer';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { UserRole } from 'modules/users/constants/role-permissions';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { PendingTrainingListActions } from '../components/PendingTrainingListActions';
import { TrainingList } from '../components/TrainingList';
import { TrainingState } from '../models/training.model';
import { trainingsQuery } from '../state/trainings.query';

interface PendingTrainingsTabs {
    title: string;
    role: UserRole | null;
}

export const PendingTrainingsPageWithoutProvider: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchFilters, getFilter } = useCrud();
    const [initialIndex, setInitialIndex] = useState<number>();

    useEffect(() => {
        patchFilters(
            {
                state: TrainingState.PENDING,
                current_user: false,
                order_by: 'createdAt',
                order_direction: 'desc',
            },
            true
        );

        // return () => {
        //     setFilter({ state: null });
        // };
    }, []);

    const tabs: PendingTrainingsTabs[] = useMemo(
        () => [
            { title: t('all-videos'), role: null },
            { title: t('master-trainers'), role: UserRole.ROLE_MASTER_TRAINER },
            { title: t('basic-trainer'), role: UserRole.ROLE_TRAINER },
        ],
        [t]
    );

    useEffect(() => {
        setInitialIndex(
            tabs.map(({ role }) => role).indexOf(getFilter('role'))
        );
    }, [tabs]);

    const renderContent = useCallback(
        (activeTabIndex?: number) => {
            if (typeof activeTabIndex !== 'undefined') {
                const { role } = tabs[activeTabIndex];

                patchFilters({ role });
            }

            return (
                <TrainingList
                    renderActions={(props) => (
                        <PendingTrainingListActions {...props} />
                    )}
                />
            );
        },
        [tabs, patchFilters]
    );

    return typeof initialIndex === 'undefined' ? (
        <></>
    ) : (
        <PageContainer
            header={{ title: t('videos-pending-approval') }}
            tabs={tabs.map(({ title }) => title)}
            initialIndex={initialIndex}
            renderContent={renderContent}
        />
    );
};

export const PendingTrainingsPage = WithProvider(
    WithCurrentPageListener(PendingTrainingsPageWithoutProvider),
    trainingsQuery
);
