import WallpaperIcon from '@material-ui/icons/Wallpaper';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { MultiLanguageSupportContext } from '../MultiLanguageSupportProvider';
import { Thumbnail } from '../shared/Thumbnail';
import './style.scss';

export interface ImageUploadProps {
    url?: string;
    currentFile?: File | string;
    onChange?: (data: any) => void;
    renderSupportedFile: () => JSX.Element;
}

export const ImageUpload: FC<ImageUploadProps> = ({
    onChange,
    currentFile,
    renderSupportedFile,
}): JSX.Element => {
    const [file, setFile] = useState<any>(currentFile ? currentFile : null);
    const [error, setError] = useState<string>('');
    const fileInput = useRef<HTMLInputElement>(null);
    const { t } = useContext(MultiLanguageSupportContext);
    const [preview, setPreview] = useState<string | ArrayBuffer | undefined>();

    useEffect(() => {
        if (currentFile && typeof currentFile !== 'string') {
            setFile(currentFile);
            setPreview(URL.createObjectURL(currentFile));
        } else if (typeof currentFile === 'string') {
            setPreview(currentFile);
        }
    }, [currentFile]);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        const file: File | null = files?.length ? files[0] : null;
        if (file) {
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                setError(t('not-supported-file-type'));
                return;
            }
            if (file.size > 2097152) {
                setError(t('image-size-error'));
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result) {
                    setPreview(reader.result);
                }
            };
            reader.readAsDataURL(file);
            setFile(file);
            if (onChange) {
                onChange(file);
            }
        }
    };

    const triggerInputFile = () => {
        fileInput.current?.click();
        onRemove();
    };

    const onRemove = () => {
        setFile(undefined);
        setPreview(undefined);
        setError('');
        if (onChange) {
            onChange(null);
        }
    };
    return (
        <div className="imageUploadContainer">
            <div className="choose-file-container" onClick={triggerInputFile}>
                {preview && <Thumbnail url={preview as string} />}
                <div className="choose-file-text">
                    {!preview && (
                        <WallpaperIcon color="inherit" fontSize="large" />
                    )}
                    <p>{!preview && t('browse-image')}</p>
                </div>
            </div>

            <input
                type="file"
                id="image-upload"
                ref={fileInput}
                accept=".png, .jpg, .jpeg"
                value=""
                onChange={onFileChange}
                hidden
            />
            <p className="error">{error}</p>

            <div className="imageUploadButtons">
                <button
                    disabled={!file}
                    onClick={onRemove}
                    className={file ? 'enabled' : 'disabled'}
                >
                    {t('remove-image')}
                </button>
            </div>
            <div className="supportedFile">{renderSupportedFile()}</div>
        </div>
    );
};
