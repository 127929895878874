import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Close, Mic, MicOff, PeopleAlt, Visibility } from '@material-ui/icons';
import { ChatMessage, Participant, VideoQuality } from '@zoom/videosdk';
import clsx from 'clsx';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import ZoomClientContext from 'core/components/ZoomClientContext';
import useWindowDimensions from 'core/hooks/useWindowDimensions';
import { useParticipantsChange } from 'modules/livestream/hooks/participants-change.hook';
import { UserRole } from 'modules/users/constants/role-permissions';
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ParticipantListItem from './ParticipantListItem';

const drawerWidth = 340;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#000',
            zIndex: 2,
            position: 'fixed',
            left: 0,
            top: 0,
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: drawerWidth,
        },
        title: {
            flexGrow: 1,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
            ...theme.mixins.toolbar,
            justifyContent: 'space-between',
        },
        drawerHeaderTitle: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
        },
        drawerHeaderIcon: {
            fontSize: 20,
            marginRight: 10,
        },
        drawerHeaderClose: {
            fontSize: 20,
        },
        content: {
            flexGrow: 1,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginRight: -drawerWidth,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        lsHeader: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '64px',
            color: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            zIndex: 99,
            backgroundColor: '#000',
        },
        video: {
            width: '100%',
            height: 'calc(100vh - 10px)',
            background: '#222',
        },
        canvaVideo: {
            width: '100vw',
            height: '100vh',
            background: '#222',
        },
        lsTitle: {
            fontSize: 18,
        },
        stop: {
            fontSize: 18,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        back: {
            marginRight: 10,
        },
        right: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
        },
        eye: {
            marginRight: 10,
            fontSize: 16,
        },
        no: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 20,
            height: 40,
            width: 60,
            background: '#333',
            lineHeight: 1,
        },
        participints: {
            height: 40,
            width: 225,
            backgroundColor: '#CE2E26',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: 'bold',
            cursor: 'pointer',
        },
        participintsAlowed: {
            height: 40,
            width: 225,
            backgroundColor: '#706F6F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: 'bold',
        },
        iconBox: {
            width: 50,
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            background: '#222',
            marginLeft: 10,
            marginRight: 10,
            cursor: 'pointer',
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '64px',
            width: '100%',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 99,
            backgroundColor: ' #000',
        },
        footerShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: drawerWidth,
        },
        handleMicForAllParticipantsContainer: {
            marginLeft: 20,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'column',
        },
        sideBox: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: `calc(100vh - 100px)`,
            justifyContent: 'space-between',
        },
        canvasVideo: {
            width: '100%',
            height: 'calc(100vh - 10px)',
            background: '#222',
        },
    })
);

interface ILivestreamVideoCompProps {
    livestreamName: string;
    canHandleMic?: boolean;
    renderBackButton: () => JSX.Element;
    renderActionButton: () => JSX.Element;
    onVideoDecodeReady?: () => Promise<void>;
}

const LivestreamVideoComp: FC<ILivestreamVideoCompProps> = ({
    livestreamName,
    canHandleMic = false,
    renderBackButton,
    renderActionButton,
    onVideoDecodeReady,
    children,
}) => {
    const zmClient = useContext(ZoomClientContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();

    const sessionInfo = zmClient.getSessionInfo();

    const [amIMuted, setAmIMuted] = useState<boolean | null>(
        zmClient.getMediaStream().isAudioMuted()
    );
    const handleMic = async (
        unmute: boolean,
        userId: number = sessionInfo.userId
    ) => {
        try {
            const mediaStream = zmClient.getMediaStream();

            if (sessionInfo.userId === userId) {
                if (unmute) {
                    await mediaStream.unmuteAudio().catch((e) => {
                        console.log('Unmute error', e);
                    });
                } else {
                    await mediaStream.muteAudio().catch((e) => {
                        console.log('Mute error', e);
                    });
                }

                setAmIMuted(!unmute); // mediaStream.isAudioMuted()
            } else {
                const chatClient = zmClient.getChatClient();

                await chatClient.send(unmute ? 'UNMUTE' : 'MUTE', userId);

                if (unmute) {
                    setIdsOfParticipantsWithRaisedHand((ids) =>
                        ids.filter((id) => id !== userId)
                    );
                } else {
                    // this is a fail safe
                    await mediaStream.muteAudio(userId).catch((e) => {
                        console.log('Mute error', e);
                    });
                }
            }
        } catch (e) {
            console.log('Handle mic error', e);
        }
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    const handleDrawerToggle = () => {
        setIsDrawerOpen((state) => !state);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const [
        idsOfParticipantsWithRaisedHand,
        setIdsOfParticipantsWithRaisedHand,
    ] = useState<number[]>([]);

    const [participants, setParticipants] = useState<Participant[]>([]);

    const participantsWithoutAdmins = participants.filter(
        ({ displayName }) => displayName !== UserRole.ROLE_AIREX_ADMIN
    );

    const participantsWithoutAdminsAndManagers = participantsWithoutAdmins.filter(
        ({ isManager }) => !isManager
    );

    const participantsWithoutAdminsManagersAndHost = participantsWithoutAdminsAndManagers.filter(
        ({ isHost }) => !isHost
    );

    useParticipantsChange(zmClient, setParticipants);

    function onHandRaiseChange(senderId: number, isHandRaised: boolean) {
        setIdsOfParticipantsWithRaisedHand((ids) =>
            isHandRaised
                ? [...ids, senderId]
                : ids.filter((id) => id !== senderId)
        );
    }

    const onChatMessage = async ({ sender, message }: ChatMessage) => {
        switch (message) {
            case 'RAISED_HAND':
                onHandRaiseChange(sender.userId, true);
                break;
            case 'UNRAISED_HAND':
                onHandRaiseChange(sender.userId, false);
                break;
        }
    };

    useEffect(() => {
        zmClient.on('chat-on-message', onChatMessage);
        zmClient.getChatClient().sendToAll('HAND_RAISE_CHECK');
    }, []);

    const handleMicForAllParticipants = async (unmute: boolean) => {
        participantsWithoutAdminsManagersAndHost.map(({ userId }) =>
            handleMic(unmute, userId)
        );
    };

    const videoRef = useRef<HTMLCanvasElement | null>(null);
    const canvasDimension = useWindowDimensions();

    const previousActiveUser = useRef<Participant>();

    const [activeVideoId, setActiveVideoId] = useState<number>(
        zmClient.getMediaStream().getActiveVideoId()
    );

    const onActiveVideoChange = useCallback(({ userId, state }) => {
        state === 'Active' && setActiveVideoId(userId);
    }, []);

    useEffect(() => {
        try {
            zmClient.on('video-active-change', onActiveVideoChange);
        } catch (e) {
            console.log('On events', e);
        }

        return () => {
            try {
                zmClient.off('video-active-change', onActiveVideoChange);
            } catch (e) {
                console.log('On events', e);
            }
        };
    }, [zmClient, onActiveVideoChange]);

    const activeUser = useMemo(
        () => participants.find((user) => user.userId === activeVideoId),
        [participants, activeVideoId]
    );

    const [isVideoDecodeReady, setIsVideoDecodeReady] = useState(false);

    const onMediaSDKChange = useCallback(async ({ action, type, result }) => {
        if (action === 'decode' && type === 'video' && result === 'success') {
            onVideoDecodeReady && (await onVideoDecodeReady());
            setIsVideoDecodeReady(true);
        }
    }, []);

    useEffect(() => {
        try {
            zmClient.on('media-sdk-change', onMediaSDKChange);
        } catch (e) {
            console.log('Off events', e);
        }

        return () => {
            try {
                zmClient.off('media-sdk-change', onMediaSDKChange);
            } catch (e) {
                console.log('Off events', e);
            }
        };
    }, [zmClient, onMediaSDKChange]);

    useEffect(() => {
        const renderVideo = async () => {
            try {
                const mediaStream = zmClient.getMediaStream();

                console.log('activeUserId', activeUser?.userId);
                console.log('currentUserId', zmClient.getSessionInfo().userId);
                console.log(
                    'hostUserId',
                    zmClient.getAllUser().find(({ isHost }) => isHost)?.userId
                );
                console.log(
                    'managerUserIds',
                    zmClient
                        .getAllUser()
                        .filter(({ isManager }) => isManager)
                        .map(({ userId }) => userId)
                );

                if (mediaStream && videoRef.current && isVideoDecodeReady) {
                    const tempUser =
                        zmClient.isHost() ||
                        zmClient.getUser(zmClient.getSessionInfo().userId)
                            ?.isManager
                            ? zmClient
                                  .getAllUser()
                                  .find(
                                      ({ userId }) =>
                                          zmClient.getSessionInfo().userId ===
                                          userId
                                  )
                            : activeUser;

                    const { bVideoOn, displayName, userId } =
                        tempUser ?? ({} as Participant);

                    if (
                        !bVideoOn ||
                        !displayName.includes(t('airex-trainer')) ||
                        userId === previousActiveUser.current?.userId
                    ) {
                        return;
                    }

                    if (previousActiveUser.current?.bVideoOn) {
                        await mediaStream
                            .stopRenderVideo(
                                videoRef.current,
                                previousActiveUser.current.userId
                            )
                            .catch((e) => {
                                console.log('Stop render video', e);
                            });
                    }
                    if (mediaStream.isSupportHDVideo()) {
                        // console.log('Support HD VIDEO!');
                        await mediaStream
                            .renderVideo(
                                videoRef.current,
                                userId,
                                canvasDimension.width,
                                canvasDimension.height,
                                0,
                                0,
                                VideoQuality.Video_360P
                            )
                            .catch((e) => {
                                console.log('Render video', e);
                            });
                    } else {
                        await mediaStream
                            .renderVideo(
                                videoRef.current,
                                userId,
                                canvasDimension.width,
                                canvasDimension.height,
                                0,
                                0,
                                VideoQuality.Video_360P
                            )
                            .catch((e) => {
                                console.log('Render video', e);
                            });
                    }

                    previousActiveUser.current = tempUser;
                }
            } catch (e) {
                console.log('Init render video', e);
            }
        };

        renderVideo();
    }, [activeUser?.userId, isVideoDecodeReady, canvasDimension]);

    console.log('isHost', zmClient.isHost());

    return (
        <div className={classes.root} id="livestream-video-comp-root">
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: isDrawerOpen,
                })}
            >
                <div className={classes.lsHeader}>
                    {renderBackButton()}
                    <div className={classes.lsTitle}>{livestreamName}</div>
                    <div className={classes.right}>
                        <div className={classes.no}>
                            <Visibility className={classes.eye} />
                            <span>
                                {participantsWithoutAdminsAndManagers?.length}
                            </span>
                        </div>
                        {renderActionButton()}
                    </div>
                </div>
            </AppBar>
            <div
                className={clsx(classes.content, {
                    [classes.contentShift]: isDrawerOpen,
                })}
            >
                {children || (
                    <canvas
                        className={classes.canvasVideo}
                        {...canvasDimension}
                        ref={videoRef}
                    />
                )}
                <div
                    className={clsx(classes.footer, {
                        [classes.footerShift]: isDrawerOpen,
                    })}
                >
                    {canHandleMic && amIMuted !== null && (
                        <div className={classes.iconBox}>
                            {amIMuted ? (
                                <MicOff onClick={() => handleMic(true)} />
                            ) : (
                                <Mic onClick={() => handleMic(false)} />
                            )}
                        </div>
                    )}
                    <div className={classes.iconBox}>
                        <PeopleAlt onClick={handleDrawerToggle} />
                    </div>
                </div>
            </div>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={isDrawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <div className={classes.drawerHeaderTitle}>
                        <PeopleAlt className={classes.drawerHeaderIcon} />
                        <span>
                            {t('participants')}{' '}
                            <span>
                                ({participantsWithoutAdminsAndManagers.length})
                            </span>
                        </span>
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        <Close className={classes.drawerHeaderClose} />
                    </IconButton>
                </div>
                <Divider />
                <div className={classes.sideBox}>
                    <List>
                        {participantsWithoutAdminsAndManagers.map(
                            (zoomParticipant, index) => (
                                <ParticipantListItem
                                    key={zoomParticipant.userId}
                                    index={index}
                                    {...zoomParticipant}
                                    isHandRaised={idsOfParticipantsWithRaisedHand.includes(
                                        zoomParticipant.userId
                                    )}
                                    hideActions={
                                        !canHandleMic ||
                                        zoomParticipant.isHost ||
                                        zoomParticipant.isManager
                                    }
                                    onMicOn={(userId) =>
                                        handleMic(true, userId)
                                    }
                                    onMicOff={(userId) =>
                                        handleMic(false, userId)
                                    }
                                />
                            )
                        )}
                    </List>
                    {canHandleMic && (
                        <div
                            className={
                                classes.handleMicForAllParticipantsContainer
                            }
                        >
                            <Button
                                color="primary"
                                disabled={
                                    !participantsWithoutAdminsManagersAndHost.length
                                }
                                label={t('mute-all-participants')}
                                onClick={() =>
                                    handleMicForAllParticipants(false)
                                }
                            />

                            <Button
                                color="primary"
                                disabled={
                                    !participantsWithoutAdminsManagersAndHost.length
                                }
                                label={t('unmute-all-participants')}
                                onClick={() =>
                                    handleMicForAllParticipants(true)
                                }
                            />
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default LivestreamVideoComp;
