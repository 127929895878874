import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { PageContainer } from 'core/components/PageContainer';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { TrainerList } from 'modules/trainers/components/TrainerList';
import React, { FC, useContext, useEffect } from 'react';
import { trainersQuery } from '../state/trainers.query';

const AllTrainersPageWithoutProvider: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { clearDialogProps } = useContext(AirexDialogContext);

    const { patchFilters } = useCrud();

    useEffect(() => {
        clearDialogProps();

        patchFilters({
            order_by: 'active',
            order_direction: 'desc',
        });
    }, []);

    return (
        <PageContainer
            header={{ title: t('all-trainers') }}
            renderContent={() => <TrainerList />}
        />
    );
};

export const AllTrainersPage = WithProvider(
    WithCurrentPageListener(AllTrainersPageWithoutProvider),
    trainersQuery
);
