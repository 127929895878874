import { Participant, VideoClient } from '@zoom/videosdk';
import { useEffect, useRef, useCallback } from 'react';

/*
 * https://github.com/zoom/sample-app-videosdk/blob/9de70ab4098fd52631bc9a2e3675bc0c03966c57/react-demo/src/feature/video/hooks/useParticipantsChange.ts#L4
 */

export function useParticipantsChange(
    zmClient: typeof VideoClient,
    fn: (participants: Participant[]) => void
): void {
    const fnRef = useRef(fn);

    fnRef.current = fn;

    const callback = useCallback(() => {
        const participants = zmClient.getAllUser();
        fnRef.current && fnRef.current(participants);
    }, [zmClient]);

    useEffect(() => {
        zmClient.on('user-added', callback);
        zmClient.on('user-removed', callback);
        zmClient.on('user-updated', callback);
        return () => {
            zmClient.off('user-added', callback);
            zmClient.off('user-removed', callback);
            zmClient.off('user-updated', callback);
        };
    }, [zmClient, callback]);

    useEffect(() => {
        callback();
    }, []);
}
