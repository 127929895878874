import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { FC } from 'react';

export const TimeStartInput: FC<any> = ({
    value,
    onClick,
}: {
    value: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <Button
        className="time-picker"
        onClick={onClick}
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
    >
        {value && value !== '' ? (
            value
        ) : (
            <p className="light-placeholder">Select</p>
        )}
    </Button>
);
