import { ReactComponent as AirexLogo } from 'assets/images/airex_logo.svg';
import { AuthForm, FormConfig } from 'core/components/AuthForm';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { validateEmailhelper } from 'core/utils/validateEmail';
import { ILoginCredentials } from 'modules/login/models/login.model';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ILocationState } from '../components/PrivateRoute';

export const LoginPage: FC = () => {
    const { isLoggedIn, login, logout, error, cleanErrorState } = useSession();
    const { push } = useHistory();
    const { state } = useLocation<ILocationState>();
    const { t } = useContext(MultiLanguageSupportContext);

    useEffect(() => {
        if (isLoggedIn) {
            logout();
        }
        return () => cleanErrorState();
    }, []);

    const onSubmitClick = async (credentials: ILoginCredentials) => {
        if (await login(credentials)) {
            push({ pathname: state?.from?.pathname ?? ROUTES.DEFAULT });
        }
    };

    const validateEmail = (data: string): boolean => {
        return validateEmailhelper(data);
    };

    const loginFormConfig: FormConfig<ILoginCredentials> = {
        logo: <AirexLogo />,
        buttonText: t('log-in'),
        linkText: t('link-forgot-password'),
        linkRoute: ROUTES.RESET_PASSWORD,
        inputs: [
            {
                name: 'email',
                label: t('email'),
                placeholder: t('input-email-placeholder'),
                textHelper: t('invalid-email-error'),
                validate: validateEmail,
            },
            {
                name: 'password',
                label: t('password'),
                placeholder: t('input-password-placeholder'),
                type: 'password',
            },
        ],
        errorText: error,
        successText: state?.info,
        onSubmitClick: onSubmitClick,
    };

    return <AuthForm {...loginFormConfig} />;
};
