import { PageNotFound } from 'core/pages/PageNotFound';
import { HomePage } from 'modules/home/pages/HomePage';
import { AddLivestreamPageContainer } from 'modules/livestream/pages/AddLivestreamPageContainer';
import { AllLivestreamsPage } from 'modules/livestream/pages/AllLivestreamsPage';
import { getLivestreamPage } from 'modules/livestream/pages/LivestreamPage';
import { LivestreamVideo } from 'modules/livestream/pages/LivestreamVideo';
import { MyLivestreamsPage } from 'modules/livestream/pages/MyLivestreamsPage';
import { PendingLivestreamsPage } from 'modules/livestream/pages/PendingLivestreamsPage';
import { MyProfileEditContainer } from 'modules/profile/pages/MyProfileEditPage';
import { MyProfilePageContainer } from 'modules/profile/pages/MyProfilePage';
import { AddTrainerPageContainer } from 'modules/trainers/pages/AddTrainersPage';
import { AllTrainersPage } from 'modules/trainers/pages/AllTrainersPage';
import { EditTrainerPageContainer } from 'modules/trainers/pages/EditTrainerPage';
import { ViewTrainerPage } from 'modules/trainers/pages/ViewTrainerPage';
import { AddTrainingPageContainer } from 'modules/trainings/pages/AddTrainingPageContainer';
import { AllTrainingsPage } from 'modules/trainings/pages/AllTrainingsPage';
import { MyTrainingsPage } from 'modules/trainings/pages/MyTrainingsPage';
import { PendingTrainingsPage } from 'modules/trainings/pages/PendingTrainingsPage';
import { getTrainingPage } from 'modules/trainings/pages/TrainingPage';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { ROUTES } from '../enums/routes.enum';
import { INavigationItem } from '../interfaces/navigationItem.interface';

export const navigationItems: INavigationItem[] = [
    {
        path: ROUTES.DEFAULT,
        section: 'User Management',
        translateKey: 'home',
        contentComponent: HomePage,
        permission: PERMISSIONS.HOME,
    },
    {
        path: ROUTES.MY_PROFILE,
        section: 'User Management',
        label: 'My profile',
        translateKey: 'my-profile',
        contentComponent: MyProfilePageContainer,
        permission: PERMISSIONS.MY_PROFILE,
    },
    {
        path: ROUTES.MY_PROFILE_EDIT,
        contentComponent: MyProfileEditContainer,
    },
    {
        path: ROUTES.TRAINER_ADD,
        section: 'User Management',
        label: 'Add trainer',
        translateKey: 'add-trainer',
        contentComponent: AddTrainerPageContainer,
        permission: PERMISSIONS.ADD_TRAINER,
    },
    {
        path: ROUTES.TRAINER_EDIT,
        contentComponent: EditTrainerPageContainer,
    },
    {
        path: ROUTES.TRAINER_VIEW,
        contentComponent: ViewTrainerPage,
    },
    {
        path: ROUTES.TRAINERS,
        section: 'User Management',
        label: 'All trainers list',
        translateKey: 'all-trainers',
        contentComponent: AllTrainersPage,
        permission: PERMISSIONS.ALL_TRAINERS_LIST,
    },
    {
        path: ROUTES.MY_TRAININGS_SINGLE,
        contentComponent: getTrainingPage(ROUTES.MY_TRAININGS),
    },
    {
        path: ROUTES.MY_TRAININGS,
        section: 'Training Videos',
        label: 'My videos',
        translateKey: 'my-videos',
        contentComponent: MyTrainingsPage,
        permission: PERMISSIONS.MY_VIDEOS,
    },
    {
        path: ROUTES.MY_TRAININGS_ADD,
        section: 'Training Videos',
        label: 'Upload new training',
        translateKey: 'add-training',
        contentComponent: AddTrainingPageContainer,
        permission: PERMISSIONS.UPLOAD_NEW_TRAINING,
    },
    {
        path: ROUTES.ALL_TRAININGS_SINGLE,
        contentComponent: getTrainingPage(ROUTES.ALL_TRAININGS),
    },
    {
        path: ROUTES.ALL_TRAININGS,
        section: 'Training Videos',
        label: 'All training videos',
        translateKey: 'all-trainings',
        contentComponent: AllTrainingsPage,
        permission: PERMISSIONS.ALL_TRAINING_VIDEOS,
    },
    {
        path: ROUTES.PENDING_TRAININGS_SINGLE,
        contentComponent: getTrainingPage(ROUTES.PENDING_TRAININGS),
    },
    {
        path: ROUTES.PENDING_TRAININGS,
        section: 'Training Videos',
        translateKey: 'videos-pending-approval',
        label: 'Videos pending approval',
        contentComponent: PendingTrainingsPage,
        permission: PERMISSIONS.VIDEOS_PENING_APPROVAL,
    },

    {
        path: ROUTES.MY_LIVESTREAMS_SINGLE,
        contentComponent: getLivestreamPage(ROUTES.MY_LIVESTREAMS),
    },
    {
        path: ROUTES.MY_LIVESTREAMS,
        section: 'Livestream',
        label: 'My livestreams',
        translateKey: 'my-livestreams',
        contentComponent: MyLivestreamsPage,
        permission: PERMISSIONS.MY_LIVESTREAM,
    },
    {
        path: ROUTES.MY_LIVESTREAMS_ADD,
        section: 'Livestream',
        label: 'Create new livestream',
        translateKey: 'add-livestream',
        contentComponent: AddLivestreamPageContainer,
        permission: PERMISSIONS.UPLOAD_NEW_LIVESTREAM,
    },
    {
        path: ROUTES.ALL_LIVESTREAMS_SINGLE,
        contentComponent: getLivestreamPage(ROUTES.ALL_LIVESTREAMS),
    },
    {
        path: ROUTES.ALL_LIVESTREAMS,
        section: 'Livestream',
        label: 'All livestream videos',
        translateKey: 'all-livestreams',
        contentComponent: AllLivestreamsPage,
        permission: PERMISSIONS.ALL_LIVESTREAM_VIDEOS,
    },
    {
        path: ROUTES.PENDING_LIVESTREAMS_SINGLE,
        contentComponent: getLivestreamPage(ROUTES.PENDING_LIVESTREAMS),
    },
    {
        path: ROUTES.PENDING_LIVESTREAMS,
        section: 'Livestream',
        translateKey: 'livestream-pending-approval',
        label: 'Livestream pending approval',
        contentComponent: PendingLivestreamsPage,
        permission: PERMISSIONS.LIVESTREAM_PENDING_APPROVAL,
    },
    {
        path: ROUTES.LIVESTREAMS_LIVE,
        contentComponent: LivestreamVideo,
    },
    {
        path: ROUTES.NOT_FOUND,
        contentComponent: PageNotFound,
    },
];
