import {
    combineQueries,
    QueryConfigOptions,
    selectPersistStateInit,
} from '@datorama/akita';
import { unsubscribeTimoutPeriod } from 'core/constants/clear-timeout-period';
import { initialEntitiesState } from 'core/constants/initial-entities-state';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesState, EntitiesStore } from './entities.store';
import { QueryEntityWithHighlight } from './highlight.query';

export abstract class EntitiesQuery<
    T extends IEntity
> extends QueryEntityWithHighlight<EntitiesState<T>> {
    private _pageNumberSubscription: Subscription | null = null;
    private _unsubscribeTimeout: NodeJS.Timeout | null = null;

    private _init$ = new Subject();
    private _hasInitialized = false;

    get hasInitialized() {
        return this._hasInitialized;
    }

    readonly init$ = this._init$.asObservable();
    readonly searchTerm$ = this.select(({ ui }) => ui.searchTerm);
    readonly pageNumber$ = this.select(({ ui }) => ui.pageNumber);
    readonly pageSize$ = this.select(({ ui }) => ui.pageSize);

    readonly activeTabIndex$ = this.select(
        ({ activeTabIndex }) => activeTabIndex
    );
    readonly validatedTabIndex$ = this.select(
        ({ validatedTabIndex }) => validatedTabIndex
    );
    readonly editMode$ = this.select(({ editMode }) => editMode);

    readonly total$ = this.select(({ total }) => total);
    readonly currentPageIds$ = this.select(
        ({ currentPageIds }) => currentPageIds
    );
    readonly currentPageEntities$ = combineQueries([
        this.currentPageIds$,
        this.selectAll({ asObject: true }),
    ]).pipe(map(([ids, entities]) => ids.map((id) => entities[id])));
    readonly shuoldFetchEntities$: Observable<any[]> = combineQueries([
        this.searchTerm$,
        this.pageSize$,
        this.pageNumber$,
    ]);

    constructor(
        public store: EntitiesStore<T, any, any>,
        options?: Partial<QueryConfigOptions<any>>
    ) {
        super(store, options);

        selectPersistStateInit().subscribe(() => {
            this._hasInitialized = true;
            this._init$.next();
        });
    }

    startCurrentPageListener = (): void => {
        if (this._unsubscribeTimeout) {
            clearTimeout(this._unsubscribeTimeout);
            this._unsubscribeTimeout = null;
        }

        setTimeout(async () => {
            if (!this.hasInitialized) {
                this.init$.subscribe(async () => {
                    const { currentPageIds } = this.store.getValue();

                    if (!currentPageIds.length) {
                        await this.store.fetchEntities();
                    }
                });
            } else {
                const { currentPageIds } = this.store.getValue();

                if (!currentPageIds.length) {
                    await this.store.fetchEntities();
                }
            }

            if (!this._pageNumberSubscription) {
                this._pageNumberSubscription = this.shuoldFetchEntities$
                    .pipe(skip(1))
                    .subscribe(async () => {
                        if (this.hasInitialized) {
                            await this.store.fetchEntities();
                        }
                    });
            }
        });
    };

    stopCurrentPageListener = (): void => {
        if (this._pageNumberSubscription) {
            this._pageNumberSubscription.unsubscribe();
            this._pageNumberSubscription = null;
        }

        if (!this._unsubscribeTimeout) {
            this._unsubscribeTimeout = setTimeout(() => {
                this.store.clearCurrentPageState(initialEntitiesState.ui);
            }, unsubscribeTimoutPeriod);
        }
    };
}
