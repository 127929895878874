import { ReactComponent as AirexLogo } from 'assets/images/airex_logo.svg';
import appStore from 'assets/images/app_store.png';
import googlePlay from 'assets/images/google_play.png';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FC, useContext } from 'react';
import styles from './LivestreamCalendarAccessPage.module.scss';

interface IUseParams {
    id: string;
}

export const LivestreamCalendarAccessPage: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    // const { id } = useParams<IUseParams>();

    // TODO: Uncomment if universal links for android & ios doesnt work
    // useEffect(() => {
    //     window.location.href = `airex://mobile.airex.app/livestream-calendar/${id}`;
    // }, [id]);

    return (
        <div className={styles.container}>
            <AirexLogo />
            <h1 className={styles.headline}>
                {t('livestream-calendar-headline')}
            </h1>
            <h3 className={styles.subtitle}>
                {t('livestream-calendar-description')}
            </h3>
            <div className={styles.storeButtons}>
                <a
                    href="https://apps.apple.com/us/app/airex-academy-training-app/id1575338825"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={appStore} alt="App Store" />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=io.q.airex"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={googlePlay} alt="Google Play" />
                </a>
            </div>
        </div>
    );
};
