import { Box } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { Thumbnail } from 'core/components/shared/Thumbnail';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { ITraining } from 'modules/trainings/models/training.model';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './style.scss';

export interface VideoCardProps {
    videoData: ITraining;
}

export const VideoCard: FC<VideoCardProps> = ({ videoData }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { push } = useHistory();

    return (
        <Box
            display="flex"
            alignItems="center"
            className="videoCardContainer"
            onClick={() =>
                push(
                    pathBuilder(
                        ROUTES.ALL_TRAININGS_SINGLE,
                        ':id',
                        videoData.id
                    )
                )
            }
        >
            <Box display="flex" flexDirection="column" marginRight="20px">
                <div className="videoScreenContainer">
                    <Thumbnail url={videoData.screen} />
                </div>
            </Box>

            <Box display="flex" flexDirection="column" flex="1">
                <h1 className="videoText">{videoData.watchedTimes}</h1>
                <p className="videoText title">{videoData.title}</p>
                <p className="videoText trainerName">
                    {`${t('with')} ${videoData.trainerName}`}
                </p>
                <Link
                    className="unlinkify"
                    to={pathBuilder(
                        ROUTES.ALL_TRAININGS_SINGLE,
                        ':id',
                        videoData.id
                    )}
                >
                    <b>
                        <p>{t('view-video')}</p>
                    </b>
                </Link>
            </Box>
        </Box>
    );
};
