import { apiService } from './apiService';

class LanguagesAPIService {
    async getLanguages() {
        const response = await apiService.get(`languages`);
        return response;
    }
}

export const languagesAPIService = new LanguagesAPIService();
