import { UserRole } from '../constants/role-permissions';

export enum PERMISSIONS {
    HOME = 'home',
    MY_PROFILE = 'my-profile',
    ADD_TRAINER = 'add-trainer',
    VIEW_TRAINER = 'view-trainer',
    EDIT_TRAINER = 'edit-trainer',
    EDIT_OWN_TRAINER = 'edit-own-trainer',
    UPDATE_TRAINER_STATE = 'update-trainer-state',
    ALL_TRAINERS_LIST = 'all-trainers-list',
    VIEW_TRAINER_ADDED_BY = 'trainer-added-by',
    MY_VIDEOS = 'my-videos',
    MY_VIDEOS_DECLINED = 'my-videos-declined',
    MY_VIDEOS_PENDING = 'my-videos-pending',
    UPLOAD_NEW_TRAINING = 'upload-new-training',
    VIEW_TRAINING = 'view-training',
    EDIT_APPROVED_TRAINING = 'edit-approved-training',
    EDIT_OWN_TRAINING = 'edit-own-training',
    DELETE_TRAINING = 'delete-training',
    DELETE_OWN_TRAINING = 'delete-own-training',
    ALL_TRAINING_VIDEOS = 'all-training-videos',
    VIDEOS_PENING_APPROVAL = 'videos-pending-approval',

    MY_LIVESTREAM = 'my-livestreams',
    MY_LIVESTREAM_DECLINED = 'my-livestreams-declined',
    MY_LIVESTREAM_PENDING = 'my-livestreams-pending',
    UPLOAD_NEW_LIVESTREAM = 'upload-new-livestream',
    VIEW_LIVESTREAM = 'view-livestream',
    EDIT_LIVESTREAM = 'edit-livestream',
    EDIT_OWN_LIVESTREAM = 'edit-own-livestream',
    DELETE_LIVESTREAM = 'delete-livestream',
    CANCEL_LIVESTREAM = 'cancel-livestream',
    DELETE_OWN_LIVESTREAM = 'delete-own-livestream',
    RESCHEDULE_OWN_LIVESTREAM = 'reschedule-own-livestream',
    CANCEL_OWN_LIVESTREAM = 'cancel-own-livestream',
    ALL_LIVESTREAM_VIDEOS = 'all-livestreams-videos',
    LIVESTREAM_PENDING_APPROVAL = 'livestreams-pending-approval',
    LIVESTREAM_SESSION_ADMIN_ACCESS = 'livestream-session',

    SELECT_TRAINER = 'select-trainer',
    MOVE_DECLINED_VIDEO_TO_DRAFT = 'move-declined_video_to_draft',
}

export type RolePermissions = {
    [key in UserRole]: Array<PERMISSIONS>;
};
