import { selectPersistStateInit } from '@datorama/akita';
import { EntitiesQuery } from '../../../core/state/entities.query';
import { ITrainer } from '../models/trainer.model';
import { trainersStore } from './trainers.store';

export class TrainersQuery extends EntitiesQuery<ITrainer> {
    constructor() {
        super(trainersStore);

        selectPersistStateInit().subscribe(() => {
            this.store.update({
                activeTabIndex: 0,
                validatedTabIndex: 0,
                editMode: false,
            });
        });
    }
}

export const trainersQuery = new TrainersQuery();
