export interface ITakenTimeslotResponse {
    starts_at: string;
    ends_at: string;
}

export interface ITakenTimeslot {
    startsAt: Date;
    endsAt: Date;
}

export const createTakenTimeslotFromResponse = ({
    starts_at,
    ends_at,
}: ITakenTimeslotResponse): ITakenTimeslot => {
    return {
        startsAt: new Date(starts_at),
        endsAt: new Date(ends_at),
    };
};
