import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { ChatMessage } from '@zoom/videosdk';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import ZoomClientContext from 'core/components/ZoomClientContext';
import { IEntity } from 'core/interfaces/entity.interface';
import { apiService } from 'core/services/apiService';
import { useLivestreamHostConnection } from 'modules/livestream/hooks/livestream-host-connection.hook';
import { IStreamDetails } from 'modules/livestream/interfaces';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import LivestreamVideoComp from './LivestreamVideoComp';
import LivestreamVideoDialog from './LivestreamVideoDialog';

const useStyles = makeStyles(() =>
    createStyles({
        stop: {
            fontSize: 18,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        back: {
            marginRight: 10,
        },
        participints: {
            height: 40,
            width: 225,
            backgroundColor: '#CE2E26',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: 'bold',
            cursor: 'pointer',
        },
    })
);

interface ILivestreamVideoAdminProps extends IEntity {
    stream: Pick<IStreamDetails, 'name'>;
}

const LivestreamVideoAdmin: FC<ILivestreamVideoAdminProps> = ({
    id: livestreamId,
    stream,
}) => {
    const zmClient = useContext(ZoomClientContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();

    const [openDialogExit, setOpenDialogExit] = useState(false);
    const [openDialogCancel, setOpenDialogCancel] = useState(false);
    const [openDialogFinished, setOpenDialogFinished] = useState(false);
    const { setDialogProps, clearDialogProps } = useContext(AirexDialogContext);

    const { push } = useHistory();

    const {
        hostIsAlive,
        hostHasBeenDisconnectedFor3Minutes,
    } = useLivestreamHostConnection();

    useEffect(() => {
        if (hostHasBeenDisconnectedFor3Minutes) {
            exitLivestream();
        }
    }, [hostHasBeenDisconnectedFor3Minutes]);

    const onChatMessage = async ({ message }: ChatMessage) => {
        switch (message) {
            case 'FINISH':
                setOpenDialogFinished(true);

                setTimeout(() => {
                    exitLivestream();
                }, 5000);
                break;
        }
    };

    const onLeave = () => {
        try {
            zmClient.off('chat-on-message', onChatMessage);
            zmClient.off('auto-play-audio-failed', () => {
                console.log('auto play failed, closing');
            });
        } catch (e) {
            console.log('Off events', e);
        }

        try {
            zmClient
                .leave(zmClient.isHost())
                .catch((e) => console.log('Leave true error', e));
        } catch (e) {
            console.log('leave error', e);
        }
    };

    const muteAudio = async () => {
        try {
            const mediaStream = zmClient.getMediaStream();

            await mediaStream
                .muteAudio()
                .then(() => {
                    console.log('Audio muted');
                })
                .catch((e) => console.log('Mute error', e));
        } catch (e) {
            console.log('muteAudio catch', e);
        }
    };

    const startAudio = async () => {
        try {
            const mediaStream = zmClient.getMediaStream();

            await mediaStream.startAudio().then(
                () => {
                    console.log('Audio started');
                },
                (e) => {
                    console.log('startAudio catch', e);
                }
            );
        } catch (e) {
            console.log('Media stream error', e);
        }
    };

    useEffect(() => {
        try {
            zmClient.on('chat-on-message', onChatMessage);
            zmClient.on('auto-play-audio-failed', () => {
                setDialogProps({
                    openDialog: true,
                    actions: [
                        {
                            content: (
                                <Button
                                    color="primary"
                                    label={t('ok')}
                                    onClick={() => clearDialogProps()}
                                />
                            ),
                        },
                    ],
                    contentText: t('auto-play-failed'),
                    onClose: clearDialogProps,
                });
            });
            zmClient.on('current-audio-change', (payload) => {
                if (payload.action === 'join') {
                    console.log('Joined by ', payload.type);

                    muteAudio();
                }
            });
        } catch (e) {
            console.log('Admin init mount error', e);
        }

        // window.addEventListener('beforeunload', () => onLeave());

        return onLeave;
    }, []);

    const cancelLivestream = async () => {
        await apiService
            .put(`trainings/livestreams/${livestreamId}/cancel`, livestreamId)
            .then(apiService.responseHandler);

        await zmClient.getChatClient().sendToAll('CANCEL');

        exitLivestream();
    };

    const exitLivestream = async () => {
        // await onLeave();

        push('/all-livestream-videos/' + livestreamId);
    };

    return (
        <>
            <LivestreamVideoComp
                livestreamName={stream.name}
                renderBackButton={() => (
                    <div
                        className={classes.stop}
                        onClick={() => setOpenDialogExit(true)}
                    >
                        <ArrowBack className={classes.back} />
                        <span>{t('button-exit-livestream')}</span>
                    </div>
                )}
                renderActionButton={() => (
                    <div
                        className={classes.participints}
                        onClick={() => setOpenDialogCancel(true)}
                    >
                        <span>{t('button-cancel-livestream')}</span>
                    </div>
                )}
                onVideoDecodeReady={startAudio}
            />
            <LivestreamVideoDialog
                open={openDialogExit}
                title={t('exit-livestream-title')}
                body={t('exit-livestream-message')}
                renderButtons={() => (
                    <>
                        <button
                            type="button"
                            onClick={() => setOpenDialogExit(false)}
                            className="cancelButton"
                        >
                            {t('cancel')}
                        </button>
                        <button
                            type="button"
                            onClick={exitLivestream}
                            className="confirmButton"
                        >
                            {t('exit-livestream')}
                        </button>
                    </>
                )}
            />
            <LivestreamVideoDialog
                open={openDialogCancel}
                title={t('cancel-livestream-title')}
                body={t('cancel-livestream-message')}
                renderButtons={() => (
                    <>
                        <button
                            type="button"
                            onClick={() => setOpenDialogCancel(false)}
                            className="cancelButton"
                        >
                            {t('cancel')}
                        </button>
                        <button
                            type="button"
                            onClick={cancelLivestream}
                            className="confirmButton"
                        >
                            {t('cancel-livestream')}
                        </button>
                    </>
                )}
            />
            <LivestreamVideoDialog
                open={openDialogFinished}
                title={t('livestream-finished-dialog-title')}
                body={t('livestream-finished-dialog-message')}
            />
            <LivestreamVideoDialog
                open={!hostIsAlive && !openDialogFinished}
                title={t('host-disconnected-dialog-title')}
                body={t('host-disconnected-dialog-message')}
            />
        </>
    );
};

export default LivestreamVideoAdmin;
