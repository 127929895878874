import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { WithProvider } from 'core/hocs/WithProvider';
import { IEntity } from 'core/interfaces/entity.interface';
import { ILocationState } from 'modules/login/components/PrivateRoute';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { useTrainer } from 'modules/trainers/hooks/useTrainer.hook';
import { AddTrainerCredentials } from 'modules/trainers/models/add-trainer.model';
import { trainersQuery } from 'modules/trainers/state/trainers.query';
import React, { FC, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { EditTrainer } from './EditTrainer';

export const EditTrainerPageContainer: FC = WithProvider(() => {
    const { id } = useParams<IEntity>();
    return <EditTrainerPage id={id} />;
}, trainersQuery);

const EditTrainerPage: FC<IEntity> = ({ id }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { push } = useHistory();
    const { state } = useLocation<ILocationState>();
    const { patchDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );
    const { updateTrainer } = useTrainer();

    const onUpdateTrainer = async (trainer: AddTrainerCredentials) => {
        if (await updateTrainer(id, trainer)) {
            patchDialogProps({
                openDialog: true,
                contentText: t('sucessfully-edited-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                label={t('close')}
                                color="primary"
                                onClick={() => {
                                    push({
                                        pathname:
                                            state?.from?.pathname ??
                                            pathBuilder(
                                                ROUTES.TRAINER_VIEW,
                                                ':id',
                                                id
                                            ),
                                    });
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                openDialog: true,
                contentText: t('unsucessfully-edited-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                label={t('close')}
                                color="primary"
                                onClick={() => {
                                    clearDialogProps();
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        }
    };

    return <EditTrainer id={id} onEdit={onUpdateTrainer} />;
};
