export interface IExpertiseResponse {
    id: number;
    name: string;
    parent: number;
}

export interface IExpertise {
    id: string;
    name: string;
    parent: string | null;
}

export const createExpertiseFromResponse = ({
    id,
    parent,
    ...props
}: IExpertiseResponse): IExpertise => {
    return {
        id: id.toString(),
        parent: !parent ? null : parent.toString(),
        ...props,
    };
};
