import { Box, FormHelperText, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FormInput } from 'core/components/FormInput';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import { validateInputText } from 'core/utils/validateInputText';
import { useFormikContext } from 'formik';
import { ILivestreamDetails } from 'modules/livestream/models/livestream.model';
import React, { FC, useContext, useEffect, useState } from 'react';
import './style.scss';

export const AgendaTab: FC<IReadonlyProps> = ({ readonly }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const formik = useFormikContext<ILivestreamDetails>();
    const [requirements, setRequirements] = useState<string>('');
    const [requirementsArray, setRequirementsArray] = useState<Array<string>>(
        formik.values.minRequirements ?? []
    );

    useEffect(() => {
        setRequirementsArray(formik.values.minRequirements);
    }, [formik.values.minRequirements]);

    const addToRequirements = () => {
        if (!validateInputText(requirements)) {
            formik.setErrors({
                ...formik.errors,
                minRequirements: t('invalid-input'),
            });
        } else if (requirements !== '') {
            const newArr = [...requirementsArray, requirements];
            setRequirementsArray([...requirementsArray, requirements]);
            formik.setFieldValue('minRequirements', newArr);
        }
        setRequirements('');
    };

    const removeFromRequirements = (i: number) => {
        const filter = requirementsArray.filter((item, index) => index !== i);

        setRequirementsArray(filter);
        formik.setFieldValue('minRequirements', filter);
    };

    return (
        <Box display="flex" flexWrap="wrap" alignItems="baseline">
            <Box display="flex" flexDirection="column" flex="1 1 auto">
                <p className="text">{t('livestream-agenda')}</p>
                <Box pb="5px" />
                <textarea
                    className="descriptionAgendaInput"
                    maxLength={1000}
                    spellCheck={false}
                    name="agenda"
                    value={formik.values.agenda ?? ''}
                    onChange={formik.handleChange}
                    required
                    placeholder="e.g.&#10;- 5 min intro&#10;- 10 min warmup&#10;- 30 min workout&#10;- 15 min questions and answers "
                    disabled={readonly}
                />
                {formik.errors.agenda !== '' && (
                    <FormHelperText className="formHelperText error">
                        {formik.errors.agenda}
                    </FormHelperText>
                )}
            </Box>
            <Box display="flex" flexDirection="column" flex="1 1 auto">
                {!readonly && (
                    <Box>
                        <FormInput
                            label={t('minimum-recommended-requirements')}
                            placeholder={t('enter-requirment')}
                            name="title"
                            type="text"
                            onChange={(e) => setRequirements(e.target.value)}
                            value={requirements}
                            disabled={readonly}
                        />
                        <div className="agendaButton">
                            <IconButton
                                disabled={readonly}
                                onClick={addToRequirements}
                            >
                                <AddCircleIcon className="iconEducation" />
                            </IconButton>
                            <p className="buttonText">{t('add-requirement')}</p>
                        </div>
                    </Box>
                )}
                <Box>
                    <p style={{ fontFamily: 'MarkBold' }}>
                        {t('requirements')}
                    </p>
                    {requirementsArray?.map((item, index) => {
                        return (
                            <div key={index} className="itemButton">
                                <p key={index} style={{ margin: 0 }}>
                                    {item}
                                </p>
                                {!readonly && (
                                    <IconButton
                                        disabled={readonly}
                                        onClick={() =>
                                            removeFromRequirements(index)
                                        }
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                )}
                            </div>
                        );
                    })}
                    {formik.errors && Object.keys(formik.errors).length > 0 && (
                        <FormHelperText className="formHelperText error">
                            {formik.errors.minRequirements}
                        </FormHelperText>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
