export const encodeImageFileAsURL = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void
): void => {
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }
};
