import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useContext } from 'react';

export const useErrorDialog = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { setDialogProps, clearDialogProps } = useContext(AirexDialogContext);

    const raiseErrorDialog = (e: any, altProp = 'error') =>
        setDialogProps({
            openDialog: true,
            contentText: e.message ?? e[altProp],
            actions: [
                {
                    content: (
                        <Button
                            color="primary"
                            label={t('close')}
                            onClick={() => clearDialogProps()}
                        />
                    ),
                },
            ],
            onClose: clearDialogProps,
        });

    return { raiseErrorDialog };
};
