import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormInput } from 'core/components/FormInput';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { apiService } from 'core/services/apiService';
import { useFormik } from 'formik';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { useTrainers } from 'modules/trainers/hooks/trainers.hook';
import {
    ILivestream,
    ILivestreamDetails,
    ILivestreamResponse,
} from 'modules/livestream/models/livestream.model';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { fullName } from 'modules/users/models/user.model';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import './style.scss';

type ICreateLivestreamData = Pick<
    ILivestream,
    'title' | 'type' | 'trainerName'
>;

export const CreateLivestreamDialog = (): JSX.Element => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me } = useSession();
    const { goBack, push } = useHistory();
    const [openFormDialog, setOpenFormDialog] = useState(true);
    const hasPermission = usePermissions();
    const [errorOnCreateLivestream, setErrorOnCreateLivestream] = useState('');

    const formik = useFormik<ICreateLivestreamData>({
        initialValues: {
            title: '',
            type: 'livestream',
            trainerName:
                me && !hasPermission(PERMISSIONS.SELECT_TRAINER)
                    ? fullName(me)
                    : '',
        },
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: async ({ trainerName: trainer_name, type, ...data }) => {
            try {
                const { id } = apiService.responseHandler(
                    await apiService.post<ILivestreamResponse>(`/trainings`, {
                        trainer_name,
                        type,
                        ...data,
                    })
                );

                push(
                    pathBuilder(
                        ROUTES.MY_LIVESTREAMS_SINGLE,
                        ':id',
                        id.toString()
                    )
                );

                setOpenFormDialog(false);
            } catch (error: any) {
                let errorMessage = t('error');

                const exception = error?.debug.exception;

                if (exception?.length) {
                    errorMessage = exception[0]?.message ?? errorMessage;
                }

                setErrorOnCreateLivestream(errorMessage);
            }
        },
        validate: (values) => {
            const errors: Partial<ILivestreamDetails> = {};

            if (!values.title?.trim()) {
                errors.title = 'Required';
            }

            if (!values.trainerName?.trim()) {
                errors.trainerName = 'Required';
            }

            return errors;
        },
    });

    const onCreateClick = () => {
        setErrorOnCreateLivestream('');
        formik.handleSubmit();
    };

    const onCancelClick = () => {
        setOpenFormDialog(false);
        goBack();
    };

    return (
        <Dialog
            open={openFormDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <div className="uploadFormDialogContainer">
                <DialogTitle>{t('add-livestream')}</DialogTitle>
                <DialogContent>
                    {errorOnCreateLivestream && (
                        <Box mb="20px">
                            <FormHelperText className="formHelperText error">
                                {errorOnCreateLivestream}
                            </FormHelperText>
                        </Box>
                    )}
                    <form className="dialogForm">
                        <FormInput<ILivestreamDetails>
                            label={t('livestream-title')}
                            name="title"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            error={
                                !!(formik.errors.title && formik.touched.title)
                            }
                            textHelper={formik.errors.title}
                            required
                        />
                        <FormInput<ILivestreamDetails>
                            label={t('trainer-name')}
                            name="trainerName"
                            type="text"
                            value={formik.values.trainerName}
                            readOnly={true}
                            required
                        />

                        <div className="dialogButtons">
                            <button
                                type="button"
                                onClick={onCancelClick}
                                className="cancelButton"
                            >
                                {t('cancel')}
                            </button>
                            <button
                                type="button"
                                onClick={onCreateClick}
                                className="confirmButton"
                            >
                                {t('create')}
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </div>
        </Dialog>
    );
};
