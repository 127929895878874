import { EntityState, EntityUIStore, StoreConfig } from '@datorama/akita';
import axios, { CancelTokenSource } from 'axios';
import { apiService } from 'core/services/apiService';
import { UserRole } from 'modules/users/constants/role-permissions';
import { ValidTranslationKeys } from 'react-i18next';
import {
    EntitiesState,
    EntitiesStore,
    IEntitiesStoreConfig,
    IEntityUIState,
} from '../../../core/state/entities.store';
import {
    createTrainingCommand,
    createTrainingFromResponse,
    ITraining,
    ITrainingResponse,
    TrainingState,
} from '../models/training.model';

export interface ITrainingVideoUpload {
    progress: number | null;
    completed: boolean;
    getSource: (() => CancelTokenSource) | null;
    videoId: string | null;
    videoFile: File | null;
}

export type ITrainingVideoUploadStateKey = keyof Pick<
    ITraining,
    'video' | 'videoClip'
>;

type ITrainingVideoUploadStateEntity = Record<
    ITrainingVideoUploadStateKey,
    ITrainingVideoUpload
>;

export type ITrainingVideoUploadState = EntityState<ITrainingVideoUploadStateEntity>;

export const emptyTrainingVideoUploadData: ITrainingVideoUpload = {
    progress: null,
    completed: false,
    getSource: null,
    videoId: null,
    videoFile: null,
};

export interface ITrainingUIState extends IEntityUIState {
    role: UserRole;
    state: TrainingState;
}

export type TrainingsState = EntitiesState<ITraining, ITrainingUIState>;

@StoreConfig({
    name: 'trainings',
    resettable: true,
})
export class TrainingsStore extends EntitiesStore<
    ITraining,
    ITrainingResponse
> {
    ui!: EntityUIStore<ITrainingVideoUploadState>;

    constructor(config: IEntitiesStoreConfig<ITraining, ITrainingResponse>) {
        super(config);

        this.createUIStore(
            {},
            { resettable: true }
        ).setInitialEntityState<ITrainingVideoUploadStateEntity>({
            video: emptyTrainingVideoUploadData,
            videoClip: emptyTrainingVideoUploadData,
        });
    }

    private _getUploadState = (
        trainingId: string,
        key: ITrainingVideoUploadStateKey
    ) => {
        return this.ui.getValue().entities![trainingId]?.[key]; // FIXME: not ideal implementation
    };

    patchKeyUplaodState(
        key: ITrainingVideoUploadStateKey,
        trainingId: string,
        partial: Partial<ITrainingVideoUpload>
    ): void {
        const keyUploadState: ITrainingVideoUpload = {
            ...this._getUploadState(trainingId, key),
            ...partial,
        };

        this.ui.update(trainingId, {
            [key]: keyUploadState,
        });
    }

    checkIfVideoFileExistAndClear = (
        key: ITrainingVideoUploadStateKey,
        trainingId: string
    ) => {
        const training = this._getUploadState(trainingId, key);

        if (training?.videoId === null) {
            this.patchKeyUplaodState(key, trainingId, {
                ...emptyTrainingVideoUploadData,
            });
        }
    };

    cancelUpload = async (
        trainingId: string,
        key: ITrainingVideoUploadStateKey
    ) => {
        const { getSource } = this._getUploadState(trainingId, key);

        if (!getSource) {
            // FIXME: should throw translatable error
            throw new Error(
                `There is no ongoing upload for training with id: ${trainingId}`
            );
        }

        getSource().cancel();
    };

    uploadVideo = async (
        trainingId: string,
        file: File,
        key: ITrainingVideoUploadStateKey,
        chunkSize = defaultChunkSize
    ): Promise<boolean> => {
        const numberOfChunks = Math.ceil(file.size / chunkSize);
        const chunks = await getArrayOfFileChunks(file, chunkSize);

        try {
            const { id } = apiService.responseHandler(
                await apiService.post<IChunkResponse, TrainingUploadRequest>(
                    `/uploads/${trainingId}`,
                    {
                        total_parts: chunks.length,
                        type: key === 'video' ? 'video' : 'video_clip',
                    }
                )
            );

            this.patchKeyUplaodState(key, trainingId, {
                videoId: id.toString(),
            });

            let chunkProgress: number;
            let chunkCounter = 0;

            for (const chunk of chunks) {
                const { chunkForm } = chunk;
                chunkProgress = 0;
                chunkCounter++;

                const { videoId } = this._getUploadState(trainingId, key);

                if (videoId !== id.toString()) {
                    if (!videoId) {
                        this.patchKeyUplaodState(key, trainingId, {
                            ...emptyTrainingVideoUploadData,
                        });
                    }

                    return false;
                }

                const source = axios.CancelToken.source();

                this.patchKeyUplaodState(key, trainingId, {
                    getSource: () => source,
                });

                try {
                    const { data } = await apiService.post<IChunkResponse>(
                        `/uploads/part/${videoId}`,
                        chunkForm,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: ({ loaded, total }: any) => {
                                chunkProgress = Math.round(
                                    (loaded / total) * 100
                                );

                                let calculatedProgress = 0;

                                if (numberOfChunks && chunkCounter) {
                                    calculatedProgress = Math.round(
                                        ((chunkCounter - 1) / numberOfChunks) *
                                            100 +
                                            chunkProgress / numberOfChunks
                                    );
                                }

                                this.patchKeyUplaodState(key, trainingId, {
                                    progress: calculatedProgress,
                                });
                            },
                            timeout: 0,
                            cancelToken: source.token,
                        }
                    );

                    if (data.uploaded_parts === data.total_parts) {
                        this.patchKeyUplaodState(key, trainingId, {
                            progress: null,
                            completed: true,
                        });
                    }
                } catch (e) {
                    if (axios.isCancel(e)) {
                        this.patchKeyUplaodState(key, trainingId, {
                            ...emptyTrainingVideoUploadData,
                        });

                        await apiService.delete(`uploads/${videoId}`);

                        return false;
                    }

                    throw e;
                }
            }
        } catch (e) {
            console.log(e);
            this.patchKeyUplaodState(key, trainingId, {
                ...emptyTrainingVideoUploadData,
            });

            throw new Error('video-upload-failed');
        }

        return true;
    };
}

export const trainingsStore = new TrainingsStore({
    responseMapper: createTrainingFromResponse,
    commandMapper: createTrainingCommand,
});

interface IChunkResponse {
    id: number;
    uploaded_parts: number;
    total_parts: number;
    progress: number;
    state: string;
}

interface ChunkInfo {
    chunkForm: FormData;
    contentRange: string;
}

interface TrainingUploadRequest {
    total_parts: number;
    type: string;
}

const getArrayOfFileChunks = async (
    file: File,
    chunkSize: number
): Promise<ChunkInfo[]> => {
    const size = file.size;

    const chunksToUpload = [];

    let chunkStart = 0;

    while (chunkStart < size) {
        const chunkEnd = Math.min(chunkStart + chunkSize, size);

        const chunk = file.slice(chunkStart, chunkEnd);
        const contentRange = `bytes ${chunkStart}-${chunkEnd - 1}/${size}`;

        const chunkForm = new FormData();
        chunkForm.append('part', chunk, file.name);
        chunksToUpload.push({ chunkForm, contentRange });

        chunkStart += chunkSize;
    }

    return chunksToUpload;
};

const defaultChunkSize = 6000000;

export interface IVideoUploadFailure {
    message: keyof ValidTranslationKeys;
    numberOfChunks: number;
    chunkProgress: number;
    chunkCounter: number;
    videoId: number;
}
