import { UserRoles } from 'modules/users/models/user.model';
import { CONTACT_NUMBER } from '../enums/contactNumbers.enum';
import { SOCIAL } from '../enums/socialLinks.enum';
import { AddTrainerData } from '../pages/AddTrainersPage';
export interface AddTrainerCredentials {
    email: string;
    first_name: string;
    last_name: string;
    avatar: any;
    roles: Array<UserRoles>;
    contact_number: Array<string>;
    languages: Array<string>;
    social: Array<string | null> | null;
    education: Array<string>;
    certificates: Array<number> | null;
    expertise: Array<number>;
}

export const createTrainerData = ({
    accountDetails,
    areaOfExpertise,
    certificates,
    education,
}: AddTrainerData): AddTrainerCredentials => {
    const contactNumbers = [];
    contactNumbers[CONTACT_NUMBER.PRIMARY] = accountDetails.contact_number1;
    if (accountDetails.contact_number2) {
        contactNumbers[CONTACT_NUMBER.SECONDARY] =
            accountDetails.contact_number2;
    }
    // const userRoles = [accountDetails.roles];
    const languages = [...accountDetails.languages];

    if (
        accountDetails.checkedAdditionalLanguages &&
        accountDetails.checkedAdditionalLanguages.length > 0
    ) {
        languages.push(...accountDetails.checkedAdditionalLanguages);
    }

    const languagesSet = new Set([...languages]);
    const languagesArray = Array.from(languagesSet);

    const social = [];
    social[SOCIAL.FACEBOOK] = accountDetails.facebook ?? null;
    social[SOCIAL.INSTAGRAM] = accountDetails.instagram ?? null;
    social[SOCIAL.LINKEDIN] = accountDetails.linkedin ?? null;
    social[SOCIAL.YOUTUBE] = accountDetails.youtube ?? null;

    // backend expect Array<number>, where those numbers are ids
    const certificatesArray = certificates.map((certificate) =>
        Number(certificate)
    );

    // backend expect Array<number>, where those numbers are ids
    const expertiseArray = areaOfExpertise.map((expertise) =>
        Number(expertise)
    );

    return {
        first_name: accountDetails.first_name,
        last_name: accountDetails.last_name,
        email: accountDetails.email,
        roles: accountDetails.roles,
        contact_number: contactNumbers,
        languages: languagesArray,
        social: social.length > 0 ? social : null,
        education,
        certificates: certificatesArray,
        expertise: expertiseArray,
        avatar: accountDetails.avatar ?? null,
    };
};
