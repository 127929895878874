import { FC } from 'react';
import { MicOff, Mic, PanTool } from '@material-ui/icons';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Participant } from '@zoom/videosdk';

const useStyles = makeStyles(() =>
    createStyles({
        hand: {
            color: '#CE2E26',
            fontSize: 16,
            marginRight: 10,
        },
        participint: {
            fontSize: 14,
            borderBottom: 1,
            borderBlockColor: '#ddd',
            borderBlockEndStyle: 'solid',
        },
    })
);

interface IParticipantListItemProps
    extends Pick<Participant, 'displayName' | 'muted' | 'userId'> {
    index: number;
    isHandRaised: boolean;
    hideActions: boolean;
    onMicOff: (userId: number) => void;
    onMicOn: (userId: number) => void;
}

const ParticipantListItem: FC<IParticipantListItemProps> = ({
    index,
    displayName,
    isHandRaised,
    hideActions,
    muted,
    userId,
    onMicOff,
    onMicOn,
}) => {
    const classes = useStyles();
    // const [hovered, setHovered] = useState(true);

    return (
        <ListItem
            // onMouseEnter={() => setHovered(true)}
            // onMouseLeave={() => setHovered(false)}
            className={classes.participint}
        >
            <ListItemText>
                {index + 1}. {displayName}
            </ListItemText>
            {!hideActions && (
                <ListItemIcon>
                    {isHandRaised && (
                        <IconButton>
                            <PanTool className={classes.hand} />
                        </IconButton>
                    )}
                    {muted ? (
                        <IconButton>
                            <MicOff onClick={() => onMicOn(userId)} />
                        </IconButton>
                    ) : (
                        <IconButton>
                            <Mic onClick={() => onMicOff(userId)} />
                        </IconButton>
                    )}
                </ListItemIcon>
            )}
        </ListItem>
    );
};

export default ParticipantListItem;
