import { ConfirmationDialogContext } from 'core/components/ConfirmationDialog/ConfirmationDialogProvider';
import React, { FC, useContext } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';
import './style.scss';

export const ConfirmationDialogContainer: FC = () => {
    const { confirmationDialogProps } = useContext(ConfirmationDialogContext);

    // TODO: This container is not needed as the ConfirmationDialog can have direct access to the context instead of via props

    return <ConfirmationDialog {...confirmationDialogProps} />;
};
