import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { LivestreamCalendarAccessPage } from 'modules/livestream/pages/LivestreamCalendarAccessPage/LivestreamCalendarAccessPage';
import { CopyrightAirex } from 'modules/login/components/CopyrightAirex';
import { PrivateRoute } from 'modules/login/components/PrivateRoute';
import { ConfirmResetPasswordPage } from 'modules/login/pages/ConfirmResetPasswordPage';
import { GeneratePasswordPage } from 'modules/login/pages/GeneratePasswordPage';
import { LoginPage } from 'modules/login/pages/LoginPage';
import { ResetPasswordPage } from 'modules/login/pages/ResetPasswordPage';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { Layout } from './core/components/Layout';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

export const App = (): JSX.Element => (
    <>
        <Router>
            <Switch>
                <Route
                    exact
                    path={ROUTES.LOGIN}
                    render={() => (
                        <>
                            <LoginPage />
                            <CopyrightAirex />
                        </>
                    )}
                />
                <Route
                    exact
                    path={ROUTES.RESET_PASSWORD}
                    render={() => (
                        <>
                            <ResetPasswordPage />
                            <CopyrightAirex />
                        </>
                    )}
                />
                <Route
                    exact
                    path={ROUTES.RESET_PASSWORD_HASH}
                    render={() => (
                        <>
                            <ConfirmResetPasswordPage />
                            <CopyrightAirex />
                        </>
                    )}
                />
                <Route
                    exact
                    path={ROUTES.GENERATE_PASSWORD_HASH}
                    render={() => (
                        <>
                            <GeneratePasswordPage />
                            <CopyrightAirex />
                        </>
                    )}
                />
                <Route
                    exact
                    path={ROUTES.LIVESTREAM_CALENDAR}
                    render={() => <LivestreamCalendarAccessPage />}
                />
                <PrivateRoute path="" component={Layout} />
            </Switch>
        </Router>
    </>
);
