import { IExpertiseResponse } from 'modules/trainers/models/expertise.model';
import { apiService } from './apiService';

class ExpertiseAPIService {
    async getExpertise() {
        const response = await apiService.get<Array<IExpertiseResponse>>(
            `/expertise`
        );
        return response;
    }
}

export const expertiseAPIService = new ExpertiseAPIService();
