import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import {
    IPageContainerHeaderProps,
    PageContainer,
} from 'core/components/PageContainer';
import { RoutePrompt } from 'core/components/RoutePrompt';
import { WithProvider } from 'core/hocs/WithProvider';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { useSession } from 'core/hooks/session.hook';
import { IBreadcrumPathProps } from 'core/interfaces/breadcrumb-path-props.interface';
import { IEntity } from 'core/interfaces/entity.interface';
import { FormikErrors, FormikProvider, useFormik } from 'formik';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { ProductsUsedTab } from 'modules/trainings/components/ProductsUsedTab';
import { TrainingDetailsTab } from 'modules/trainings/components/TrainingDetailsTab';
import { UploadVideoTab } from 'modules/trainings/components/UploadVideoTab';
import {
    ITraining,
    ITrainingDetails,
    TrainingState,
} from 'modules/trainings/models/training.model';
import { UserRole } from 'modules/users/constants/role-permissions';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { getUserRole } from 'modules/users/models/user.model';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useParams } from 'react-router';
import { useTrainingState } from '../hooks/training-state.hook';
import { trainingsQuery } from '../state/trainings.query';
import { trainingsStore } from '../state/trainings.store';

enum TrainingPageTabs {
    UPLOAD_VIDEO,
    TRAINING_DETAILS,
    PRODUCTS_USED,
}

export interface ITrainingPageProps {
    id: string;
    videoClip: string | null;
    video: string;
    initialValues: Omit<ITrainingDetails, 'videoClip' | 'video'>;
    onSubmit?: (entity: ITrainingDetails) => Promise<ITrainingDetails | null>;
}

type TrainingPageProps = ITrainingPageProps & IBreadcrumPathProps;

export const TrainingPage: FC<TrainingPageProps> = ({
    id,
    videoClip,
    video,
    breadcrumbPath,
    initialValues,
    onSubmit = async () => null,
}: TrainingPageProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me } = useSession();
    const hasPermission = usePermissions();
    const [tempValues, setTempValues] = useState<
        Partial<Omit<ITrainingDetails, 'video' | 'duration'>>
    >({});
    const [tempVideoClip, setTempVideoClip] = useState(videoClip);
    const [tempVideo, setTempVideo] = useState(video);

    const tabs = useMemo(
        () => [t('upload-video'), t('training-details'), t('products-used')],
        [t]
    );

    const validate = (
        values: Omit<ITrainingDetails, 'videoClip' | 'video'>
    ) => {
        const errors: FormikErrors<ITrainingDetails> = {};

        !values.duration && (errors.duration = t('required'));
        !values.screen && (errors.screen = t('required'));
        !values.categories?.length && (errors.categories = t('required'));
        !values.health && (errors.health = t('required'));
        !values.challenge && (errors.challenge = t('required'));
        !values.description && (errors.description = t('required'));
        !values.productIds?.length && (errors.productIds = t('required'));

        return errors;
    };

    const formik = useFormik<Omit<ITrainingDetails, 'videoClip' | 'video'>>({
        initialValues,
        // Remove isinitialValid due to
        // Warning: isInitialValid has been deprecated and will be removed in future versions of Formik. Please use initialErrors or validateOnMount instead.
        initialErrors: validate({ ...initialValues, ...tempValues }),
        // isInitialValid: !(Object.keys(validate(initialValues)).length > 0),
        enableReinitialize: true,
        onSubmit: () => {
            requestApproval(id);
        },
        validate,
    });

    const canEdit = useMemo(
        () =>
            (initialValues.state === TrainingState.APPROVED &&
                hasPermission(PERMISSIONS.EDIT_APPROVED_TRAINING)) ||
            (initialValues.state === TrainingState.DRAFT &&
                hasPermission(PERMISSIONS.EDIT_OWN_TRAINING) &&
                me?.id === initialValues.createdBy),

        [me, initialValues]
    );

    const renderContent = useCallback(
        (activeIndex) => {
            const readonly = !(canEdit && !initialValues.createdFromLivestream);
            const canUploadVideo = canEdit;

            switch (activeIndex) {
                case TrainingPageTabs.UPLOAD_VIDEO:
                    return (
                        <UploadVideoTab
                            disableUploadVideo={!canUploadVideo}
                            readonly={readonly}
                            id={id}
                        />
                    );
                case TrainingPageTabs.TRAINING_DETAILS:
                    return <TrainingDetailsTab readonly={readonly} />;
                case TrainingPageTabs.PRODUCTS_USED:
                    return <ProductsUsedTab readonly={readonly} />;
                default:
                    // TODO: add spinner
                    return <>Loading...</>;
            }
        },
        [canEdit]
    );

    const { approve, decline, requestApproval } = useTrainingState();

    useEffect(() => {
        if (Object.keys(tempValues).length) {
            formik.setValues({ ...initialValues, ...tempValues });
            setTempValues({});
        }
    }, [tempValues, initialValues]);

    useEffect(() => {
        if (tempVideoClip !== videoClip) {
            setTempVideoClip(videoClip);
        }
    }, [initialValues]);

    useEffect(() => {
        if (tempVideo !== video) {
            setTempVideo(video);
        }
    }, [initialValues]);

    useEffect(() => {
        const { duration, ...formikValues } = formik.values;

        if (duration && duration !== formik.initialValues.duration) {
            setTempValues({
                ...formikValues,
            });

            /**
             * only duration should be saved
             */
            onSubmit({
                ...formik.values,
                videoClip: tempVideoClip,
                video: tempVideo,
                duration,
            });
        }
    }, [formik.values.duration, tempVideoClip, tempVideo]);

    useEffect(() => {
        // cleanup video file from store for specific training when
        // video isn't in process of uploading
        return () => {
            trainingsStore.checkIfVideoFileExistAndClear('video', id);
            trainingsStore.checkIfVideoFileExistAndClear('videoClip', id);
        };
    }, []);

    const actions = () => {
        switch (initialValues.state) {
            case TrainingState.DRAFT:
                return !canEdit
                    ? []
                    : !formik.dirty
                    ? [
                          {
                              content:
                                  me &&
                                  getUserRole(me) ===
                                      UserRole.ROLE_AIREX_ADMIN ? (
                                      <Button
                                          color="primary"
                                          disabled={!formik.isValid || !video}
                                          label={t('publish')}
                                          onClick={() =>
                                              approve(id, {
                                                  shouldRedirectAfterSuccess: true,
                                                  path: ROUTES.ALL_TRAININGS,
                                              })
                                          }
                                      />
                                  ) : !initialValues.createdFromLivestream ? (
                                      <Button
                                          color="primary"
                                          disabled={
                                              !formik.isValid ||
                                              !tempVideoClip ||
                                              !tempVideo
                                          }
                                          label={t('request-approval')}
                                          onClick={() => formik.submitForm()}
                                      />
                                  ) : (
                                      <Button
                                          color="primary"
                                          disabled={!formik.isValid || !video}
                                          label={t('publish-as-training')}
                                          onClick={() =>
                                              approve(
                                                  id,
                                                  {
                                                      shouldRedirectAfterSuccess: true,
                                                      path:
                                                          ROUTES.ALL_TRAININGS,
                                                  },
                                                  true
                                              )
                                          }
                                      />
                                  ),
                          },
                      ]
                    : [
                          {
                              content: (
                                  <Button
                                      color="default"
                                      label={t('discard')}
                                      onClick={() =>
                                          formik.setValues(initialValues)
                                      }
                                  />
                              ),
                          },
                          {
                              content: (
                                  <Button
                                      color="primary"
                                      label={t('save')}
                                      onClick={() =>
                                          onSubmit({
                                              ...formik.values,
                                              videoClip: tempVideoClip,
                                              video: tempVideo,
                                          })
                                      }
                                  />
                              ),
                          },
                      ];
            case TrainingState.PENDING:
                return !hasPermission(PERMISSIONS.VIDEOS_PENING_APPROVAL)
                    ? []
                    : [
                          {
                              content: (
                                  <Button
                                      color="default"
                                      label={t('decline')}
                                      onClick={() => decline(id)}
                                  />
                              ),
                          },
                          {
                              content: (
                                  <Button
                                      color="primary"
                                      label={t('approve')}
                                      onClick={() => approve(id)}
                                  />
                              ),
                          },
                      ];
            case TrainingState.DECLINED:
                return [];
            case TrainingState.APPROVED:
                return !canEdit
                    ? []
                    : [
                          {
                              content: (
                                  <Button
                                      color="default"
                                      label={t('discard')}
                                      onClick={() =>
                                          formik.setValues(initialValues)
                                      }
                                  />
                              ),
                          },
                          {
                              content: (
                                  <Button
                                      disabled={!formik.dirty}
                                      color="primary"
                                      label={t('save')}
                                      onClick={() =>
                                          //   console.log(formik.values.state)
                                          onSubmit({
                                              ...formik.values,
                                              videoClip: tempVideoClip,
                                              video: tempVideo,
                                          })
                                      }
                                  />
                              ),
                          },
                      ];
        }
    };

    const header = useMemo<IPageContainerHeaderProps>(() => {
        let label;

        switch (breadcrumbPath) {
            case ROUTES.MY_TRAININGS:
                label = t('back-to-my-trainings');
                break;
            case ROUTES.ALL_TRAININGS:
                label = t('back-to-all-trainings');
                break;
            case ROUTES.PENDING_TRAININGS:
                label = t('back-to-pending-trainings');
                break;
            default:
                label = t('back-to-trainings');
                break;
        }

        return {
            breadcrumb: {
                path: breadcrumbPath,
                label,
            },
            title: initialValues.title,
            actions: actions(),
        };
    }, [
        t,
        formik.values,
        me,
        initialValues,
        formik.dirty,
        formik.isValid,
        tempVideoClip,
        tempVideo,
    ]);

    return (
        <>
            <PageContainer
                header={header}
                tabs={tabs}
                initialIndex={0}
                renderContent={(activeIndex) => (
                    <FormikProvider value={formik}>
                        {renderContent(activeIndex)}
                    </FormikProvider>
                )}
            />
            <RoutePrompt
                blockWhen={formik.dirty}
                dialogText={t('edit-dialog-text')}
            />
        </>
    );
};

export const getTrainingPage = (
    breadcrumbPath:
        | ROUTES.MY_TRAININGS
        | ROUTES.ALL_TRAININGS
        | ROUTES.PENDING_TRAININGS
): FC =>
    WithProvider(() => {
        const params = useParams<IEntity>();
        const [training, saveChanges] = useEntityById<ITraining>({
            ...params,
            forceFetch: true,
        });

        if (!training) {
            return null;
        }

        const { id, videoClip, video, ...initialValues } = training;
        const props: ITrainingPageProps = {
            id,
            videoClip,
            video,
            initialValues,
            onSubmit: (details) => saveChanges({ id, ...details }),
        };

        return <TrainingPage {...props} breadcrumbPath={breadcrumbPath} />;
    }, trainingsQuery);
