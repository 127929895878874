import { initialEntitiesState } from 'core/constants/initial-entities-state';
import { ICreateEntityOptions } from 'core/interfaces/create-entity-options.interface';
import { IUpdateEntityStateOptions } from 'core/interfaces/update-entity-state-options.interface copy';
import { updateActive } from 'core/services/updateActive';
import { shallowEqual } from 'core/utils/shallowEqual';
import { useContext } from 'react';
import { EntitiesContext } from '../constants/entities.context';
import { IDeleteEntitiesOptions } from '../interfaces/delete-entities-options.interface';
import { IEntity } from '../interfaces/entity.interface';
import { IEntityUIState } from '../state/entities.store';

export interface IUseCrud<T> {
    setSearchTerm: (searchTerm: string) => void;
    setPageSize: (pageSize: number) => void;
    setPageNumber: (pageNumber: number) => void;
    getFilter: (key: string) => any;
    patchFilters: (
        params: { [key: string]: any },
        clearCurrentPageState?: boolean
    ) => void;
    fetch: (params?: IEntityUIState) => Promise<T[]>;
    fetchById: (id: string) => Promise<T>;
    deleteMultiple: (
        ids: string[],
        options?: Partial<IDeleteEntitiesOptions>
    ) => Promise<void>;
    deleteSelected: (
        options?: Partial<IDeleteEntitiesOptions>
    ) => Promise<void>;
    updateSingle: (entity: T) => Promise<T>;
    createSingle: (
        entity: Omit<T, 'id'>,
        options?: Partial<ICreateEntityOptions>
    ) => Promise<T>;
    activate: (
        id: string,
        options?: Partial<IUpdateEntityStateOptions>
    ) => Promise<void>;
    deactivate: (
        id: string,
        options?: Partial<IUpdateEntityStateOptions>
    ) => Promise<void>;
    getById: (id: string) => Promise<T>;
    startCurrentPageListener: () => void;
    stopCurrentPageListener: () => void;
}

export const useCrud = <T extends IEntity>(): IUseCrud<T> => {
    const { query, store } = useContext(EntitiesContext);

    return {
        setSearchTerm: (searchTerm: string) => {
            const {
                ui: { searchTerm: lastSearchTerm },
            } = store.getValue();

            if (lastSearchTerm === searchTerm) {
                return;
            }

            store.patchUIState({
                searchTerm,
                pageNumber: 1,
            });
        },

        setPageSize: (pageSize: number) => {
            const {
                ui: { pageSize: lastPageSize },
            } = store.getValue();

            if (lastPageSize === pageSize) {
                return;
            }

            store.patchUIState({
                pageSize,
                pageNumber: 1,
            });
        },

        setPageNumber: (pageNumber: number) => {
            store.patchUIState({
                pageNumber,
            });
        },

        getFilter: (key: string) => {
            const { ui } = query.getValue();

            return (ui as any)[key] ?? null;
        },

        patchFilters: (
            params: { [key: string]: any },
            clearCurrentPageState = false
        ): void => {
            if (clearCurrentPageState) {
                const ui = {
                    ...(initialEntitiesState.ui ?? ({} as IEntityUIState)),
                    ...params,
                };

                if (!shallowEqual(ui, store.getValue().ui)) {
                    store.clearCurrentPageState(ui);
                }
            } else {
                store.patchUIState({
                    ...params,
                });
            }
        },

        fetch: store.fetchEntities as (params?: IEntityUIState) => Promise<T[]>,

        deleteMultiple: store.deleteMultiple,
        deleteSelected: async (options?: Partial<IDeleteEntitiesOptions>) => {
            const selectedIds = query.getActiveId();
            selectedIds && (await store.deleteMultiple(selectedIds, options));
        },

        updateSingle: store.updateEntity as (entity: IEntity) => Promise<T>,

        createSingle: (store.createEntity as never) as (
            entity: Omit<T, 'id'>,
            options?: Partial<ICreateEntityOptions>
        ) => Promise<T>,

        activate: updateActive({ store, active: true }),
        deactivate: updateActive({ store, active: false }),

        fetchById: store.fetchById as (id: string) => Promise<T>,

        getById: async (id: string) => {
            let entity = query.getEntity(id) as T;

            if (!entity) {
                entity = (await store.fetchById(id)) as T;
            }

            return entity;
        },

        startCurrentPageListener: query.startCurrentPageListener,
        stopCurrentPageListener: query.stopCurrentPageListener,
    };
};
