import { Box, FormHelperText, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button } from 'core/components/Button';
import { FormInput } from 'core/components/FormInput';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useActiveTab } from 'core/hooks/active-tab.hook';
import { useEditMode } from 'core/hooks/edit-mode.hook';
import { validateInputText } from 'core/utils/validateInputText';
import { useFormik } from 'formik';
import { IEducation } from 'modules/trainers/models/education.model';
import React, { FC, useContext, useEffect, useState } from 'react';
import './style.scss';

export interface EducationProps {
    data: Array<string>;
    onNextClick?: (data: Array<string>) => void;
    onCancelClick?: (data?: any) => void;
}

export const Education: FC<EducationProps> = ({
    data,
    onNextClick,
    onCancelClick,
}): JSX.Element => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { activeTab, updateTabsUI, updateValidatedTabIndex } = useActiveTab();
    const { updateEditMode, edit } = useEditMode();
    const [activeTabEdit, setActiveTabEdit] = useState<boolean>(false);
    const [education, setEducation] = useState<string>('');

    const [educationArray, setEducationArray] = useState<Array<string>>(
        data ?? []
    );

    useEffect(() => {
        if (activeTabEdit) {
            // if tab is edited, set validated tab index to current active tab
            // reset steps
            updateValidatedTabIndex(activeTab);
        }
    }, [activeTabEdit]);

    const handleChange = (e: any) => {
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }
        setEducation(e.target.value);
    };

    const onTabCancel = () => {
        if (edit) {
            if (onCancelClick) {
                onCancelClick();
            }
        }
    };

    const addToEducation = () => {
        if (!validateInputText(education)) {
            formik.setErrors([t('invalid-input')]);
        } else if (educationArray.length === 5) {
            formik.setErrors([t('maximum-qualifications')]);
        } else if (education !== '' && educationArray.length < 5) {
            setEducationArray([...educationArray, education]);
            formik.setValues([...educationArray, education]);
        }
        setEducation('');
    };

    const removeFromEducation = (i: number) => {
        if (!activeTabEdit) {
            updateEditMode(true);
            setActiveTabEdit(true);
        }

        setEducationArray(() => {
            return educationArray.filter((item, index) => index !== i);
        });

        formik.setValues(() => {
            return educationArray.filter((item, index) => index !== i);
        });
    };

    const formik = useFormik({
        initialValues: [...educationArray],
        enableReinitialize: true,
        onSubmit: () => {
            if (onNextClick) {
                onNextClick(educationArray);
            }
            updateTabsUI(activeTab + 1, activeTab + 1);
        },
        validate: (values) => {
            let errors: Array<string> = [];

            if (values.length === 0) {
                errors = [t('add-one-qualification')];
            }

            return errors;
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="educationContainer">
                <div className="rowContainer">
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="baseline"
                        flexWrap="wrap"
                    >
                        {/* Input with add button */}
                        <Box marginRight="35px">
                            <FormInput<IEducation>
                                label={t('completed-education')}
                                placeholder={t('enter-title')}
                                name="title"
                                type="text"
                                onChange={handleChange}
                                value={education}
                            />
                            <div className="educationButton">
                                <IconButton onClick={addToEducation}>
                                    <AddCircleIcon className="iconEducation" />
                                </IconButton>
                                <p className="buttonText">
                                    {t('add-to-qualifications-list')}
                                </p>
                            </div>
                        </Box>
                        {/* Education list */}
                        <Box flex="1 1 auto">
                            <p style={{ fontFamily: 'MarkBold' }}>
                                {t('qualifications')}
                            </p>
                            {educationArray?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="educationButton"
                                    >
                                        <p key={index} style={{ margin: 0 }}>
                                            {item}
                                        </p>
                                        <IconButton
                                            onClick={() =>
                                                removeFromEducation(index)
                                            }
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}
                            {formik.errors && formik.errors.length > 0 && (
                                <FormHelperText className="formHelperText error">
                                    {formik.errors[0]}
                                </FormHelperText>
                            )}
                        </Box>
                    </Box>
                </div>

                <div className="buttons">
                    <Button
                        type="button"
                        label={t('cancel')}
                        onClick={onTabCancel}
                        color="default"
                        style={{ width: '184px', height: '40px' }}
                    />
                    <Button
                        type="submit"
                        label={t('next')}
                        color="primary"
                        style={{ width: '184px', height: '40px' }}
                    />
                </div>
            </div>
        </form>
    );
};
