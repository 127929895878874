import { FC } from 'react';
import './style.scss';

export interface CopyrightProps {
    logo: JSX.Element;
    text: string;
    companyName: string;
}

export const Copyright: FC<CopyrightProps> = ({
    logo,
    text,
    companyName,
}): JSX.Element => {
    return (
        <div className="copyRightContainer">
            <p className="text">
                {text} {logo}
            </p>
            <p className="copyright">
                {' '}
                © {new Date().getFullYear()} {companyName}
            </p>
        </div>
    );
};
