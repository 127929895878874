import AirexLogoUser from 'assets/images/airex_logo-grey.svg';
import { FC } from 'react';
import style from './style.module.scss';

export const Thumbnail: FC<{ url: string | null; alt?: string }> = ({
    url,
    ...rest
}) => (
    <img
        className={`${style.thumbnail} ${url ? '' : style.contain}`}
        src={url ?? AirexLogoUser}
        {...rest}
    />
);
