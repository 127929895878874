import { Tab, Tabs as TabsMUI } from '@material-ui/core';
import React, { FC, useLayoutEffect, useState } from 'react';
import './style.scss';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index} {...other}>
            {value === index && children}
        </div>
    );
}

export interface TabProps {
    title: string;
    element: JSX.Element;
    disabled?: boolean;
}

export interface TabsProps {
    tabs: TabProps[];
    activeTab?: number;
    onTabClick?: (tabIndex: number) => void;
}

export const Tabs: FC<TabsProps> = ({
    tabs,
    activeTab,
    onTabClick,
}): JSX.Element => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
        activeTab ? activeTab : 0
    );

    useLayoutEffect(() => {
        if (activeTab !== selectedTabIndex) {
            setSelectedTabIndex(activeTab ?? 0);
        }
    }, [activeTab]);

    const onTabsClick = (e: any, tabIndex: number) => {
        if (onTabClick) {
            onTabClick(tabIndex);
        }
        setSelectedTabIndex(tabIndex);
    };

    return (
        <div className="tabsContainer">
            <TabsMUI
                value={selectedTabIndex}
                onChange={onTabsClick}
                variant="scrollable"
            >
                {tabs.map((tab) => {
                    return (
                        <Tab
                            key={tab.title}
                            label={tab.title}
                            className="tab"
                            disabled={tab.disabled}
                        />
                    );
                })}
            </TabsMUI>
            {tabs.map((tab, index) => {
                return (
                    <TabPanel
                        key={tab.title}
                        value={selectedTabIndex}
                        index={index}
                    >
                        {tab.element}
                    </TabPanel>
                );
            })}
        </div>
    );
};
