import { useObservable } from '@mindspace-io/react';
import { EntitiesContext } from 'core/constants/entities.context';
import { useContext } from 'react';

interface IUseEditMode {
    updateEditMode: (edit: boolean) => void;
    cleanEditMode: () => void;
    edit: boolean;
}

export const useEditMode = (): IUseEditMode => {
    const { query, store } = useContext(EntitiesContext);

    const { editMode } = store.getValue();

    const [edit] = useObservable(query.editMode$, editMode);

    const updateEditMode = (editMode: boolean) => {
        store.update({
            editMode,
        });
    };

    const cleanEditMode = () => {
        updateEditMode(false);
    };

    return {
        edit,
        updateEditMode,
        cleanEditMode,
    };
};
