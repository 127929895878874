import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { NavigationContainer } from '../../modules/navigation/components/Navigation';
import { navigationItems } from '../../modules/navigation/constants/navigation-items';
import { darkTheme, lightTheme } from '../constants/themes';
import { AirexDialogContainer } from './AirexDialog/AirexDialogContainer';
import { ConfirmationDialogContainer } from './ConfirmationDialog/ConfirmationDialogContainer';
import { ThemeSetterContext } from './ThemeSetterProvider';

const privateContentRoutes = navigationItems.filter(
    ({ contentComponent }) => contentComponent
);

export const Layout = (): JSX.Element => {
    const [theme] = useContext(ThemeSetterContext);

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <>
                <div>
                    <Box display="flex" justifyContent="flex-start">
                        <NavigationContainer />
                        <Box
                            display="flex"
                            className="flexContainer"
                            style={{
                                maxWidth: '1500px',
                                flex: '1 1 auto',
                                width: '70%',
                            }}
                        >
                            <Switch>
                                {privateContentRoutes.map(
                                    ({ path, contentComponent }) => (
                                        <Route
                                            key={path}
                                            exact
                                            path={path}
                                            component={contentComponent}
                                        ></Route>
                                    )
                                )}
                            </Switch>
                        </Box>
                    </Box>
                    <ConfirmationDialogContainer />
                    <AirexDialogContainer />
                </div>
            </>
        </ThemeProvider>
    );
};
