import { Box } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { ActionList } from 'core/components/ActionList';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { IActionProps } from 'core/interfaces/action-props.interface';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { useTrainingState } from 'modules/trainings/hooks/training-state.hook';
import {
    ITraining,
    TrainingState,
} from 'modules/trainings/models/training.model';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import React, { FC, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import style from './index.module.scss';

interface TrainingListActionsProps {
    training: ITraining;
}

export const TrainingListActions: FC<TrainingListActionsProps> = ({
    training,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );
    const { me } = useSession();
    const { deleteMultiple } = useCrud();
    const hasPermission = usePermissions();
    const { pathname } = useLocation();
    const { draft } = useTrainingState(true);

    const showEdit = useMemo(() => {
        switch (training?.state) {
            case TrainingState.APPROVED:
                return hasPermission(PERMISSIONS.EDIT_APPROVED_TRAINING);
            case TrainingState.DRAFT:
                return (
                    hasPermission(PERMISSIONS.EDIT_OWN_TRAINING) &&
                    training.createdBy === me?.id
                );
            default:
                return false;
        }
    }, [training]);

    const showDelete = useMemo(() => {
        return (
            hasPermission(PERMISSIONS.DELETE_TRAINING) ||
            (hasPermission(PERMISSIONS.DELETE_OWN_TRAINING) &&
                training.createdBy === me?.id &&
                training?.state === TrainingState.DRAFT)
        );
    }, [training]);

    const showDraft = useMemo(() => {
        return (
            hasPermission(PERMISSIONS.MOVE_DECLINED_VIDEO_TO_DRAFT) &&
            training.createdBy === me?.id &&
            training?.state === TrainingState.DECLINED
        );
    }, [training]);

    const actions = useMemo<IActionProps[]>(
        () =>
            [
                {
                    hide: !showDraft,
                    content: (
                        <button
                            className={style.actionButton}
                            onClick={() => draft(training.id)}
                        >
                            {t('move-declined-to-draft')}
                        </button>
                    ),
                },
                {
                    content: showEdit ? <Edit /> : <Visibility />,
                    path: `${pathname}/${training.id}`,
                },

                {
                    hide: !showDelete,
                    content: (
                        <Delete
                            className="with-pointer"
                            onClick={() => {
                                patchDialogProps({
                                    openDialog: true,
                                    contentText: t(
                                        'delete-training-confirmation-text'
                                    ),
                                    actions: [
                                        {
                                            content: (
                                                <Button
                                                    color="default"
                                                    style={{ width: '184px' }}
                                                    label={t('cancel')}
                                                    onClick={() =>
                                                        clearDialogProps()
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            content: (
                                                <Button
                                                    color="primary"
                                                    style={{ width: '184px' }}
                                                    label={t('delete-training')}
                                                    onClick={async () => {
                                                        try {
                                                            await deleteMultiple(
                                                                [training.id],
                                                                {
                                                                    shouldFetchAfterSuccess: true,
                                                                }
                                                            );

                                                            clearDialogProps();
                                                        } catch (e) {
                                                            console.log(e);
                                                            // TODO: handle error
                                                        }
                                                    }}
                                                />
                                            ),
                                        },
                                    ],
                                    onClose: clearDialogProps,
                                });
                            }}
                        />
                    ),
                },
            ].map(({ content, ...rest }) => ({
                ...rest,
                content: (
                    <Box marginTop="14px" marginLeft="22px">
                        {content}
                    </Box>
                ),
            })),
        [t, showEdit]
    );

    return (
        <Box display="flex" justifyContent="flex-end" width="175px">
            <ActionList actions={actions} />
        </Box>
    );
};
