import { CSSProperties, FC } from 'react';
import './style.scss';

export interface ButtonProps {
    type?: 'button' | 'submit' | 'reset';
    onClick?: (data: any) => void;
    className?: string;
    label?: string;
    disabled?: boolean;
    color?: 'default' | 'primary';
    style?: CSSProperties;
}

export const Button: FC<ButtonProps> = ({
    type,
    className,
    onClick,
    label,
    disabled,
    color,
    style,
    ...props
}): JSX.Element => {
    return (
        <button
            type={type}
            onClick={onClick}
            style={style}
            className={`${disabled ? 'disabled' : null} ${color} ${className ?? ''}`}
            disabled={disabled}
            {...props}
        >
            {label}
        </button>
    );
};
