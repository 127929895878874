import { Box } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from 'assets/images/facebook_icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/instagram_icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/images/linkedin_icon.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube_icon.svg';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { Thumbnail } from 'core/components/shared/Thumbnail';
import { SOCIAL } from 'modules/trainers/enums/socialLinks.enum';
import { ITrainer } from 'modules/trainers/models/trainer.model';
import { UserRole } from 'modules/users/constants/role-permissions';
import { fullName, getUserRole } from 'modules/users/models/user.model';
import React, { FC, Fragment, useContext, useMemo } from 'react';
import './style.scss';

export interface ITrainerDetailsProps {
    trainer: ITrainer;
}

export const TrainerDetails: FC<ITrainerDetailsProps> = ({ trainer }) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const trainerRole = useMemo(() => {
        if (!trainer.roles?.length) return null;

        switch (getUserRole(trainer)) {
            case UserRole.ROLE_AIREX_ADMIN:
                return 'Admin';
            case UserRole.ROLE_MASTER_TRAINER:
                return 'Master Trainer';
            case UserRole.ROLE_TRAINER:
                return 'Trainer';
            default:
                return null;
        }
    }, [trainer.roles]);

    const renderSocialAccount = (
        SocialIcon: React.FC<React.SVGProps<SVGSVGElement>>,
        socialUrl: string | null
    ) =>
        !socialUrl ? (
            <></>
        ) : (
            <Box display="flex" marginBottom="10px">
                <SocialIcon className="socialIcon" />
                <p className="value">{socialUrl}</p>
            </Box>
        );

    return (
        <Box className="trainerDetailsContainer">
            <Box display="flex" flexDirection="column" marginRight="60px">
                <div className="avatar">
                    <Thumbnail url={trainer.avatar} />
                </div>
            </Box>

            <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('fullname')}</p>
                    <p className="value">{fullName(trainer)}</p>
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('email')}</p>
                    <p className="value">{trainer.email}</p>
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('contact-number')}</p>
                    {trainer.contactNumber?.map(
                        (number: string, index: number) => (
                            <Fragment key={number}>
                                <p className="value">{number}</p>
                                {trainer.contactNumber &&
                                    index <
                                        trainer.contactNumber.length - 1 && (
                                        <Box marginRight="4px">,</Box>
                                    )}
                            </Fragment>
                        )
                    )}
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('languages')}</p>
                    {trainer.languages?.map(
                        (language: string, index: number) => (
                            <Fragment key={language}>
                                <p className="value">{language}</p>
                                {trainer.languages &&
                                    index < trainer.languages.length - 1 && (
                                        <Box marginRight="4px">,</Box>
                                    )}
                            </Fragment>
                        )
                    )}
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('granted-permission')}</p>
                    <p className="value">{trainerRole}</p>
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('area-of-expertise')}</p>
                    {trainer.expertise?.map(
                        (
                            expertise: { name: string; parent: number },
                            index: number
                        ) => (
                            <Fragment key={expertise.name}>
                                <p className="value">{expertise.name}</p>
                                {trainer.expertise &&
                                    index < trainer.expertise.length - 1 && (
                                        <Box marginRight="4px">,</Box>
                                    )}
                            </Fragment>
                        )
                    )}
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('education')}</p>
                    <ul className="list">
                        {trainer.education?.map((education) => (
                            <li key={education}>{education}</li>
                        ))}
                    </ul>
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('certificates')}</p>
                    <ul className="list">
                        {trainer.certificates?.map((certificate) => (
                            <li key={certificate.name}>{certificate.name}</li>
                        ))}
                    </ul>
                </Box>

                <Box display="flex" marginBottom="10px" flexWrap="wrap">
                    <p className="label">{t('social-media-accounts')}</p>
                    <Box display="flex" flexDirection="column" marginLeft="0px">
                        {!trainer.social ? (
                            <></>
                        ) : (
                            <>
                                {renderSocialAccount(
                                    FacebookIcon,
                                    trainer.social[SOCIAL.FACEBOOK]
                                )}
                                {renderSocialAccount(
                                    InstagramIcon,
                                    trainer.social[SOCIAL.INSTAGRAM]
                                )}
                                {renderSocialAccount(
                                    LinkedinIcon,
                                    trainer.social[SOCIAL.LINKEDIN]
                                )}
                                {renderSocialAccount(
                                    YoutubeIcon,
                                    trainer.social[SOCIAL.YOUTUBE]
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
