import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { WithProvider } from 'core/hocs/WithProvider';
import { IBreadcrumPathProps } from 'core/interfaces/breadcrumb-path-props.interface';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { useContext } from 'react';
import { CreateLivestreamDialog } from '../components/CreateLivestreamDialog';
import { createLivestream } from '../models/livestream.model';
import { livestreamsQuery } from '../state/livestreams.query';
import { ILivestreamPageProps, LivestreamPage } from './LivestreamPage';

export const AddLivestreamPageContainer = WithProvider(() => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { id, videoClip, ...initialValues } = createLivestream({
        title: t('new-livestream'),
    });

    const props: ILivestreamPageProps & IBreadcrumPathProps = {
        id,
        videoClip,
        initialValues,
        breadcrumbPath: ROUTES.MY_LIVESTREAMS,
    };

    return (
        <>
            <LivestreamPage {...props} />
            <CreateLivestreamDialog />
        </>
    );
}, livestreamsQuery);
