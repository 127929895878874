import { Box } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { ActionList } from 'core/components/ActionList';
import { AirexDialogContext } from 'core/components/AirexDialog/AirexDialogProvider';
import { Button } from 'core/components/Button';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { useErrorDialog } from 'core/hooks/shared/error.hook';
import { IActionProps } from 'core/interfaces/action-props.interface';
import { apiService } from 'core/services/apiService';
import { differenceInMinutes } from 'date-fns';
import { Formik, FormikErrors } from 'formik';
import { useLiveStreamState } from 'modules/livestream/hooks/livestream-state.hook';
import {
    ILivestream,
    ILivestreamDetails,
    LiveStreamLiveStatusEnum,
    LiveStreamState,
} from 'modules/livestream/models/livestream.model';
import {
    createRescheduleTimeslotCommand,
    IRescheduleTimeslotCommand,
} from 'modules/livestream/models/rescheduleTimeslot.model';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import React, { FC, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import { DatePickersComponent } from '../LivestreamDetailsTab/DatePickersComponent';
import style from './index.module.scss';

interface LivestreamListActionsProps {
    livestream: ILivestream;
}

export const LivestreamListActions: FC<LivestreamListActionsProps> = ({
    livestream,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { patchDialogProps, clearDialogProps } = useContext(
        AirexDialogContext
    );
    const { me } = useSession();
    const { deleteMultiple, fetch } = useCrud();
    const hasPermission = usePermissions();
    const { pathname } = useLocation();
    const { raiseErrorDialog } = useErrorDialog();
    const { draft } = useLiveStreamState(livestream.id, true);

    const validate = (values: Omit<ILivestreamDetails, 'videoClip'>) => {
        const errors: FormikErrors<ILivestreamDetails> = {};
        !values.startsAt && (errors.startsAt = t('livestream-24-hours'));

        // validate livestream duration
        const endTime = values.endsAt && values.endsAt.getTime();
        const startTime = values.startsAt && values.startsAt.getTime();

        if (endTime && startTime) {
            if (endTime < startTime) {
                errors.endsAt = t('livestream-start-time');
            } else {
                // calculate diff, duration must be between 30 and 60 min
                const diff = Math.abs(endTime - startTime) / 60000; // min
                if (diff < 30) {
                    errors.startsAt = t('livestream-30-min');
                }
                if (diff > 60) {
                    errors.startsAt = t('livestream-60-min');
                }
            }
        }

        // validate startsAt 24h from now
        if (startTime) {
            const currentDate = new Date();
            const tommorowDate = new Date();
            tommorowDate.setDate(currentDate.getDate() + 1);
            if (startTime < tommorowDate.getTime()) {
                errors.startsAt = t('livestream-24-hours');
            }
        }

        return errors;
    };

    const showEdit = useMemo(() => {
        return livestream?.state !== LiveStreamState.DRAFT
            ? false
            : hasPermission(PERMISSIONS.EDIT_LIVESTREAM) ||
                  (hasPermission(PERMISSIONS.EDIT_OWN_LIVESTREAM) &&
                      livestream.createdBy === me?.id);
    }, [livestream]);

    const showDelete = useMemo(() => {
        return (
            hasPermission(PERMISSIONS.DELETE_LIVESTREAM) ||
            (hasPermission(PERMISSIONS.DELETE_OWN_LIVESTREAM) &&
                livestream.createdBy === me?.id &&
                livestream?.state === LiveStreamState.DRAFT)
        );
    }, [livestream]);

    const showDraft = useMemo(() => {
        return (
            hasPermission(PERMISSIONS.MOVE_DECLINED_VIDEO_TO_DRAFT) &&
            livestream.createdBy === me?.id &&
            livestream?.state === LiveStreamState.DECLINED
        );
    }, [livestream]);

    const showReschedule = useMemo(() => {
        return (
            livestream.startsAt &&
            differenceInMinutes(livestream.startsAt, new Date()) >= 120 &&
            hasPermission(PERMISSIONS.RESCHEDULE_OWN_LIVESTREAM) &&
            livestream.createdBy === me?.id &&
            livestream.liveStatus !== LiveStreamLiveStatusEnum.PASSED &&
            livestream?.state === LiveStreamState.APPROVED &&
            !livestream.canceled
        );
    }, [livestream]);

    const showCancel = useMemo(() => {
        return (
            !livestream.canceled &&
            livestream.liveStatus !== LiveStreamLiveStatusEnum.PASSED &&
            livestream?.state === LiveStreamState.APPROVED &&
            (hasPermission(PERMISSIONS.CANCEL_LIVESTREAM) ||
                (hasPermission(PERMISSIONS.CANCEL_OWN_LIVESTREAM) &&
                    livestream.createdBy === me?.id))
        );
    }, [livestream]);

    const rescheduleLivestream = () => {
        patchDialogProps({
            openDialog: true,
            contentText: t('reschedule-livestream'),
            content: (
                <Formik
                    initialValues={livestream}
                    initialErrors={validate(livestream)}
                    enableReinitialize={true}
                    validateOnMount={true}
                    validate={validate}
                    onSubmit={(values) => {
                        if (values.startsAt && values.endsAt) {
                            try {
                                apiService.put<any, IRescheduleTimeslotCommand>(
                                    `/trainings/livestreams/${livestream.id}/reschedule`,
                                    createRescheduleTimeslotCommand({
                                        startsAt: values.startsAt,
                                        endsAt: values.endsAt,
                                    })
                                );
                                clearDialogProps();
                                // fetch new entitites after successful cancel
                                fetch();
                            } catch (e: any) {
                                raiseErrorDialog(e);
                            }
                        }
                    }}
                >
                    {(props) => (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <DatePickersComponent
                                readonly={false}
                                validateTakenTimeslot={false}
                            />
                            <Box margin="32px auto 0 auto">
                                <Button
                                    color="default"
                                    style={{ width: '184px' }}
                                    label={t('cancel')}
                                    onClick={() => clearDialogProps()}
                                />
                                <Button
                                    color="primary"
                                    style={{
                                        width: '184px',
                                        marginLeft: '16px',
                                    }}
                                    label={'Reschedule'}
                                    disabled={!props.isValid || !props.dirty}
                                    type="submit"
                                    onClick={props.handleSubmit}
                                />
                            </Box>
                        </Box>
                    )}
                </Formik>
            ),
            onClose: clearDialogProps,
        });
    };

    const cancelLivestream = async () => {
        if (livestream.liveStatus === LiveStreamLiveStatusEnum.UPCOMING) {
            patchDialogProps({
                openDialog: true,
                contentText: t('cancel-livestream-title'),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                style={{ width: '184px' }}
                                label={t('no')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                style={{ width: '184px' }}
                                label={t('yes')}
                                onClick={async () => {
                                    try {
                                        await apiService.put(
                                            `trainings/livestreams/${livestream.id}/cancel`,
                                            livestream.id
                                        );
                                        clearDialogProps();
                                        // fetch new entitites after successful cancel
                                        fetch();
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else if (livestream.liveStatus === LiveStreamLiveStatusEnum.LIVE) {
            patchDialogProps({
                openDialog: true,
                contentText: hasPermission(PERMISSIONS.CANCEL_LIVESTREAM)
                    ? t('cancel-started-livestream-admin')
                    : t('cancel-startet-livestream-master-trainer'),
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                style={{ width: '184px' }}
                                label={t('close')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        }
    };

    const deleteLivestream = () => {
        if (livestream.liveStatus === LiveStreamLiveStatusEnum.LIVE) {
            patchDialogProps({
                openDialog: true,
                contentText: t('delete-ongoing-livestream'),
                actions: [
                    {
                        content: (
                            <Button
                                color="primary"
                                style={{ width: '184px' }}
                                label={t('close')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        } else {
            patchDialogProps({
                openDialog: true,
                contentText: t('delete-livestream-confirmation-text'),
                actions: [
                    {
                        content: (
                            <Button
                                color="default"
                                style={{ width: '184px' }}
                                label={t('cancel')}
                                onClick={() => clearDialogProps()}
                            />
                        ),
                    },
                    {
                        content: (
                            <Button
                                color="primary"
                                style={{ width: '184px' }}
                                label={t('delete-livestream')}
                                onClick={async () => {
                                    try {
                                        await deleteMultiple([livestream.id], {
                                            shouldFetchAfterSuccess: true,
                                        });

                                        clearDialogProps();
                                    } catch (e) {
                                        raiseErrorDialog(e);
                                    }
                                }}
                            />
                        ),
                    },
                ],
                onClose: clearDialogProps,
            });
        }
    };
    const actions = useMemo<IActionProps[]>(
        () =>
            [
                {
                    hide: !showDraft,
                    content: (
                        <button
                            className={style.actionButton}
                            onClick={() => draft(livestream.id)}
                        >
                            {t('move-declined-to-draft')}
                        </button>
                    ),
                },
                {
                    content: showEdit ? <Edit /> : <Visibility />,
                    path: `${pathname}/${livestream.id}`,
                },
                {
                    hide: !showDelete,
                    content: (
                        <Delete
                            className="with-pointer"
                            onClick={deleteLivestream}
                        />
                    ),
                },
            ].map(({ content, ...rest }) => ({
                ...rest,
                content: (
                    <Box marginTop="14px" marginLeft="22px">
                        {content}
                    </Box>
                ),
            })),
        [t, showEdit]
    );

    return (
        <Box display="flex" flexDirection="column" flexWrap="wrap" flex="2">
            <Box display="flex" justifyContent="flex-end">
                <ActionList actions={actions} />
            </Box>
            <Box display="flex" justifyContent="flex-end" flexWrap="wrap">
                {showReschedule && (
                    <Button
                        color="primary"
                        style={{ width: '140px' }}
                        label={t('reschedule')}
                        onClick={() => rescheduleLivestream()}
                    />
                )}
                {showCancel && (
                    <Button
                        color="default"
                        style={{ width: '140px' }}
                        label={t('cancel-livestream')}
                        onClick={cancelLivestream}
                    />
                )}
            </Box>
        </Box>
    );
};
