import { Thumbnail } from 'core/components/shared/Thumbnail';
import { ITrainer } from 'modules/trainers/models/trainer.model';
import React, { FC } from 'react';
import style from './TrainerListItemAvatar.module.scss';

export const TrainerListItemAvatar: FC<{ trainer: ITrainer }> = ({
    trainer,
}) => {
    return (
        <div className={style.imageContainer}>
            <Thumbnail url={trainer.avatar} />
        </div>
    );
};
