import React, { createContext, FC, useReducer } from 'react';
import { AirexDialogProps } from '.';

interface IUseConfirmationDialog {
    props: AirexDialogProps;
    setDialogProps: (props: Partial<AirexDialogProps>) => void;
    patchDialogProps: (props: Partial<AirexDialogProps>) => void;
    clearDialogProps: () => void;
}

const initialConfirmationDialogProps: AirexDialogProps = {
    openDialog: false,
    style: {},
    title: '',
    contentText: '',
    content: <></>,
    actions: [],
};

function reducer(
    state: AirexDialogProps,
    action: {
        type: 'patch' | 'set' | 'clear';
        partialState?: Partial<AirexDialogProps>;
    }
) {
    switch (action.type) {
        case 'patch':
            return { ...state, ...action.partialState };
        case 'set':
            return {
                ...initialConfirmationDialogProps,
                ...action.partialState,
            };
        case 'clear':
            return initialConfirmationDialogProps;
        default:
            throw new Error();
    }
}

export const AirexDialogContext = createContext<IUseConfirmationDialog>(
    {} as IUseConfirmationDialog
);

export const AirexDialogProvider: FC = ({ children }) => {
    const [props, dispatch] = useReducer(
        reducer,
        initialConfirmationDialogProps
    );

    const setDialogProps = (partialState: Partial<AirexDialogProps>) => {
        dispatch({ type: 'set', partialState });
    };

    const patchDialogProps = (partialState: Partial<AirexDialogProps>) => {
        dispatch({ type: 'patch', partialState });
    };

    const clearDialogProps = () => {
        dispatch({ type: 'clear' });
    };

    const value = {
        props,
        setDialogProps,
        patchDialogProps,
        clearDialogProps,
    };

    return (
        <AirexDialogContext.Provider value={value}>
            {children}
        </AirexDialogContext.Provider>
    );
};
