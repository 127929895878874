import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import VideocamIcon from '@material-ui/icons/Videocam';
import { useObservable } from '@mindspace-io/react';
import { ReactComponent as AirexAcademy } from 'assets/images/airex_academy.svg';
import { ReactComponent as AirexLogo } from 'assets/images/airex_logo.svg';
import { ConfirmationDialogContext } from 'core/components/ConfirmationDialog/ConfirmationDialogProvider';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { WithProvider } from 'core/hocs/WithProvider';
import { useEditMode } from 'core/hooks/edit-mode.hook';
import { useSession } from 'core/hooks/session.hook';
import { livestreamsQuery } from 'modules/livestream/state/livestreams.query';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { trainersQuery } from 'modules/trainers/state/trainers.query';
import { trainingsQuery } from 'modules/trainings/state/trainings.query';
import { getUserRole } from 'modules/users/models/user.model';
import { FC, useCallback, useContext, useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { navigationItems } from '../constants/navigation-items';
import { ROUTES } from '../enums/routes.enum';
import './style.scss';
import { UserInfo } from './UserInfo';

interface IDetectionItem {
    dialogText: string;
    condition: boolean;
}

const Navigation: FC = () => {
    const hasPermission = usePermissions();
    const { t } = useContext(MultiLanguageSupportContext);
    const { edit, updateEditMode } = useEditMode();
    const { push } = useHistory();
    const location = useLocation();
    const { me } = useSession();
    const { setConfirmationDialogProps } = useContext(
        ConfirmationDialogContext
    );
    const [areAnyVideoInProgress] = useObservable(
        trainingsQuery.areAnyVideoInProgress$,
        false
    );
    const [areAnyLivestreamInProgress] = useObservable(
        livestreamsQuery.areAnyLivestreamInProgress$,
        false
    );

    const checkRoute = (pathname: string, path: string) =>
        pathname.split('/').pop() !== 'add' && path.split('/').reverse()[0]
            ? pathname.includes(path.split('/').reverse()[0])
            : pathname === path;

    // Initialy set edit mode on false to clean previous state from store
    useEffect(() => {
        updateEditMode(false);
    }, []);

    /**
     * The need for this implementation is when there are multiple detection items, we should ask the user for each if they are sure about aborting them.
     *
     * For instance:
     * The user starts uploading a video and then navigates to create a trainer and starts adding data.
     * The user then press logout.
     *
     * When we have two detection items whose conditions are met and we need to show confirmation dialogs for both.
     * This solution checks all of them in an serial manner.
     * The skipUntilDetectionItemIndex is used to determine from which detection item to start from (or rather, which detection items to skip).
     */
    let leavePage = false;
    const onNavClick = useCallback(
        (path: string, skipUntilDetectionItemIndex = 0) => {
            if (location.pathname === path) {
                return;
            }
            const detectionItems: IDetectionItem[] = [
                { dialogText: t('edit-dialog-text'), condition: edit },
                {
                    dialogText: t('video-upload-in-progress-warning'),
                    condition: path === ROUTES.LOGIN && areAnyVideoInProgress,
                },
            ];

            for (let i = 0; i < detectionItems.length; i++) {
                const { dialogText, condition } = detectionItems[i];

                if (skipUntilDetectionItemIndex <= i && condition) {
                    setConfirmationDialogProps({
                        openDialog: true,
                        dialogText,
                        leftButtonLabel: t('yes'),
                        rightButtonLabel: t('cancel'),
                        onLeftClick: () => {
                            leavePage = true;
                            onNavClick(path, i + 1);
                        },
                        onRightClick: () => {
                            leavePage = false;
                            closeDialog();
                        },
                        onClose: () => {
                            leavePage = false;
                            closeDialog();
                        },
                        closeByDefault: false,
                    });

                    return;
                }
            }

            setConfirmationDialogProps({
                openDialog: false,
            });

            if (leavePage) {
                push(path);
            }
        },
        [
            edit,
            location,
            areAnyVideoInProgress,
            setConfirmationDialogProps,
            push,
        ]
    );

    const closeDialog = () => {
        setConfirmationDialogProps({
            openDialog: false,
        });
    };

    const userManagementSection = navigationItems
        .filter(({ section }) => section === 'User Management')
        .map(({ path, translateKey, label, permission }) => {
            if (permission && hasPermission(permission)) {
                return (
                    <div key={translateKey} className="menuLinkContainer">
                        <NavLink
                            onClick={() => onNavClick(path)}
                            className="menuLink"
                            isActive={() => checkRoute(location.pathname, path)}
                            to={(location) => {
                                if (edit) {
                                    return {
                                        ...location,
                                        state: null,
                                    };
                                }

                                return {
                                    ...location,
                                    pathname: path,
                                    state: null,
                                };
                            }}
                            exact
                        >
                            {translateKey ? t(translateKey) : label}
                        </NavLink>
                    </div>
                );
            }
        });

    const trainingVideosSection = navigationItems
        .filter(({ section }) => section === 'Training Videos')
        .map(({ path, translateKey, label, permission }) => {
            if (permission && hasPermission(permission)) {
                return (
                    <div key={translateKey} className="menuLinkContainer">
                        <NavLink
                            onClick={() => onNavClick(path)}
                            className="menuLink"
                            isActive={() => checkRoute(location.pathname, path)}
                            to={(location) => {
                                if (edit) {
                                    return {
                                        ...location,
                                        state: null,
                                    };
                                }

                                return {
                                    ...location,
                                    pathname: path,
                                    state: null,
                                };
                            }}
                            exact
                        >
                            {translateKey ? t(translateKey) : label}
                        </NavLink>
                    </div>
                );
            }
        });

    const trainingLivestreamSection = navigationItems
        .filter(({ section }) => section === 'Livestream')
        .map(({ path, translateKey, label, permission }) => {
            if (permission && hasPermission(permission)) {
                return (
                    <div key={translateKey} className="menuLinkContainer">
                        <NavLink
                            onClick={() => onNavClick(path)}
                            className="menuLink"
                            isActive={() => checkRoute(location.pathname, path)}
                            to={(location) => {
                                if (edit) {
                                    return {
                                        ...location,
                                        state: null,
                                    };
                                }

                                return {
                                    ...location,
                                    pathname: path,
                                    state: null,
                                };
                            }}
                            exact
                        >
                            {translateKey ? t(translateKey) : label}
                        </NavLink>
                    </div>
                );
            }
        });

    return (
        <div className="menuContainer">
            <div className="menu">
                <AirexLogo
                    className="with-pointer"
                    onClick={() => push(ROUTES.DEFAULT)}
                />
                <UserInfo />
                <div className="menuSectionContainer">
                    {userManagementSection && userManagementSection.length > 0 && (
                        <div className="menuSection">
                            <AccountCircleIcon />
                            <div className="menuSectionText">
                                {t('user-management-nav')}
                            </div>
                        </div>
                    )}
                    {userManagementSection}
                </div>
                <div className="menuSectionContainer">
                    {trainingVideosSection && trainingVideosSection.length > 0 && (
                        <div className="menuSection">
                            <PlayCircleFilledIcon />
                            <div className="menuSectionText">
                                {t('training-vides-nav')}
                            </div>
                        </div>
                    )}
                    {trainingVideosSection}
                </div>
                <div className="menuSectionContainer">
                    {me &&
                        getUserRole(me) !== 'ROLE_TRAINER' &&
                        trainingLivestreamSection &&
                        trainingLivestreamSection.length > 0 && (
                            <div className="menuSection">
                                <VideocamIcon />
                                <div className="menuSectionText">
                                    {t('training-livestream-nav')}
                                </div>
                            </div>
                        )}
                    {trainingLivestreamSection}
                </div>
                <div className="dividerSectionContainer"></div>
                <div className="logoutSectionContainer">
                    <PowerSettingsNewIcon />
                    <NavLink
                        className="logout"
                        onClick={() => onNavClick(ROUTES.LOGIN)}
                        to={(location) => {
                            if (!edit && !areAnyVideoInProgress) {
                                return {
                                    ...location,
                                    pathname: ROUTES.LOGIN,
                                    state: null,
                                };
                            }

                            return {
                                ...location,
                                state: null,
                            };
                        }}
                    >
                        {t('log-out')}
                    </NavLink>
                </div>
                <div className="poweredByContainer">
                    <p className="text">
                        {t('powered-by')} <AirexAcademy />
                    </p>
                </div>
            </div>
        </div>
    );
};

export const NavigationContainer = WithProvider(Navigation, trainersQuery);
