import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IActionProps } from '../interfaces/action-props.interface';

interface IActionListProps {
    actions: IActionProps[];
    data?: any;
}

export const ActionListItem = ({
    renderContent,
    content,
    path,
    data,
}: IActionProps): JSX.Element => {
    return !path ? (
        renderContent ? (
            renderContent(data)
        ) : (
            content ?? <></>
        )
    ) : (
        <Link className="unlinkify" to={path}>
            {content}
        </Link>
    );
};

export const ActionList: FC<IActionListProps> = ({ actions, data }) => (
    <>
        {actions
            .filter(({ hide }) => !hide)
            .map((action, index) => (
                <ActionListItem key={index} {...action} data={data} />
            ))}
    </>
);
