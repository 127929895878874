import { Box } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { Thumbnail } from 'core/components/shared/Thumbnail';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { ITrainer } from 'modules/trainers/models/trainer.model';
import { UserRole } from 'modules/users/constants/role-permissions';
import { fullName, getUserRole } from 'modules/users/models/user.model';
import { FC, useContext, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './style.scss';

export interface TrainerCardProps {
    trainer: ITrainer;
}

export const TrainerCard: FC<TrainerCardProps> = ({ trainer }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { push } = useHistory();

    // TODO move to user model as helper function
    const trainerRole = useMemo(() => {
        if (!trainer.roles?.length) return null;

        switch (getUserRole(trainer)) {
            case UserRole.ROLE_AIREX_ADMIN:
                return 'Admin';
            case UserRole.ROLE_MASTER_TRAINER:
                return 'Master Trainer';
            case UserRole.ROLE_TRAINER:
                return 'Trainer';
            default:
                return null;
        }
    }, [trainer.roles]);

    return (
        <Box
            display="flex"
            className="trainerCardContainer"
            onClick={() =>
                push(pathBuilder(ROUTES.TRAINER_VIEW, ':id', trainer.id))
            }
        >
            <Box display="flex" flexDirection="column" marginRight="20px">
                <div className="avatarContainer">
                    <Thumbnail url={trainer.avatar} />
                </div>
            </Box>

            <Box display="flex" flexDirection="column" flex="1">
                <h3>{fullName(trainer)}</h3>

                <ul className="list">
                    <li>{trainerRole}</li>
                    {trainer.expertise?.map((expertise) => (
                        <li key={expertise.name}>{expertise.name}</li>
                    ))}
                </ul>

                <Link
                    className="unlinkify"
                    to={pathBuilder(ROUTES.TRAINER_VIEW, ':id', trainer.id)}
                >
                    <b>
                        <p>{t('view-profile')}</p>
                    </b>
                </Link>
            </Box>
        </Box>
    );
};
