import {
    akitaDevtools,
    persistState,
    PersistStateParams,
} from '@datorama/akita';
import { AirexDialogProvider } from 'core/components/AirexDialog/AirexDialogProvider';
import { ConfirmationDialogProvider } from 'core/components/ConfirmationDialog/ConfirmationDialogProvider';
import { MultiLanguageSupportProvider } from 'core/components/MultiLanguageSupportProvider';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { debounceTime } from 'rxjs/operators';
import { App } from './App';
import { ThemeSetterProvider } from './core/components/ThemeSetterProvider';
import './core/utils/multi-language-support';
import { reportWebVitals } from './reportWebVitals';

const persistStateParams: Partial<PersistStateParams> = {
    include: ['session', 'users'],
};

if (process.env.NODE_ENV !== 'production') {
    akitaDevtools();
} else {
    persistStateParams.preStorageUpdateOperator = () => debounceTime(2000);
}

persistState(persistStateParams);

/**
 * Issue: https://www.gitmemory.com/issue/datorama/akita/634/789712235
 *
 * workaround is to define the resettable flag at store declaration via decorator
 */
// akitaConfig({ resettable: true });

/**
 * We need to wrap app with Suspense because of the translation support,
 * as it loads translation files asynchronously
 */

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={null}>
            <MultiLanguageSupportProvider>
                <ThemeSetterProvider>
                    <AirexDialogProvider>
                        <ConfirmationDialogProvider>
                            <App />
                        </ConfirmationDialogProvider>
                    </AirexDialogProvider>
                </ThemeSetterProvider>
            </MultiLanguageSupportProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
