import { Dialog, DialogContent } from '@material-ui/core';
import './style.scss';

interface ILivestreamVideoDialogProps {
    open: boolean;
    title: string;
    body: string;
    renderButtons?: () => JSX.Element;
}

const LivestreamVideoDialog = ({
    open,
    title,
    body,
    renderButtons,
}: ILivestreamVideoDialogProps): JSX.Element => (
    <Dialog open={open} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <div className="uploadFormDialogContainer">
            <DialogContent>
                <div className="dialogBox">
                    <div className="dialogTitle">{title}</div>
                    <div className="dialogMess">{body}</div>
                    {renderButtons && (
                        <div className="dialogButtons">{renderButtons()}</div>
                    )}
                </div>
            </DialogContent>
        </div>
    </Dialog>
);

export default LivestreamVideoDialog;
