import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import ZoomClientContext from 'core/components/ZoomClientContext';
import { WithProvider } from 'core/hocs/WithProvider';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { useSession } from 'core/hooks/session.hook';
import { apiService } from 'core/services/apiService';
import { usePermissions } from 'modules/login/hooks/permissions.hook';
import { UserRole } from 'modules/users/constants/role-permissions';
import { PERMISSIONS } from 'modules/users/models/permissions.model';
import { fullName } from 'modules/users/models/user.model';
import { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import LivestreamVideoAdmin from '../components/LivestreamVideoComp/LivestreamVideoAdmin';
import LivestreamVideoHost from '../components/LivestreamVideoComp/LivestreamVideoHost';
import { LivestreamStatusEnum } from '../enums';
import { useLivestream } from '../hooks/livestream.hook';
import { IStream } from '../interfaces';
import { ILivestream, ILivestreamResponse } from '../models/livestream.model';
import { livestreamsQuery } from '../state/livestreams.query';

export const LivestreamVideo = WithProvider<ILivestream, ILivestreamResponse>(
    withRouter(({ match }) => {
        const { t } = useContext(MultiLanguageSupportContext);
        const { me } = useSession();
        const hasPermission = usePermissions();

        const zmClient = useContext(ZoomClientContext);
        const [isJoined, setIsJoined] = useState<boolean | null>(null);
        const [initStarted, setInitStarted] = useState<boolean>(false);

        const [livestream, setLivestream] = useEntityById<ILivestream>({
            id: match.params.id,
        });

        const { jwt } = useLivestream(livestream ?? { id: match.params.id }, {
            autoJoin: true,
        });

        const userName = !me
            ? ''
            : livestream?.createdBy === me.id
            ? fullName(me) + ' - ' + t('airex-trainer')
            : hasPermission(PERMISSIONS.LIVESTREAM_SESSION_ADMIN_ACCESS)
            ? UserRole.ROLE_AIREX_ADMIN
            : '';

        useEffect(() => {
            if (!livestream?.stream || !jwt || !userName || initStarted) {
                return;
            }

            setInitStarted(true);

            const { stream } = livestream;

            try {
                zmClient.init('en-US', 'Global').then(async () => {
                    try {
                        await zmClient.join(stream.name_slug, jwt, userName);

                        setIsJoined(true);
                    } catch (e: any) {
                        console.log(e);

                        switch (e.type) {
                            case 'INVALID_OPERATION':
                                if (e.reason === 'duplicated operation') {
                                    window.location.reload();
                                    return;
                                }
                                break;
                        }

                        setIsJoined(false);
                    }
                });
            } catch (e) {
                console.log('Init', e);
            }
        }, [livestream, jwt, userName, initStarted]);

        if (!livestream) {
            return <>Loading...</>;
        } else if (
            !livestream.stream ||
            livestream.stream.status !== LivestreamStatusEnum.IN_PROGRESS
        ) {
            return <Redirect to={'/my-livestream-videos/' + livestream.id} />;
        }

        if (isJoined === null) {
            return <>Loading...</>;
        }

        const allowJoin = async (join_allowed: boolean) => {
            const stream = await apiService
                .put<IStream>(`streams/` + livestream.id, {
                    join_allowed,
                })
                .then(apiService.responseHandler);

            setLivestream({ ...livestream, stream }, { localOnly: true });
        };

        if (livestream.createdBy === me?.id) {
            return (
                <LivestreamVideoHost
                    allowJoin={allowJoin}
                    id={livestream.id}
                    stream={livestream.stream}
                />
            );
        }

        if (hasPermission(PERMISSIONS.LIVESTREAM_SESSION_ADMIN_ACCESS)) {
            return (
                <LivestreamVideoAdmin
                    id={livestream.id}
                    stream={livestream.stream}
                />
            );
        }

        zmClient.leave();

        return <Redirect to={'/my-livestream-videos/' + livestream.id} />;
    }),
    livestreamsQuery
);
