import { Box, FormControlLabel, FormHelperText } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import { useFormikContext } from 'formik';
import { useProducts } from 'modules/products/hooks/products.hook';
import { ITrainingDetails } from 'modules/trainings/models/training.model';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import './style.scss';

export const ProductsUsedTab: FC<IReadonlyProps> = ({ readonly }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const formik = useFormikContext<ITrainingDetails>();
    const [productCategory, setProductCategory] = useState<string | null>(null);
    const [products] = useProducts();

    const productCategories = useMemo(
        () =>
            Array.from(
                products.reduce((obj: Set<string>, product) => {
                    obj.add(product.category);
                    return obj;
                }, new Set<string>())
            ),
        [products]
    );

    const filteredProducts = useMemo(
        () =>
            productCategory
                ? products.filter(
                      ({ category }) => category === productCategory
                  )
                : products,
        [products, productCategory]
    );

    const isSelected = useCallback(
        (productId: string) =>
            formik.values.productIds.indexOf(productId) !== -1,
        [formik.values.productIds]
    );

    return (
        <>
            <Box
                className="selectProductsContainer"
                display="flex"
                flexDirection="column"
            >
                <label className="formLabel">{t('product-category')}</label>
                <select
                    name="productsCategory"
                    className="selectBox"
                    value={productCategory ?? ''}
                    onChange={(e) => setProductCategory(e.target.value)}
                    required
                >
                    <option value="">{t('all-products')}</option>
                    {productCategories.map((category) => (
                        <option key={category} value={category}>
                            {category}
                        </option>
                    ))}
                </select>
            </Box>

            <Box className="checkboxContainer">
                {filteredProducts.map((product) => (
                    <FormControlLabel
                        key={product.id}
                        style={{ marginRight: '30px' }}
                        label={
                            <Box className="checkboxLabel">{product.name}</Box>
                        }
                        control={
                            <StyledCheckbox
                                name="productIds"
                                value={product.id}
                                checked={isSelected(product.id)}
                                onChange={formik.handleChange}
                                disabled={readonly}
                            />
                        }
                    />
                ))}
            </Box>
            {!formik.values.productIds.length && (
                <FormHelperText className="formHelperText error">
                    {formik.errors.productIds}
                </FormHelperText>
            )}
        </>
    );
};

const StyledCheckbox = withStyles({
    root: {
        color: '#000',
        '&$checked': {
            color: '#000',
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
