import { Box, FormHelperText } from '@material-ui/core';
import { FormInput } from 'core/components/FormInput';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ThumbnailUpload } from 'core/components/ThumbnailUpload';
import { IEntity } from 'core/interfaces/entity.interface';
import { IReadonlyProps } from 'core/interfaces/readonly-props.interface';
import { encodeImageFileAsURL } from 'core/utils/encodeImageFileAsURL';
import { useFormikContext } from 'formik';
import { ITrainingDetails } from 'modules/trainings/models/training.model';
import { trainingsStore } from 'modules/trainings/state/trainings.store';
import React, { FC, useContext } from 'react';
import './style.scss';
import { UploadVideo } from './UploadVideoInput';

type IUploadVideoTabProps = IReadonlyProps &
    IEntity & {
        disableUploadVideo: boolean;
    };

export const UploadVideoTab: FC<IUploadVideoTabProps> = ({
    readonly,
    disableUploadVideo,
    id,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const formik = useFormikContext<ITrainingDetails>();

    const handleImageChange = (file: File | null) => {
        !file
            ? formik.setFieldValue('screen', formik.initialValues.screen)
            : encodeImageFileAsURL(file, (result) =>
                  formik.setFieldValue('screen', result as string)
              );
    };

    const handleDurationChange = (duration: string | null) => {
        if (formik.values.video && duration && !formik.values.duration) {
            formik.setFieldValue('duration', duration);
        }
    };

    const handleUploadCompleted = (duration: string | null) => {
        if (duration && duration !== formik.initialValues.duration) {
            formik.setFieldValue('duration', duration);
        } else {
            /**
             * if the duration has not changed,
             * fetch training to get the new signed video url
             */
            trainingsStore.fetchById(id);
        }
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <FormInput<ITrainingDetails>
                    label={t('video-title')}
                    name="title"
                    type="text"
                    value={formik.values.title}
                    readOnly={true}
                />

                <Box px="20px" />

                <FormInput<ITrainingDetails>
                    label={t('trainer-name')}
                    name="trainerName"
                    type="text"
                    value={formik.values.trainerName}
                    readOnly={true}
                />
            </Box>

            <Box pt="20px" />

            <Box className="uploadVideoTabContainer">
                <Box className="column">
                    <Box display="flex" justifyContent="space-between">
                        <p className="bold">{t('upload-video-clip')}</p>
                        <p className="light">{t('short-workout-teaser')}</p>
                    </Box>

                    <UploadVideo
                        id={id}
                        uploadEntityKey="videoClip"
                        readonly={readonly}
                    />
                </Box>

                <Box className="column">
                    <p className="bold">{t('upload-thumbnail')}</p>

                    <ThumbnailUpload
                        url={formik.values.screen}
                        reset={
                            formik.values.screen === formik.initialValues.screen
                        }
                        onChange={handleImageChange}
                        readonly={readonly}
                        renderSupportedFile={() => (
                            <>
                                <p>{t('supported-image-types')}</p>
                                <p> {t('maximum-image-size')}</p>
                                <p>
                                    {t('format', {
                                        format: `16:9`,
                                    })}
                                </p>
                            </>
                        )}
                    />
                    {!formik.values.screen && (
                        <FormHelperText className="formHelperText error">
                            {formik.errors.screen}
                        </FormHelperText>
                    )}
                </Box>
            </Box>
            <Box>
                <Box className="column">
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <p className="bold">{t('upload-video-title')}</p>
                        <p className="light">{t('full-workout-video')}</p>
                    </Box>

                    <UploadVideo
                        id={id}
                        uploadEntityKey="video"
                        durationLabel={formik.values.duration}
                        onUploadCompleted={handleUploadCompleted}
                        setDurationLabel={handleDurationChange}
                        readonly={disableUploadVideo}
                    />
                </Box>
            </Box>
        </>
    );
};
