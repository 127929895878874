import { combineQueries, EntityUIQuery } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntitiesQuery } from '../../../core/state/entities.query';
import { ILivestream } from '../models/livestream.model';
import {
    ILivestreamVideoUploadState,
    LivestreamsStore,
    livestreamsStore,
} from './livestreams.store';

export class LivestreamsQuery extends EntitiesQuery<ILivestream> {
    ui!: EntityUIQuery<ILivestreamVideoUploadState>;

    readonly role$ = this.select(({ ui }) => (ui as any).role);
    readonly state$ = this.select(({ ui }) => (ui as any).state);
    readonly shuoldFetchEntities$ = combineQueries([
        this.searchTerm$,
        this.pageSize$,
        this.pageNumber$,
        this.role$,
        this.state$,
    ]);
    readonly areAnyLivestreamInProgress$: Observable<boolean>;

    constructor(store: LivestreamsStore) {
        super(store);

        this.createUIQuery();

        this.areAnyLivestreamInProgress$ = this.ui.selectAll().pipe(
            // map((entities) => entities.some((entity) => Object.keys(entity).filter((key) => entity[key as ILivestreamVideoUploadStateKey].progress !== null).length ))
            map((entities) =>
                entities.some(
                    (entity) =>
                        entity.video.progress !== null ||
                        entity.videoClip.progress !== null
                )
            )
        );
    }
}

export const livestreamsQuery = new LivestreamsQuery(livestreamsStore);
