import { applyTransaction, Store, StoreConfig } from '@datorama/akita';
import { IUser } from 'modules/users/models/user.model';
import { usersStore } from 'modules/users/state/users.store';
import { ISession } from '../models/session.model';

const createInitialState = (): ISession => {
    return {
        token: null,
        refreshToken: null,
        me: null,
        error: null,
    };
};

@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<ISession> {
    constructor() {
        super(createInitialState());
    }

    handleToken({
        token,
        refreshToken,
        user,
    }: Omit<ISession, 'me' | 'error'> & { user: IUser }): void {
        applyTransaction(() => {
            usersStore.upsert(user.id, user);
            this.update({ token, refreshToken, me: user.id });
            this.setError(null);
        });
    }
}

export const sessionStore = new SessionStore();
