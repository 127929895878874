import { EntitiesState, IEntityUIState } from '../../core/state/entities.store';
import { pageSizeOptions } from './page-size-options';

export const initialEntitiesState: Partial<
    EntitiesState<any, IEntityUIState>
> = {
    ui: {
        searchTerm: '',
        pageNumber: 1,
        pageSize: pageSizeOptions[0],
    },
    total: null,
    highlightNext: null,
    currentPageIds: [],
    active: [],
    activeTabIndex: 0,
    validatedTabIndex: 0,
    editMode: false,
};
