import { Box } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { apiService } from 'core/services/apiService';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import {
    createTrainingFromResponse,
    ITraining,
    ITrainingResponse,
} from 'modules/trainings/models/training.model';
import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CardPlaceholder } from '../CardPlaceholder';
import { VideoCard } from './VideoCard';

const numberOfItems = 2;
const lastNumberOfDays = 7;

export const MostWatchedVideos: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [mostWatchedVideos, setMostWatchedVideos] = useState<
        ITraining[]
    >([]);

    const renderMostWatchedVideos = () => {
        return new Array(numberOfItems)
            .fill(null)
            .map((_item, index) => mostWatchedVideos[index] ?? null);
    };

    useEffect(() => {
        apiService
            .get<ITrainingResponse[]>(
                `/trainings/watched/${numberOfItems}/days/${lastNumberOfDays}`
            )
            .then(({ data }) =>
                setMostWatchedVideos(
                    data.map(createTrainingFromResponse)
                )
            );
    }, []);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <h2>{t('most-watched-videos', { days: lastNumberOfDays })}</h2>
                <Link className="unlinkify" to={ROUTES.ALL_TRAININGS}>
                    <Box display="flex" alignItems="center">
                        <Box mr="5px">
                            <b>
                                <p>{t('view-all-training-videos')}</p>
                            </b>
                        </Box>
                        <ArrowRightAltIcon />
                    </Box>
                </Link>
            </Box>
            <Box display="flex" flexWrap="wrap" style={{ gap: '40px' }}>
                {renderMostWatchedVideos().map((videoData, index) => {
                    return (
                        <Box key={videoData?.id ?? index}>
                            {!videoData ? (
                                <CardPlaceholder
                                    style={{
                                        height: '192px',
                                        width: '521px',
                                    }}
                                />
                            ) : (
                                <VideoCard videoData={videoData} />
                            )}
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};
